import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import FieldGroup from '../../../../components/layout/FieldGroup';
import MultiTextField from '../../../../components/compound/MultiTextField';
import SelectField from '../../../../components/compound/SelectField';
import TextField from '../../../../components/compound/TextField';
import Section from '../../../../components/layout/Section';
import {
    MUNICIPALITY_WITH_JP_VALUE_OPTIONS,
    PREFECTURE_WITH_JP_VALUE_OPTIONS
} from '../../../../constants/options';
import { useValidation } from '../../../../hooks';
import { VisaApplicationType } from '../../../../types/visa/applicationType';
import { INDUSTRY_OF_EMPLOYER_OPTIONS } from '../../../../constants/options/industry';
import { FormN } from '../../../../types/uncommonFormParts/formN/data';


interface OrganizationSectionProps {
  visaApplicationType?: VisaApplicationType;
  formN: FormN | null;
  onChangeFormN: (formN: Partial<FormN>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
};

const OrganizationSection: FunctionComponent<OrganizationSectionProps> = ({
  visaApplicationType,
  formN,
  onChangeFormN,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'organizationSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const [nameOfOrganization, setNameOfOrganization] = useState<string>('');
  const [nameOfBranchOffice, setNameOfBranchOffice] = useState<string>('');
  const [prefecture, setPrefecture] = useState<string>('');
  const [municipality, setMunicipality] = useState<string>('');
  const [townStreetApartment, setTownStreetApartment] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [corporationNumber, setCorporationNumber] = useState<string>('');
  const [employmentInsuranceAppOfficeNum, setEmploymentInsuranceAppOfficeNum] = useState<string>('');
  const [mainIndustry, setMainIndustry] = useState<string>('');
  const [otherIndustry1, setOtherIndustry1] = useState<string>('');
  const [otherIndustry2, setOtherIndustry2] = useState<string>('');
  const [capitalYen, setCapitalYen] = useState<string>('');
  const [annualRevenueLastYearYen, setAnnualRevenueLastYearYen] = useState<string>('');
  const [numberOfEmployees, setNumberOfEmployees] = useState<string>('');
  const [numberOfForeignEmployees, setNumberOfForeignEmployees] = useState<string>('');
  const [numberOfTechnicalInternTrainees, setNumberOfTechnicalInternTrainees] = useState<string>('');

  const [nameOfOrganizationError, setNameOfOrganizationError] = useState<string>('');
  const [nameOfBranchOfficeError, setNameOfBranchOfficeError] = useState<string>('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [corporationNumberError, setCorporationNumberError] = useState<string>('');
  const [employmentInsuranceAppOfficeNumError, setEmploymentInsuranceAppOfficeNumError] = useState<string>('');
  const [otherIndustry1Error, setOtherIndustry1Error] = useState<string>('');
  const [otherIndustry2Error, setOtherIndustry2Error] = useState<string>('');
  const [capitalYenError, setCapitalYenError] = useState<string>('');
  const [annualRevenueLastYearYenError, setAnnualRevenueLastYearYenError] = useState<string>('');
  const [numberOfEmployeesError, setNumberOfEmployeesError] = useState<string>('');
  const [numberOfForeignEmployeesError, setNumberOfForeignEmployeesError] = useState<string>('');
  const [numberOfTechnicalInternTraineesError, setNumberOfTechnicalInternTraineesError] = useState<string>('');

  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormN>) => {
    onChangeFormN(data);
  }

  useEffect(() => {
    if (!formN) 
      return;

    setNameOfOrganization(formN["contracting_organization_name"] ?? '');
    setNameOfBranchOffice(formN["contracting_organization_name_of_branch"] ?? '');
    setPhoneNumber(formN["contracting_organization_telephone_no"] ?? '');
    setPrefecture(formN["contracting_organization_address_prefecture"] ?? '');
    setMunicipality(formN["contracting_organization_address_municipality"] ?? '');
    setTownStreetApartment(formN["contracting_organization_address_street"] ?? '');
    setCorporationNumber(formN["contracting_organization_corporation_no"] ?? '');
    setEmploymentInsuranceAppOfficeNum(formN["contracting_organization_office_number"] ?? '');
    setMainIndustry(formN["contracting_organization_main_business_type"] ?? '');
    setOtherIndustry1(formN["contracting_organization_other_business_type_1"] ?? '');
    setOtherIndustry2(formN["contracting_organization_other_business_type_2"] ?? '');
    setCapitalYen(formN["contracting_organization_capital"] ?? '');
    setAnnualRevenueLastYearYen(formN["contracting_organization_annual_sales"] ?? '');
    setNumberOfEmployees(formN["contracting_organization_number_of_employees"] ?? '');
    setNumberOfForeignEmployees(formN["contracting_organization_number_of_foreign_employees"] ?? '');
    setNumberOfTechnicalInternTrainees(formN["contracting_organization_number_of_technical_intern_trainees"] ?? '');    

  }, [formN]);


  useEffect(() => {
    const areAllRequiredFiedsFilled = [
      nameOfOrganization,
      nameOfBranchOffice,
      prefecture,
      municipality,
      townStreetApartment,
      phoneNumber,
      corporationNumber,
      employmentInsuranceAppOfficeNum,
      mainIndustry,
      otherIndustry1,
      otherIndustry2,
      capitalYen,
      annualRevenueLastYearYen,
      numberOfEmployees,
      numberOfForeignEmployees,
      numberOfTechnicalInternTrainees
    ].every(val => !!val);

    const areThereNoErrors = [
      nameOfOrganizationError,
      nameOfBranchOfficeError,
      townStreetApartmentError,
      phoneNumberError,
      corporationNumberError,
      employmentInsuranceAppOfficeNumError,
      otherIndustry1Error,
      otherIndustry2Error,
      capitalYenError,
      annualRevenueLastYearYenError,
      numberOfEmployeesError,
      numberOfForeignEmployeesError,
      numberOfTechnicalInternTraineesError
    ].every(val => !val);

    onSectionReadinessChange(
      areAllRequiredFiedsFilled && areThereNoErrors
    );
  }, [
    nameOfOrganization,
    nameOfBranchOffice,
    prefecture,
    municipality,
    townStreetApartment,
    phoneNumber,
    corporationNumber,
    employmentInsuranceAppOfficeNum,
    mainIndustry,
    otherIndustry1,
    otherIndustry2,
    capitalYen,
    annualRevenueLastYearYen,
    numberOfEmployees,
    numberOfForeignEmployees,
    numberOfTechnicalInternTrainees,
    nameOfOrganizationError,
    nameOfBranchOfficeError,
    townStreetApartmentError,
    phoneNumberError,
    corporationNumberError,
    employmentInsuranceAppOfficeNumError,
    otherIndustry1Error,
    otherIndustry2Error,
    capitalYenError,
    annualRevenueLastYearYenError,
    numberOfEmployeesError,
    numberOfForeignEmployeesError,
    numberOfTechnicalInternTraineesError
  ])
  

  return (
    <Section>
      {/* Name of organization */}
      <TextField
        required
        label={t('nameOfOrganization')}
        placeholder="トクティー株式会社"        
        value={nameOfOrganization}
        error={nameOfOrganizationError || getEmptyError(nameOfOrganization)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfOrganization}
        onErrorChange={setNameOfOrganizationError}
        onBlur={() => {
          saveData({
            "contracting_organization_name": nameOfOrganization
          })
        }}
      />

      {/* Name of branch/office */}
      <TextField
        required
        label={t('nameOfBranchOffice')}
        placeholder="本社"
        value={nameOfBranchOffice}
        error={nameOfBranchOfficeError || getEmptyError(nameOfBranchOffice)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfBranchOffice}
        onErrorChange={setNameOfBranchOfficeError}
        onBlur={() => {
          saveData({
            "contracting_organization_name_of_branch": nameOfBranchOffice
          })
        }}
      />

      {/* Location */}
      <FieldGroup 
        title={t('location')}
        note={t("noteOnLocation")}
        theme="light"
      >

        {/* Prefecture */}
        <SelectField
          required
          label={t('prefecture')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={PREFECTURE_WITH_JP_VALUE_OPTIONS}
          value={prefecture}
          error={getEmptyError(prefecture)}
          onValueChange={val => {
            setPrefecture(val);
            setMunicipality('');

            saveData({
              "contracting_organization_address_prefecture": val,
              "contracting_organization_address_municipality": '',
            });
          }}
        />

        {/* Municipality */}
        <SelectField
          required
          disabled={!prefecture}
          label={t('municipality')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={MUNICIPALITY_WITH_JP_VALUE_OPTIONS[prefecture] ?? []}
          value={municipality}
          error={prefecture 
            ? getEmptyError(municipality)
            : t('enterPrefectureFirst')
          }
          onValueChange={val => {
            setMunicipality(val);

            saveData({
              "contracting_organization_address_municipality": val,
            });
          }}
        />

        {/* Town/Street/Apartment */}
        <MultiTextField
          required
          label={t('townStreetApartment')}
          placeholder="品川１ー２ー３ビザダスビル３F"
          value={townStreetApartment}
          error={townStreetApartmentError || getEmptyError(townStreetApartment)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setTownStreetApartment}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({
              "contracting_organization_address_street": townStreetApartment
            })
          }}
        />
      </FieldGroup>

      {/* Phone number */}
      <TextField
        required
        label={t('phoneNumber')}
        placeholder="09012345678"
        value={phoneNumber}
        error={phoneNumberError || getEmptyError(phoneNumber)}
        note={t("noteOnPhoneNumber")}
        restriction={tWithoutPrefix('inputNote.halfwidthNumberNoHyphens')}
        maxLength={12}
        validators={[validation.isNumeric, validation.createLengthValidator(12)]}
        onValueChange={setPhoneNumber}
        onErrorChange={setPhoneNumberError}
        onBlur={() => {
          saveData({ 
            "contracting_organization_telephone_no": phoneNumber
          });
        }}
      />

      {/* Corporation number */}
      <TextField
        required
        label={t('corporationNumber')}
        placeholder="1234567891234"
        value={corporationNumber}
        error={corporationNumberError || getEmptyError(corporationNumber)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={13}
        validators={[validation.isNumeric, validation.createLengthValidator(13)]}
        onValueChange={setCorporationNumber}
        onErrorChange={setCorporationNumberError}
        onBlur={() => {
          saveData({
            "contracting_organization_corporation_no": corporationNumber
          });
        }}
      />

      {/* Employment insurance application office number */}
      <TextField
        label={t('employmentInsuranceAppOfficeNum')}
        value={employmentInsuranceAppOfficeNum}
        placeholder="12345678912"
        error={employmentInsuranceAppOfficeNumError}
        restriction={tWithoutPrefix('inputNote.halfwidthNumberNoHyphens')}
        maxLength={11}
        validators={[validation.isNumeric, validation.createLengthValidator(11)]}
        onValueChange={setEmploymentInsuranceAppOfficeNum}
        onErrorChange={setEmploymentInsuranceAppOfficeNumError}
        onBlur={() => {
          saveData({
            "contracting_organization_office_number": employmentInsuranceAppOfficeNum
          });
        }}
      />

      {/* Main industry */}
      <SelectField
        required
        label={t('mainIndustry')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={INDUSTRY_OF_EMPLOYER_OPTIONS}
        value={mainIndustry}
        error={getEmptyError(mainIndustry)}
        onValueChange={val => {
          setMainIndustry(val);
          saveData({
            "contracting_organization_main_business_type": val
          });
        }}
      />

      {/* Other industry 1 */}
      <SelectField
        label={t('otherIndustry1')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={INDUSTRY_OF_EMPLOYER_OPTIONS}
        value={otherIndustry1}
        error={getEmptyError(otherIndustry1)}
        onValueChange={val => {
          setOtherIndustry1(val);
          saveData({
            "contracting_organization_other_business_type_1": val
          });
        }}
        onErrorChange={setOtherIndustry1Error}
      />

      {/* Other industry 2 */}
      <SelectField
        label={t('otherIndustry2')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={INDUSTRY_OF_EMPLOYER_OPTIONS}
        value={otherIndustry2}
        error={getEmptyError(otherIndustry2)}
        onValueChange={val => {
          setOtherIndustry2(val);
          saveData({
            "contracting_organization_other_business_type_2": val
          });
        }}
        onErrorChange={setOtherIndustry2Error}
      />

      {/* Capital (yen) */}
      <TextField
        required
        label={t('capitalYen')}
        value={capitalYen}
        error={capitalYenError || getEmptyError(capitalYen)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setCapitalYen}
        onErrorChange={setCapitalYenError}
        onBlur={() => {
          saveData({
            "contracting_organization_capital": capitalYen
          });
        }}
      />

      {/* Annual revenue last year (yen) */}
      <TextField
        required
        label={t('annualReveueLastYearYen')}
        value={annualRevenueLastYearYen}
        error={annualRevenueLastYearYenError || getEmptyError(annualRevenueLastYearYen)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setAnnualRevenueLastYearYen}
        onErrorChange={setAnnualRevenueLastYearYenError}
        onBlur={() => {
          saveData({
            "contracting_organization_annual_sales": annualRevenueLastYearYen
          });
        }}
      />

      {/* Number of employees */}
      <TextField
        required
        label={t('numberOfEmployees')}
        value={numberOfEmployees}
        error={numberOfEmployeesError || getEmptyError(numberOfEmployees)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setNumberOfEmployees}
        onErrorChange={setNumberOfEmployeesError}
        onBlur={() => {
          saveData({
            "contracting_organization_number_of_employees": numberOfEmployees
          });
        }}
      />

      {/* Number of foreign employees */}
      <TextField
        required
        label={t('numberOfForeignEmployees')}
        value={numberOfForeignEmployees}
        error={numberOfForeignEmployeesError || getEmptyError(numberOfForeignEmployees)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setNumberOfForeignEmployees}
        onErrorChange={setNumberOfForeignEmployeesError}
        onBlur={() => {
          saveData({
            "contracting_organization_number_of_foreign_employees": numberOfForeignEmployees
          });
        }}
      />

      {/* Number of technical intern trainees */}
      <TextField
        required
        label={t('numberOfTechnicalInternTrainees')}
        value={numberOfTechnicalInternTrainees}
        error={numberOfTechnicalInternTraineesError || getEmptyError(numberOfTechnicalInternTrainees)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumber')}
        maxLength={14}
        validators={[validation.isNumeric, validation.createLengthValidator(14)]}
        onValueChange={setNumberOfTechnicalInternTrainees}
        onErrorChange={setNumberOfTechnicalInternTraineesError}
        onBlur={() => {
          saveData({
            "contracting_organization_number_of_technical_intern_trainees": numberOfTechnicalInternTrainees
          });
        }}
      />

    </Section>
  );
};

export default OrganizationSection;