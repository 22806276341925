import { FunctionComponent, useEffect, useState } from 'react';
import Section from '../../../components/layout/Section';
import { useTranslation } from "react-i18next";
import { DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT, DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU } from '../../../constants/options';
import SelectField from "../../../components/compound/SelectField";
import MultiTextField from "../../../components/compound/MultiTextField";
import RadioField from "../../../components/compound/RadioField";
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { HowToReceiveNewResidenceCard } from '../../../types/visa/formCommonValues';
import { useValidation } from '../../../hooks';
import { OFFICE_OPTIONS } from '../../../constants/options/office';
import { SelectOption } from '../../../types/option';
import { ChangeBySelfData } from '../../../types/changeBySelf/data';
import { DESIRED_STATUS_OF_RESIDENCE_OPTIONS } from '../../../constants/options/desiredStatusOfResidence';

interface DetailsOfChangeSectionProps {
  visaApplicationType: VisaApplicationType;
  changeBySelfData: ChangeBySelfData | null;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
}

const DetailsOfChangeSection: FunctionComponent<DetailsOfChangeSectionProps> = ({
  visaApplicationType,
  changeBySelfData,
  onChangeChangeBySelfData,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'detailsOfChangeSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();

  const [desiredStatusOfResidence, setDesiredStatusOfResidence] = useState(''); 
  const [desiredPeriodOfStay, setDesiredPeriodOfStay] = useState('');
  const [reasonForChange, setReasonForChange] = useState('');
  const [reasonForChangeError, setReasonForChangeError] = useState('');
  const [receivingMethod, setReceivingMethod] = useState('');
  const [officeToPickUp, setOfficeToPickUp] = useState('');

  //Used to determine which office to pick up at
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const desiredPeriodOfStayOptions: SelectOption[] = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ChangeToDependentVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT;
      case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
        return DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU;
      default:
        return [];
    }
  })();

  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field") : '';
  const saveData = (data: Partial<ChangeBySelfData>) => {
    onChangeChangeBySelfData(data);
  }

  const isAddressInfoInsufficient = !prefecture || !municipality;
  
  useEffect(() => {
    if (!changeBySelfData) 
      return;

    const data = changeBySelfData;

    setDesiredStatusOfResidence(data['WCICS020Dto:selNewZirySkk'] || '');
    setReasonForChange(data['WCICS020Dto:txtKusnReason'] || '');
    setReceivingMethod(data['WCIBS010Dto:radZiryCardJryHuhu'] || '');
    setOfficeToPickUp(data['WCIBS010Dto:selJryuKnsh'] || '');

    if (data['WCIBS010Dto:hdnSearchedAddress']) {
      setPrefecture(data['WCIBS010Dto:hdnSearchedAddress'].slice(0, 2) ?? '');
      setMunicipality(data['WCIBS010Dto:hdnSearchedAddress'].slice(2) ?? '');
    }

    setDesiredPeriodOfStay(data['WCICS020Dto:selNewZiryPeriod'] ?? '');  
  }, [changeBySelfData]);


  useEffect(() => {
    const receivingOffice = 
      receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice 
        ? [officeToPickUp] 
        : [];

    const areAllRequiredFieldsFilled = 
      [
        desiredStatusOfResidence,
        desiredPeriodOfStay,
        reasonForChange,
        receivingMethod,
        ...receivingOffice
      ].every(val => !!val);

    const areThereNoErrors =
      [
        reasonForChangeError
      ].every(err => !err);

    onSectionReadinessChange(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    desiredStatusOfResidence,
    desiredPeriodOfStay,
    reasonForChange,
    reasonForChangeError,
    receivingMethod,
    officeToPickUp
  ])

  return (
    <Section>
      {/* Desired status of residence (Read only) */}
      <SelectField
        required
        disabled
        label={t("desiredStatusOfResidence")}
        options={DESIRED_STATUS_OF_RESIDENCE_OPTIONS}
        value={desiredStatusOfResidence}
      />

      {/* Desired period of stay */}
      <SelectField
        required
        label={t("desiredPeriodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={desiredPeriodOfStayOptions}
        value={desiredPeriodOfStay}
        error={getEmptyError(desiredPeriodOfStay)}
        onValueChange={val => {
          setDesiredPeriodOfStay(val);
          saveData({ 'WCICS020Dto:selNewZiryPeriod': val });
        }}
      />

      {/* Reason for change */}
      <MultiTextField
        required
        label={t("reasonOfChange")}
        placeholder={"日本人と結婚したため"}
        value={reasonForChange}
        error={reasonForChangeError || getEmptyError(reasonForChange)}
        restriction={tWithoutPrefix('inputNote.fullwidthJapanese')}
        maxLength={600}
        validators={[validation.isFullwidth, validation.createLengthValidator(600)]}
        onValueChange={setReasonForChange}
        onErrorChange={setReasonForChangeError}
        onBlur={() => {
          saveData({ 'WCICS020Dto:txtKusnReason': reasonForChange });
        }}
      />

      {/* How will you receive your new residence card? */}
      <RadioField
        required
        label={t("recievingNewResidenceCard")}
        options={[
          {
            label: t("byMail"),
            value: HowToReceiveNewResidenceCard.Mail,
          },
          {
            label: t("pickUpAtImmigrationOffice"),
            value: HowToReceiveNewResidenceCard.VisitImmigrationOffice,
          },
        ]}
        value={receivingMethod}
        error={getEmptyError(receivingMethod)}
        onValueChange={val => {
          if (val === HowToReceiveNewResidenceCard.Mail) {
            saveData({ 
              'WCIBS010Dto:radZiryCardJryHuhu': val,
              'WCIBS010Dto:selJryuKnsh': ''
            });
          }
          
          if (val === HowToReceiveNewResidenceCard.VisitImmigrationOffice) {
            saveData({ 'WCIBS010Dto:radZiryCardJryHuhu': val });
          }

          setReceivingMethod(val);
        }}
      />

      {/* Which office to pick up at */}
      {receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice && (
        <SelectField
          disabled={isAddressInfoInsufficient}
          label={t("pickUpOffice")}
          options={
            OFFICE_OPTIONS[`${prefecture}${municipality}`] 
            ?? OFFICE_OPTIONS[prefecture] 
            ?? []
          }
          value={officeToPickUp}
          error={
            (isAddressInfoInsufficient && t("pleaseEnterAddressFirst")) ||
            getEmptyError(officeToPickUp) 
          }
          onValueChange={val => {
            setOfficeToPickUp(val);
            saveData({ 'WCIBS010Dto:selJryuKnsh': val });
          }}
        />
      )}
    </Section>
  );
};

export default DetailsOfChangeSection;