import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { VisaAttachmentKind } from "../../../../../../../../types/visa/attachment";
import { extractVisaAttachments } from "../../../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { AttachmentsBodyProps } from "../../../../../../../../types/modal/attachment";
import PreviewList from "../../../../../PreviewList";


export const DependentVisaAttachments: FunctionComponent<AttachmentsBodyProps> = ({
  visaAttachments
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'attachmentsSection' });
  return (
    <PreviewList
      items={[
        {
          label: t('passport.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Passport)
        },
        {
          label: t('residenceCard.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.ResidenceCard)
        },
        {
          label: t('photo.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Photo)
        },
        {
          label: t('supportsResidenceCardOrPassport.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.SupporterResidenceCardOrPassport)
        },
        {
          label: t('docThatProvesRelationshipWithSupporter.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsProvingRelationshipBtwApplicantAndSupporter)
        },
        {
          label: t('docThatProvesSupportersJobAndIncome.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsCertifyingOccupationAndIncomeOfDependent)
        },
        {
          label: t('other.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Other)
        },
        
      ]}
    />
  )
}

export default DependentVisaAttachments;
