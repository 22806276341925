import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "../../components/modal/Modal";
import Button from "../../components/form/Button";
import Column from "../../components/layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { useDispatcher } from "../../hooks";
import PageHeading from "../../components/text/PageHeading";
import { useNavigate } from "react-router-dom";
import BackButton from "../../components/navigation/BackButton";
import { createVisaApplication } from "../../apis/visaApplication";
import { createExtensionBySelfData } from "../../apis/extensionBySelfData";
import { getCurrentUser } from "../../apis/user";
import RadioField from "../../components/compound/RadioField";
import { 
  convertToApplicationCategoryCodeForExtensionBySelf, 
  convertToCurrentStatusOfResidenceCode, 
  convertToStatusOfResidenceCodeForChangeBySelf, 
  convertToStatusOfResidenceCodeForExtensionBySelf 
} from "../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { StatusOfResidenceOfUser } from "../../types/user/data";
import { createFormP } from "../../apis/formP";
import { createFormN } from "../../apis/formN";
import { createChangeBySelfData } from "../../apis/changeBySelfData";
import { KeptVisaApplicationContent, No } from "../../types/bySelfCommon/specificValues";
import { ApplicationCategoryCodeForChangeBySelf } from "../../types/changeBySelf/specificValues";
import { NewStatusOfResidenceCode_CB19 } from "../../types/visa/currentStatusOfResidence";
import { createFormR } from "../../apis/formR";


interface CreateNewAppModalProps {
  statusOfResidence: StatusOfResidenceOfUser;
  onClose: () => void;
}

//Defined as enum even if only one value is used for now
//This is because a new screen can be added later
enum ContentType {
  ChooseTypeOfApp = 'choose-type-of-app',
  WhichVisaToChangeTo = 'which-visa-to-change-to',
}

enum ApplicationType {
  Extension = 'extension',
  Change = 'change',
}

enum TargetVisaToChangeTo {
  Gijinkoku = 'engineer-specialist-in-humanities-international-services',
  Dependent = 'dependent',
}

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
`;

const Content = styled(Column)`
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: 100%;
  margin: 0 auto;
`;

const UpperPartContainer = styled(Column)`
  gap: 20px;
`;

const LowerPartContainer = styled.div`
  margin-top: 58px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
`;


const CreateNewAppModal: FunctionComponent<CreateNewAppModalProps> = ({
  statusOfResidence, //On which visa the user is currently on
  onClose,
}) => {
  const { dispatcher } = useDispatcher();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [contentType, setContentType] = useState(ContentType.ChooseTypeOfApp);
  const [appType, setAppType] = useState<ApplicationType>();
  const [targetVisa, setTargetVisa] = useState<TargetVisaToChangeTo>();
  const btnDisabled = (contentType === ContentType.ChooseTypeOfApp && !appType) || 
    (contentType === ContentType.WhichVisaToChangeTo && !targetVisa);
  const onClickBack = () => {
    switch (contentType) {
      case ContentType.ChooseTypeOfApp:
        onClose();
        return;
      case ContentType.WhichVisaToChangeTo:
        setContentType(ContentType.ChooseTypeOfApp);
        return;
    }
  };
  const onClickNext = async () => {
    if (appType === ApplicationType.Extension) {
      await onCreate();
      return;
    }

    if (appType === ApplicationType.Change) {
      if (targetVisa)
        await onCreate();
      else
        setContentType(ContentType.WhichVisaToChangeTo);      
    }
  }

  const onCreate = async () => {
    dispatcher.startLoading();
    try {
      const user = await getCurrentUser();
      const visaApplication = await createVisaApplication();
      const statusOfResidenceOfUser = user.status_of_residence;

      switch (statusOfResidenceOfUser) {
        case StatusOfResidenceOfUser.Gijinkoku:
          await createFormN(visaApplication.id);
          break;
        case StatusOfResidenceOfUser.StudyAbroad:
          await createFormP(visaApplication.id);
      }
      
      if (!appType)
        throw new Error('No application type is selected');

      if (appType === ApplicationType.Extension) {
        await createExtensionBySelfData(
          visaApplication.id,
          {
            "No.": No, //This value ('1') must be used for this kind of application,
            
            //Note: Current status of residence code and Status of residence code are different
            //Don't mix them up. They will be used in different fields.

            //現在の在留資格 / Current status of residence
            "WCIBS010Dto:selCurrentZirySkk": convertToCurrentStatusOfResidenceCode(user.status_of_residence),

            //申請内容の控え有無 / Keep a copy of the application content
            //We only use Yes
            "WZAAS190Dto:chkHkeDui": KeptVisaApplicationContent.Yes, 

            //申請種別　/ Application category
            selSnsiShbt: convertToApplicationCategoryCodeForExtensionBySelf(user.status_of_residence),

            //在留資格 / Status of residence
            selZirySkk: convertToStatusOfResidenceCodeForExtensionBySelf(user.status_of_residence)
          }
        );

        switch (statusOfResidenceOfUser) {
          case StatusOfResidenceOfUser.Gijinkoku:
            await createFormN(visaApplication.id);
        }  
      }

      if (appType === ApplicationType.Change) {
        let targetApplicationCategoryCode: ApplicationCategoryCodeForChangeBySelf;
        let newStatusOfResidenceCode: NewStatusOfResidenceCode_CB19;

        switch (targetVisa) {
          case TargetVisaToChangeTo.Gijinkoku:
            await createFormN(visaApplication.id);
            targetApplicationCategoryCode = ApplicationCategoryCodeForChangeBySelf.Gijinkoku;
            newStatusOfResidenceCode = NewStatusOfResidenceCode_CB19
              .EngineerSpecialistInHumanitiesInternationalServices
            break;
          case TargetVisaToChangeTo.Dependent:
            await createFormR(visaApplication.id);
            targetApplicationCategoryCode = ApplicationCategoryCodeForChangeBySelf.Dependent;
            newStatusOfResidenceCode = NewStatusOfResidenceCode_CB19
              .Dependent
            break;
          default:
            throw new Error('Invalid target visa');
        }

        await createChangeBySelfData(
          visaApplication.id,
          {
            "No.": No, //This value ('1') must be used for this kind of application,

            //Note: Current status of residence code and Status of residence code are different
            //Don't mix them up. They will be used in different fields.

            //現在の在留資格 / Current status of residence
            "WCICS020Dto:selCurrentZirySkk": convertToCurrentStatusOfResidenceCode(user.status_of_residence),

            //申請内容の控え有無 / Keep a copy of the application content
            "WZAAS200Dto:chkHkeDui": KeptVisaApplicationContent.Yes, //We only use Yes

            //新しい在留資格 / New status of residence
            "WCICS020Dto:selNewZirySkk": newStatusOfResidenceCode,

            //申請種別 / Application category
            selSnsiShbt: targetApplicationCategoryCode,

            //在留資格 / Status of residence
            selZirySkk: convertToStatusOfResidenceCodeForChangeBySelf(user.status_of_residence)
          }
        );

        
      }

      navigate(`/application/${visaApplication.id}/edit/`);
      dispatcher.showSnackbar(t("snackbar.appCreated"), 'success');
    } catch (e) {
      dispatcher.showSnackbar(t("snackbar.failedToProceed"), 'warning');
    } finally {
      dispatcher.stopLoading();
    }
  }


  return (
    <Modal>
      <ContentContainer>
        <Content>
          <UpperPartContainer>
            <BackButton onClick={onClickBack}/>
            <PageHeading>{t("modalCreateApp.createApplication")}</PageHeading>

            { contentType === ContentType.ChooseTypeOfApp &&
              <RadioField
                label={t("modalCreateApp.chooseApplication")}
                options={[
                  {
                    label: t("modalCreateApp.extendCurrentVisa"),
                    value: ApplicationType.Extension
                  },
                  {
                    label: t("modalCreateApp.changeToAnotherVisa"),
                    value: ApplicationType.Change
                  }
                ]}
                value={appType}
                onValueChange={setAppType}
              />
            }

            { contentType === ContentType.WhichVisaToChangeTo &&
              <RadioField
                label={t("modalCreateApp.changeToAnotherVisa")}
                options={[
                  {
                    label: t("modalCreateApp.visa.technical"),
                    value: TargetVisaToChangeTo.Gijinkoku,
                    disabled: statusOfResidence === StatusOfResidenceOfUser.Gijinkoku
                  },
                  {
                    label: t("modalCreateApp.visa.dependent"),
                    value: TargetVisaToChangeTo.Dependent,
                    disabled: statusOfResidence === StatusOfResidenceOfUser.Dependent
                  }
                ]}
                value={targetVisa}
                onValueChange={setTargetVisa}
              />
            }

          </UpperPartContainer>
          <LowerPartContainer>
            <Button
              disabled={btnDisabled}
              variant="primary"
              style={{ width: '100%' }}
              onClick={onClickNext}
            >
              {t("modalCreateApp.next")}
            </Button>
          </LowerPartContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default CreateNewAppModal;
