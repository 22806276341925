//The suffix, "CB19" is a parent key for all the codes.
//You won't have to know what it means exactly. you might see validation errors 
//from backend that mention "CB19."
//It's confusing but added to distinguish the codes from other similar codes 
//(i.e. "StatusOfResidenceOfUser" and "StatusOfResidenceCode").

//These codes are common to both ExtensionBySelf and ChangeBySelf Applications
//Used for "selCurrentZirySkk"

//Source (see at the bottom):
//https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1813510246

export enum CurrentStatusOfResidenceCode_CB19 {
  Dependent = 'T44',
  SpouseOrChildOfJapaneseNational = 'T61',
  SpouseOrChildOfPermanentResident = 'T62',
  LongTermResident = 'T63',
  StudyAbroad = 'T41',
  EngineerOrSpecialistInHumanitiesOrInternationalServices = 'T26',
  DesignatedActivities = 'T51',
}


//Used for "selNewZirySkk" i.e. 新しい在留資格 / New status of residence
//Source (see at the bottom):
//https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1263905895&range=A2157
export enum NewStatusOfResidenceCode_CB19   {
  Diplomat = 'T01',
  Official = 'T02',
  Professor = 'T03',
  Artist = 'T04',
  ReligiousActivities = 'T05',
  Journalist = 'T06',
  LegalAccountingServices = 'T12',
  MedicalServices = 'T13',
  Researcher = 'T14',
  Instructor = 'T15',
  IntraCompanyTransferee = 'T18',
  Entertainer = 'T19',
  SkilledLabor = 'T20',
  TechnicalInternTraining_i_a = 'T21',
  TechnicalInternTraining_i_b = 'T22',
  TechnicalInternTraining_ii_a = 'T23',
  TechnicalInternTraining_ii_b = 'T24',
  BusinessManager = 'T25',
  EngineerSpecialistInHumanitiesInternationalServices = 'T26',
  TechnicalInternTraining_iii_a = 'T27',
  TechnicalInternTraining_iii_b = 'T28',
  NursingCare = 'T29',
  CulturalActivities = 'T31',
  TemporaryVisitor = 'T32',
  Student = 'T41',
  Trainee = 'T43',
  Dependent = 'T44',
  DesignatedActivities = 'T51',
  SpecialPermanentResidents = 'T60',
  SpouseOrChildOfJapaneseNational = 'T61',
  SpouseOrChildOfPermanentResident = 'T62',
  LongTermResident = 'T63',
  SpecifiedSkilledWorker_i = 'T71',
  SpecifiedSkilledWorker_ii = 'T72',
  HighlySkilledProfessional_i_a = 'T81',
  HighlySkilledProfessional_i_b = 'T82',
  HighlySkilledProfessional_i_c = 'T83',
  HighlySkilledProfessional_ii = 'T90',
  PermanentResident = 'X14',
}