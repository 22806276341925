//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

import { SelectOption } from "../../types/option";

export const MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.101",
      "value": "法学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.102",
      "value": "経済学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.103",
      "value": "政治学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.104",
      "value": "商学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.105",
      "value": "経営学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.106",
      "value": "文学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.107",
      "value": "語学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.108",
      "value": "社会学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.109",
      "value": "歴史学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.110",
      "value": "心理学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.111",
      "value": "教育学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.112",
      "value": "芸術学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.199",
      "value": "その他人文・社会科学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.201",
      "value": "理学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.202",
      "value": "化学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.203",
      "value": "工学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.204",
      "value": "農学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.205",
      "value": "水産学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.206",
      "value": "薬学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.207",
      "value": "医学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.208",
      "value": "歯学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.209",
      "value": "体育学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.210",
      "value": "介護福祉"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.299",
      "value": "その他自然科学"
  },
  {
      "label": "majorFieldOfStudyForDrMsBcJcForGijinkoku.999",
      "value": "その他"
  }
];


export const MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.01",
      "value": "工業"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.02",
      "value": "農業"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.03",
      "value": "医療・衛生"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.05",
      "value": "教育・社会福祉"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.04",
      "value": "法律"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.06",
      "value": "商業実務"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.07",
      "value": "服飾・家政"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.08",
      "value": "文化・教養"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.09",
      "value": "介護福祉"
  },
  {
      "label": "majorFieldOfStudyForCollegeOfTechnologyForGijinkoku.99",
      "value": "その他"
  }
];
