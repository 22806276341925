import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import DateSelectField from '../../../../components/compound/DateSelectField';
import RadioField from '../../../../components/compound/RadioField';
import SelectField from '../../../../components/compound/SelectField';
import TextField from '../../../../components/compound/TextField';
import Section from '../../../../components/layout/Section';
import { useValidation } from '../../../../hooks';
import { VisaApplicationType } from '../../../../types/visa/applicationType';
import { isThisVisaChange, isThisVisaExtension } from '../../../../utils/visaApplicationHelper';
import { FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE, FINAL_EDUCATION_DRMSBCJC_VALUES, FINAL_EDUCATION_OTHER_VALUE, LocationOfFinalEducation, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE, MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE } from '../../../../types/uncommonFormParts/formN/value';
import { MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU, MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU } from '../../../../constants/options/major';
import { FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU } from '../../../../constants/options/education';
import { FormN } from '../../../../types/uncommonFormParts/formN/data';

interface GijinkokuVisaFinalEducationSectionProps {
  visaApplicationType: VisaApplicationType;
  formN: FormN;
  onChangeFormN: (data: Partial<FormN>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
};

const GijinkokuVisaFinalEducationSection: FunctionComponent<GijinkokuVisaFinalEducationSectionProps> = ({
  visaApplicationType,
  formN,
  onChangeFormN,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'finalEducationSection' });
  const { t : tWithoutPrefix } = useTranslation();

  const validation = useValidation();
  const [finalEducation, setFinalEducation] = useState<string>('');
  const [finalEducationOther, setFinalEducationOther] = useState<string>('');
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [location, setLocation] = useState<string>('');
  const [dateOfGraduation, setDateOfGraduation] = useState<string>('');
  const [majorFieldOfStudy, setMajorFieldOfStudy] = useState<string>('');
  const [majorFieldOfStudyOther, setMajorFieldOfStudyOther] = useState<string>('');

  const [finalEducationOtherError, setFinalEducationOtherError] = useState<string>('');
  const [nameOfSchoolError, setNameOfSchoolError] = useState<string>('');
  const [majorFieldOfStudyOtherError, setMajorFieldOfStudyOtherError] = useState<string>('');

  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormN>) => {
    onChangeFormN(data);
  }

  useEffect(() => {
    const data = formN;

    if (!data) 
      return;

    setFinalEducation(data["educational_background_kind"] ?? '');
    setFinalEducationOther(data["educational_background_kind_other"] ?? '');
    setNameOfSchool(data["educational_background_name_of_school"] ?? '');
    setLocation(data["educational_background_place"] ?? '');
    setDateOfGraduation(data["educational_background_date"] ?? '');
    setMajorFieldOfStudy(data["major_field_of_study"] ?? '');
    setMajorFieldOfStudyOther(data["major_field_of_study_other"] ?? '');
  }, [formN]);


  useEffect(() => {
    const conditionallyRequiredFields = [];

    if (finalEducation === FINAL_EDUCATION_OTHER_VALUE) 
      conditionallyRequiredFields.push(finalEducationOther);
    
    if (FINAL_EDUCATION_DRMSBCJC_VALUES.includes(finalEducation) ||
        FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE === finalEducation)
      conditionallyRequiredFields.push(majorFieldOfStudy);

    if (majorFieldOfStudy === MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE ||
        majorFieldOfStudy === MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE)
      conditionallyRequiredFields.push(majorFieldOfStudyOther);

    const areAllRequiredFiedsFilled = [
      finalEducation,
      nameOfSchool,
      location,
      dateOfGraduation,
      ...conditionallyRequiredFields
    ].every(val => !!val);

    const areThereNoErrors = [
      finalEducationOtherError,
      nameOfSchoolError,
      majorFieldOfStudyOtherError
    ].every(val => !val);

    onSectionReadinessChange(
      areAllRequiredFiedsFilled && areThereNoErrors
    );
  }, [
    finalEducation,
    finalEducationOther,
    nameOfSchool,
    location,
    dateOfGraduation,
    majorFieldOfStudy,
    majorFieldOfStudyOther,
    finalEducationOtherError,
    nameOfSchoolError,
    majorFieldOfStudyOtherError
  ]);
  

  return (
    <Section>
      {/* Final education */}
      <SelectField
        required
        label={t('finalEducation')}
        placeholder={tWithoutPrefix('placeholder.select')}
        options={FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU}
        value={finalEducation}
        error={getEmptyError(finalEducation)}
        onValueChange={val => {
          let data: Partial<FormN> = {};
          data["educational_background_kind"] = val;
          
          if (val !== FINAL_EDUCATION_OTHER_VALUE) {
            setFinalEducationOtherError('');
            setMajorFieldOfStudy('');
            setMajorFieldOfStudyOther('');
            setMajorFieldOfStudyOtherError('');
            data["educational_background_kind_other"] = '';
            data["major_field_of_study"] = '';
            data["major_field_of_study_other"] = '';
          }
          setFinalEducation(val);
          saveData(data);
        }}
      />

      {/* Final education (Other) */}
      { finalEducation === FINAL_EDUCATION_OTHER_VALUE &&
        <TextField
          required
          label={t('finalEducationOther')}
          value={finalEducationOther}
          error={finalEducationOtherError || getEmptyError(finalEducationOther)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setFinalEducationOther}
          onErrorChange={setFinalEducationOtherError}
          onBlur={() => {
            saveData({ educational_background_kind_other: finalEducationOther });
          }}
        />
      }

      {/* Name of school */}
      <TextField
        required
        label={t('nameOfSchool')}
        placeholder="Visadas University"
        value={nameOfSchool}
        error={nameOfSchoolError || getEmptyError(nameOfSchool)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfSchool}
        onErrorChange={setNameOfSchoolError}
        onBlur={() => {
          saveData({ educational_background_name_of_school: nameOfSchool });
        }}
      />

      {/* Location */}
      <RadioField
        required
        label={t('location')}
        options={[
          {
            label: t('japan'),
            value: LocationOfFinalEducation.Japan,
          },
          {
            label: t('foreignCountry'),
            value: LocationOfFinalEducation.ForeignCountry,
          },
        ]}
        value={location}
        error={getEmptyError(location)}
        onValueChange={val => {
          setLocation(val);
          saveData({ educational_background_place: val });
        }}
      />

      {/* Date of graduation */}
      <DateSelectField
        required
        label={t('dateOfGraduation')}
        minDate={new Date(1950, 0, 1)}
        maxDate={new Date()}
        value={dateOfGraduation}
        error={getEmptyError(dateOfGraduation)}
        onValueChange={val => {
          setDateOfGraduation(val);
          saveData({ educational_background_date: val });
        }}
      />

      {/* Major field of study (when one of the following is selected: 
          Doctor, Master, Bachelor, Junior college) */}
      { FINAL_EDUCATION_DRMSBCJC_VALUES.includes(finalEducation) &&
        <SelectField
          required
          label={t('majorFieldOfStudy')}
          placeholder={tWithoutPrefix('placeholder.select')}
          options={MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_OPTIONS_FOR_GIJINKOKU}
          value={majorFieldOfStudy}
          error={getEmptyError(majorFieldOfStudy)}
          onValueChange={val => {
            let data: Partial<FormN> = {};

            data["major_field_of_study"] = val;
            
            if (val !== MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE) {
              setMajorFieldOfStudyOtherError('');
              data["major_field_of_study_other"] = '';
            }
              
            setMajorFieldOfStudy(val);
            saveData(data);
          }}
        />
      }

      {/* Major field of study (Other) 
          (when one of the following is selected: Doctor, Master, Bachelor, Junior college,
          and Other is selected for Major field of study) */}
      {  FINAL_EDUCATION_DRMSBCJC_VALUES.includes(finalEducation) &&
         MAJOR_FIELD_OF_STUDY_FOR_DRMSBCJC_FOR_TECH_OTHER_VALUE === majorFieldOfStudy &&
        <TextField
          required
          label={t('majorFieldOfStudyOther')}
          value={majorFieldOfStudyOther}
          error={majorFieldOfStudyOtherError || getEmptyError(majorFieldOfStudyOther)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setMajorFieldOfStudyOther}
          onErrorChange={setMajorFieldOfStudyOtherError}
          onBlur={() => {
            saveData({ major_field_of_study_other: majorFieldOfStudyOther });
          }}
        />
      }

      {/* Major field of study (when "college of technology" is selected) */}
      { FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE === finalEducation &&
        <SelectField
          required
          label={t('majorFieldOfStudy')}
          placeholder={tWithoutPrefix('placeholder.select')}
          options={MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECHNOLOGY_OPTIONS_FOR_GIJINKOKU}
          value={majorFieldOfStudy}
          error={getEmptyError(majorFieldOfStudy)}
          onValueChange={val => {
            let data: Partial<FormN> = {};
            data["major_field_of_study"] = val;
            
            if (val !== MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE) {
              setMajorFieldOfStudyOther('');
              setMajorFieldOfStudyOtherError('');
              data["major_field_of_study_other"] = '';
            }
            
            setMajorFieldOfStudy(val);
            saveData(data);
          }}
        />
      }

      {/* Major field of study (Other) 
          (when one of the following is selected: Doctor, Master, Bachelor, Junior college,
          and Other is selected for Major field of study) */}
      {  FINAL_EDUCATION_COLLEGE_OF_TECH_VALUE === finalEducation &&
         MAJOR_FIELD_OF_STUDY_FOR_COLLEGE_OF_TECH_FOR_TECHNICAL_OTHER_VALUE === majorFieldOfStudy &&
        <TextField
          required
          label={t('majorFieldOfStudyOther')}
          value={majorFieldOfStudyOther}
          error={majorFieldOfStudyOtherError || getEmptyError(majorFieldOfStudyOther)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setMajorFieldOfStudyOther}
          onErrorChange={setMajorFieldOfStudyOtherError}
          onBlur={() => {
            saveData({ major_field_of_study_other: majorFieldOfStudyOther });
          }}
        />
      }
    

    </Section>
  );
};

export default GijinkokuVisaFinalEducationSection;