import { SelectOption } from "../../types/option";

export const RELATIONSHIP_OPTIONS: SelectOption[] = [
    {
        "label": "relationship.01",
        "value": "01"
    },
    {
        "label": "relationship.03",
        "value": "03"
    },
    {
        "label": "relationship.02",
        "value": "02"
    },
    {
        "label": "relationship.04",
        "value": "04"
    },
    {
        "label": "relationship.05",
        "value": "05"
    },
    {
        "label": "relationship.06",
        "value": "06"
    },
    {
        "label": "relationship.07",
        "value": "07"
    },
    {
        "label": "relationship.08",
        "value": "08"
    },
    {
        "label": "relationship.09",
        "value": "09"
    },
    {
        "label": "relationship.10",
        "value": "10"
    },
    {
        "label": "relationship.11",
        "value": "11"
    },
    {
        "label": "relationship.12",
        "value": "12"
    },
    {
        "label": "relationship.13",
        "value": "13"
    },
    {
        "label": "relationship.14",
        "value": "14"
    },
    {
        "label": "relationship.15",
        "value": "15"
    },
    {
        "label": "relationship.16",
        "value": "16"
    },
    {
        "label": "relationship.17",
        "value": "17"
    },
    {
        "label": "relationship.18",
        "value": "18"
    },
    {
        "label": "relationship.19",
        "value": "19"
    },
    {
        "label": "relationship.20",
        "value": "20"
    },
    {
        "label": "relationship.21",
        "value": "21"
    },
    {
        "label": "relationship.51",
        "value": "51"
    },
    {
        "label": "relationship.52",
        "value": "52"
    },
    {
        "label": "relationship.41",
        "value": "41"
    },
    {
        "label": "relationship.31",
        "value": "31"
    },
    {
        "label": "relationship.32",
        "value": "32"
    },
    {
        "label": "relationship.33",
        "value": "33"
    },
    {
        "label": "relationship.42",
        "value": "42"
    },
    {
        "label": "relationship.43",
        "value": "43"
    },
    {
        "label": "relationship.99",
        "value": "99"
    }
];

//For some fields, the option "01" (i.e. "self") is not appropriate, so we hide it
export const RELATIONSHIP_OPTIONS_WITHOUT_SELF: SelectOption[] = RELATIONSHIP_OPTIONS.filter((option) => option.value !== "01");


//Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=308892565#gid=308892565
export const RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "relationshipWithSupporterForStudyAbroad.02",
        "value": "妻"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.03",
        "value": "夫"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.05",
        "value": "父"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.06",
        "value": "母"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.11",
        "value": "祖父"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.12",
        "value": "祖母"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.14",
        "value": "養父"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.15",
        "value": "養母"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.32",
        "value": "友人・知人"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.33",
        "value": "友人・知人の親族"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.41",
        "value": "受入教育機関"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.42",
        "value": "取引関係者・現地企業等職員"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.51",
        "value": "兄弟姉妹"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.52",
        "value": "叔父(伯父)・叔母(伯母)"
    },
    {
        "label": "relationshipWithSupporterForStudyAbroad.99",
        "value": "その他"
    }
       
];
