import { FunctionComponent, useEffect, useState } from 'react';
import { VisaFormProps } from '../../../types/visa/formProp';
import Accordion from '../../../components/navigation/Accordion';
import BasicInformationSection from '../ApplicantBlock/BasicInformationSection';
import StatusOfResidenceSection from '../ApplicantBlock/StatusOfResidenceSection';
import { useTranslation } from 'react-i18next';
import DetailsOfExtensionSection from '../ApplicantBlock/DetailsOfExtensionSection';
import FamilyCoresidentsSection from '../ApplicantBlock/FamilyCoresidentsSection';
import DependentSection from '../ApplicantBlock/DependentSection';
import OtherSection from '../ApplicantBlock/OtherSection';
import AttachmentsSection from '../ApplicantBlock/AttachmentSection';
import { ReactComponent as ClipIcon } from '../../../assets/icon-clip.svg';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';

interface DependentVisaFormProps extends VisaFormProps {} 


const DependentVisaForm: FunctionComponent<DependentVisaFormProps>  = ({
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  visaAttachments,
  onExtensionBySelfDataChange,
  onChangeBySelfDataChange,
  onApplicationReadinessChange,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  showErrorAlerts
}) => {
  const { t } = useTranslation();
  const isNoDataGiven = (!extensionBySelfData && !changeBySelfData);
  const [activeSection, setActiveSection] = useState<string>('');
  const toggleActiveSection = (key: string) => setActiveSection(activeSection === key ? '' : key);
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  const [isBasicInfoSecReady, setIsBasicInfoSecReady] = useState<boolean>(false);
  const [isStatusOfResidenceSecReady, setIsStatusOfResidenceSecReady] = useState<boolean>(false);
  const [isDetailsOfExtensionSecReady, setIsDetailsOfExtensionSecReady] = useState<boolean>(false);
  const [isDetailsOfChangeSecReady, setIsDetailsOfChangeSecReady] = useState<boolean>(false);
  const [isFamilyCohabitantsSecReady, setIsFamilyCohabitantsSecReady] = useState<boolean>(false);
  const [isDependentSecReady, setIsDependentSecReady] = useState<boolean>(false);
  const [isOtherSecReady, setIsOtherSecReady] = useState<boolean>(false);
  const [isAttachmentsSecReady, setIsAttachmentsSecReady] = useState<boolean>(false);

  useEffect (() => {
    const conditionalSecsReady: boolean[] = [];

    if (isVisaExtension) {
      conditionalSecsReady.push(isDetailsOfExtensionSecReady);
    }

    if (isVisaChange) {
      conditionalSecsReady.push(isDetailsOfChangeSecReady);
    }

    onApplicationReadinessChange([
      isBasicInfoSecReady,
      isStatusOfResidenceSecReady,
      isFamilyCohabitantsSecReady,
      isDependentSecReady,
      isOtherSecReady,
      isAttachmentsSecReady,

      ...conditionalSecsReady
    ].every(isSectionReady => isSectionReady));
  }, [
    isBasicInfoSecReady,
    isStatusOfResidenceSecReady,
    isDetailsOfExtensionSecReady,
    isFamilyCohabitantsSecReady,
    isDependentSecReady,
    isOtherSecReady,
    isAttachmentsSecReady
  ])
  
  if (isNoDataGiven)
    return null;
    

  return (
    <>
      {/* Basic Information */}
      <Accordion
        active={activeSection === 'basic'}
        label={t("basicInformationSection.title")}
        onClick={() => toggleActiveSection('basic')}
        isError={showErrorAlerts && !isBasicInfoSecReady}
      >
        <BasicInformationSection
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onExtensionBySelfDataChange}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onSectionReadinessChange={setIsBasicInfoSecReady}
          isEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>
      

      {/* Status of Residence */}
      <Accordion
        active={activeSection === 'residence'}
        label={t("statusOfResidenceSection.title")}
        onClick={() => toggleActiveSection('residence')}
        isError={showErrorAlerts && !isStatusOfResidenceSecReady}
      >
        <StatusOfResidenceSection
          visaApplicationType={visaApplicationType}
          extensionBySelfData={extensionBySelfData}
          changeBySelfData={changeBySelfData}
          onChangeExtensionBySelfData={onExtensionBySelfDataChange}
          onChangeChangeBySelfData={onChangeBySelfDataChange}
          onSectionReadinessChange={setIsStatusOfResidenceSecReady}
          isEmptyRequiredValueError={showErrorAlerts}
        />
      </Accordion>
    

      {/* Details of Extension */}
      {/* TODO: Render conditionally when Visa Change for Family visa is added */}
      <Accordion
        active={activeSection === 'details'}
        label={t("detailsOfExtensionSection.title")}
        onClick={() => toggleActiveSection('details')}
        isError={showErrorAlerts && !isDetailsOfExtensionSecReady}
      >
          <DetailsOfExtensionSection
            visaApplicationType={visaApplicationType}
            extensionBySelfData={extensionBySelfData}
            onChangeExtensionBySelfData={onExtensionBySelfDataChange}
            onSectionReadinessChange={setIsDetailsOfExtensionSecReady}
            isEmptyRequiredValueError={showErrorAlerts}
          />
      </Accordion>


      {/* Family/Co-residents */}
      <Accordion
        active={activeSection === 'family-coresidents'}
        label={t("familyCoresidentsSection.title")}
        onClick={() => toggleActiveSection('family-coresidents')}   
        isError={showErrorAlerts && !isFamilyCohabitantsSecReady}
      >
          <FamilyCoresidentsSection
            visaApplicationType={visaApplicationType}
            extensionBySelfData={extensionBySelfData}
            changeBySelfData={changeBySelfData}
            onChangeExtensionBySelfData={onExtensionBySelfDataChange}
            onChangeChangeBySelfData={onChangeBySelfDataChange}
            onSectionReadinessChange={isThereError => setIsFamilyCohabitantsSecReady(isThereError)}
            isEmptyRequiredValueError={showErrorAlerts}
          />
      </Accordion>


      {/* Dependent */}
      <Accordion
        active={activeSection === 'dependent'}
        label={t('dependentSection.title')}
        onClick={() => toggleActiveSection('dependent')}   
        isError={showErrorAlerts && !isDependentSecReady}
      >
          <DependentSection 
            visaApplicationType={visaApplicationType}
            visaAttachments={visaAttachments}
            onVisaAttachmentUpload={onVisaAttachmentUpload}
            onVisaAttachmentDelete={onVisaAttachmentDelete}
            onSectionReadinessChange={setIsDependentSecReady}
            showUnattachedAlert={showErrorAlerts}
          />
      </Accordion>


      {/* Other */}
      <Accordion
        active={activeSection === 'other'}
        label={t("otherSection.title")}
        onClick={() => toggleActiveSection('other')}
        isError={showErrorAlerts && !isOtherSecReady}
      >
          <OtherSection
            visaApplicationType={visaApplicationType}
            extensionBySelfData={extensionBySelfData}
            changeBySelfData={changeBySelfData}
            onChangeExtensionBySelfData={onExtensionBySelfDataChange}
            onChangeChangeBySelfData={onChangeBySelfDataChange}
            onSectionReadinessChange={setIsOtherSecReady}
            isEmptyRequiredValueError={showErrorAlerts}
          />
      </Accordion>


      {/* Attachments */}
      <Accordion
        active={activeSection === 'attachment'}
        label={t("attachmentsSection.title")}
        onClick={() => toggleActiveSection('attachment')}
        iconComponent={<ClipIcon />}
        isError={ showErrorAlerts && !isAttachmentsSecReady }
      >
          <AttachmentsSection 
            visaApplicationType={visaApplicationType}
            visaAttachments={visaAttachments}
            onVisaAttachmentUpload={onVisaAttachmentUpload}
            onVisaAttachmentDelete={onVisaAttachmentDelete}
            onSectionReadinessChange={setIsAttachmentsSecReady}
            showUnattachedAlert={showErrorAlerts}
          />
      </Accordion>
    </>
  )
}

export default DependentVisaForm;