import { FormR } from "../types/visa/uncommonFormParts/formR";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

export const createFormR = async (visaApplicationId: number | string, data?: Partial<FormR>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `form_rs`,
      data: {
        ...data,
        visa_application_id: visaApplicationId
      }
  });

  if (hasErrorStatus(response)) 
    throwRequestError('createFormR', response.status);

  return response.data as FormR;
};

export const getFormR = async (formRId: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `form_rs/${formRId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('getFormR', response.status);

  return response.data as FormR;
};

export const updateFormR = async (formRId: number | string, data: Partial<FormR>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `form_rs/${formRId}`,
      data
  });

  if (hasErrorStatus(response))
    throwRequestError('updateFormR', response.status);

  return response.data as FormR;
};

export const deleteFormR = async (formRId: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `form_rs/${formRId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('deleteFormR', response.status); 

  return response.data as FormR;
};


