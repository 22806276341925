import { FunctionComponent, useEffect, useState } from 'react';
import { VisaFormProps } from '../../../types/visa/formProp';
import Accordion from '../../../components/navigation/Accordion';
import BasicInformationSection from '../ApplicantBlock/BasicInformationSection';
import StatusOfResidenceSection from '../ApplicantBlock/StatusOfResidenceSection';
import { useTranslation } from 'react-i18next';
import DetailsOfExtensionSection from '../ApplicantBlock/DetailsOfExtensionSection';
import FamilyCoresidentsSection from '../ApplicantBlock/FamilyCoresidentsSection';
import { ReactComponent as ClipIcon } from '../../../assets/icon-clip.svg';
import styled from 'styled-components';
import Text from '../../../components/text/Text';
import Column from '../../../components/layout/Column';
import AttachmentsSection from '../AttachmentsBlock/AttachmentSection/AttachmentsSection';
import { SupplementaryInfo } from '../../../types/uncommonFormParts/supplementaryInfo';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import PlaceOfStudySection from '../ApplicantBlock/PlaceOfStudySection';
import StudentVisaFinalEducationSection from '../ApplicantBlock/FinalEducationSections.tsx/StudyAbroadVisaFinalEducationSection';
import JaLangAbilityAndEduHistorySection from '../ApplicantBlock/JaLangAbilityAndEduHistorySection';
import MethodOfSupportToPaySection from '../ApplicantBlock/MethodOfSupportToPaySection';
import StudentVisaOrganizationSection from '../OrganizationBlock/OrganizationSections/StudyAbroadVisaOrganizationSection';
import { FormP } from '../../../types/uncommonFormParts/formP/data';
import StudyAbroadVisaOthersSection from '../ApplicantBlock/OthersSections/StudyAbroadVisaOthersSection';

interface StudentVisaFormProps extends VisaFormProps {
  formP: FormP | null;
  supplementaryInfo: SupplementaryInfo | null;
  onChangeFormP: (data: Partial<FormP>) => void;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onVisaAttachmentsDelete: (visaAttachmentIds: number []) => void;
} 

const ContentWrapper = styled(Column)`
  gap: 40px;
`;

const BlockWrapper = styled.div`
  width: 100%;
`;

const BlockHeadingWrapper = styled.div`
  padding: 10px 20px 20px 20px;
`;

const BlockHeading = styled(Text)`
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
`;

const StudyAbroadVisaForm: FunctionComponent<StudentVisaFormProps>  = ({
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  formP,
  supplementaryInfo,
  visaAttachments,
  onExtensionBySelfDataChange,
  onChangeBySelfDataChange,
  onChangeFormP,
  onChangeSupplementaryInfo,
  onApplicationReadinessChange,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onVisaAttachmentsDelete,
  showErrorAlerts
}) => {
  const { t } = useTranslation();
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const isMissingSomething = (vals: any []) => vals.some(val => !!!val);
  const isDataInsufficient = 
    (isVisaExtension && isMissingSomething([extensionBySelfData, formP, supplementaryInfo])) || 
    (isVisaChange && isMissingSomething([changeBySelfData, formP, supplementaryInfo]));
  const [activeSection, setActiveSection] = useState<string>('');
  const toggleActiveSection = (key: string) => setActiveSection(activeSection === key ? '' : key);

  const [isBasicInfoSecReady, setIsBasicInfoSecReady] = useState<boolean>(false);
  const [isStatusOfResidenceSecReady, setIsStatusOfResidenceSecReady] = useState<boolean>(false);
  const [isDetailsOfExtensionSecReady, setIsDetailsOfExtensionSecReady] = useState<boolean>(false);
  const [isDetailsOfChangeSecReady, setIsDetailsOfChangeSecReady] = useState<boolean>(false);
  const [isFamilyCohabitantsSecReady, setIsFamilyCohabitantsSecReady] = useState<boolean>(false);
  const [isPlaceOfStudySecReady, setIsPlaceOfStudySecReady] = useState<boolean>(false);
  const [isFinalEducationSecReady, setIsFinalEducationSecSecReady] = useState<boolean>(false);
  const [isJaLangAbilityAndEduHistorySecReady, setIsJaLangAbilityAndEduHistorySecReady] = useState<boolean>(false);
  const [isMethodOfSupportToPaySecReady, setIsMethodOfSupportToPaySecReady] = useState<boolean>(false);
  const [isOthersSecReady, setIsOthersSecReady] = useState<boolean>(false);
  const [isOrganizationSecReady, setIsOrganizationSecReady] = useState<boolean>(false);
  const [isAttachmentsSecReady, setIsAttachmentsSecReady] = useState<boolean>(false);


  useEffect (() => {
    const conditionalSecsRediness = [];

    if (isVisaExtension) {
      conditionalSecsRediness.push(isDetailsOfExtensionSecReady);
    }

    if (isVisaChange) {
      conditionalSecsRediness.push(isDetailsOfChangeSecReady);
    }

    onApplicationReadinessChange([
      //Common sections
      isBasicInfoSecReady,
      isStatusOfResidenceSecReady,
      isDetailsOfExtensionSecReady,
      isFamilyCohabitantsSecReady,

      //Uncommon sections
      isPlaceOfStudySecReady,
      isFinalEducationSecReady,
      isJaLangAbilityAndEduHistorySecReady,
      isMethodOfSupportToPaySecReady,
      isOrganizationSecReady,
      isAttachmentsSecReady,

      ...conditionalSecsRediness
    ].every(isSectionReady => isSectionReady));
  }, [
    isBasicInfoSecReady,
    isStatusOfResidenceSecReady,
    isDetailsOfExtensionSecReady,
    isDetailsOfChangeSecReady,
    isFamilyCohabitantsSecReady,
    isPlaceOfStudySecReady,
    isFinalEducationSecReady,
    isJaLangAbilityAndEduHistorySecReady,
    isMethodOfSupportToPaySecReady,
    isOthersSecReady,
    isOrganizationSecReady,
    isAttachmentsSecReady
  ])
  
  // if (isDataInsufficient){
  //   return null;
  // }
    

  return (
    <ContentWrapper>

      {/* Application block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.forApplicant")}</BlockHeading>
        </BlockHeadingWrapper>

        <div>
          {/* Basic Information */}
          <Accordion
            active={activeSection === 'basic'}
            label={t("basicInformationSection.title")}
            onClick={() => toggleActiveSection('basic')}
            isError={showErrorAlerts && !isBasicInfoSecReady}
          >
            <BasicInformationSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsBasicInfoSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>
          

          {/* Status of Residence */}
          <Accordion
            active={activeSection === 'residence'}
            label={t("statusOfResidenceSection.title")}
            onClick={() => toggleActiveSection('residence')}
            isError={showErrorAlerts && !isStatusOfResidenceSecReady}
          >
            <StatusOfResidenceSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsStatusOfResidenceSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>
        

          {/* Details of Extension */}
          {/* TODO: Render conditionally when Visa Change is added */}
          <Accordion
            active={activeSection === 'details'}
            label={t("detailsOfExtensionSection.title")}
            onClick={() => toggleActiveSection('details')}
            isError={showErrorAlerts && !isDetailsOfExtensionSecReady}
          >
            <DetailsOfExtensionSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onSectionReadinessChange={setIsDetailsOfExtensionSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Family/Co-residents */}
          <Accordion
            active={activeSection === 'family-coresidents'}
            label={t("familyCoresidentsSection.title")}
            onClick={() => toggleActiveSection('family-coresidents')}   
            isError={showErrorAlerts && !isFamilyCohabitantsSecReady}
          >
            <FamilyCoresidentsSection
              visaApplicationType={visaApplicationType}
              extensionBySelfData={extensionBySelfData}
              changeBySelfData={changeBySelfData}
              onChangeExtensionBySelfData={onExtensionBySelfDataChange}
              onChangeChangeBySelfData={onChangeBySelfDataChange}
              onSectionReadinessChange={setIsFamilyCohabitantsSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Place of study */}
          <Accordion
            active={activeSection === 'place-of-study'}
            label={t("placeOfStudySection.title")}
            onClick={() => toggleActiveSection('place-of-study')}   
          >
            <PlaceOfStudySection 
              visaApplicationType={visaApplicationType}
              onSectionReadinessChange={setIsPlaceOfStudySecReady}
              isEmptyRequiredValueError={showErrorAlerts}
              formP={formP}
              onChangeFormP={onChangeFormP}
            />
          </Accordion>


          {/* Final Education */}
          <Accordion
            active={activeSection === 'final-education'}
            label={t("finalEducationSection.title")}
            onClick={() => toggleActiveSection('final-education')}   
            isError={showErrorAlerts && !isFinalEducationSecReady}
          >
            <StudentVisaFinalEducationSection
              visaApplicationType={visaApplicationType}
              onSectionReadinessChange={setIsFinalEducationSecSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
              formP={formP}
              onChangeFormP={onChangeFormP}
            />
          </Accordion>


          {/* Japanese language ability & education history */}
          <Accordion
            active={activeSection === 'ja-lang-ability-and-edu-history'}
            label={t("jaLangAbilityAndEduHistorySection.title")}
            onClick={() => toggleActiveSection('ja-lang-ability-and-edu-history')}   
            isError={showErrorAlerts && !isJaLangAbilityAndEduHistorySecReady}
          >
            <JaLangAbilityAndEduHistorySection
              visaApplicationType={visaApplicationType}
              formP={formP!}
              onChangeFormP={onChangeFormP}
              onSectionReadinessChange={setIsJaLangAbilityAndEduHistorySecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Method of support to pay for expenses while in Japan */}
          <Accordion
            active={activeSection === 'method-of-support-to-pay'}
            label={t("methodOfSupportToPaySection.title")}
            onClick={() => toggleActiveSection('method-of-support-to-pay')}   
            isError={showErrorAlerts && !isMethodOfSupportToPaySecReady}
          >
            <MethodOfSupportToPaySection
              visaApplicationType={visaApplicationType}
              formP={formP!}
              onChangeFormP={onChangeFormP}
              onSectionReadinessChange={setIsMethodOfSupportToPaySecReady}
              isEmptyRequiredValueError={showErrorAlerts}
            />
          </Accordion>


          {/* Other */}
          <Accordion
            active={activeSection === 'other'}
            label={t("otherSection.title")}
            onClick={() => toggleActiveSection('other')}
            isError={showErrorAlerts && !isOthersSecReady}
          >
              <StudyAbroadVisaOthersSection
                visaApplicationType={visaApplicationType}
                formP={formP!}
                supplementaryInfo={supplementaryInfo!}
                onChangeFormP={onChangeFormP}
                onChangeSupplementaryInfo={onChangeSupplementaryInfo}
                onSectionReadinessChange={setIsOthersSecReady}
              />
          </Accordion>
        </div>

      </BlockWrapper>


      {/* Organization block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.forOrganization")}</BlockHeading>
        </BlockHeadingWrapper>
        
        <div>
          {/* Organization */}
          <Accordion
            active={activeSection === 'organization'}
            label={t("organizationSection.title")}
            onClick={() => toggleActiveSection('organization')}
            isError={ showErrorAlerts && !isOrganizationSecReady }
          >
            <StudentVisaOrganizationSection
              visaApplicationType={visaApplicationType}
              formP={formP!}
              onChangeFormP={onChangeFormP}
              onSectionReadinessChange={setIsOrganizationSecReady}
              isEmptyRequiredValueError={showErrorAlerts}
              supplementaryInfo={supplementaryInfo!}
              onChangeSupplementaryInfo={onChangeSupplementaryInfo}
            />          
          </Accordion>
        </div>

      </BlockWrapper>


      {/* Attachments block */}
      <BlockWrapper>

        <BlockHeadingWrapper>
          <BlockHeading>{t("applicationFormPage.blocks.attachments")}</BlockHeading>
        </BlockHeadingWrapper>

        <div>
          {/* Attachments */}
          <Accordion
            active={activeSection === 'attachment'}
            label={t("attachmentsSection.title")}
            onClick={() => toggleActiveSection('attachment')}
            iconComponent={<ClipIcon />}
            isError={ showErrorAlerts && !isAttachmentsSecReady }
          >
              <AttachmentsSection 
                visaApplicationType={visaApplicationType}
                visaAttachments={visaAttachments}
                supplementaryInfo={supplementaryInfo!}
                onVisaAttachmentUpload={onVisaAttachmentUpload}
                onVisaAttachmentDelete={onVisaAttachmentDelete}
                onVisaAttachmentsDelete={onVisaAttachmentsDelete}
                onChangeSupplementaryInfo={onChangeSupplementaryInfo}
                onSectionReadinessChange={setIsAttachmentsSecReady}
                showRequiredAlert={showErrorAlerts}
              />
          </Accordion>
        </div>

      </BlockWrapper>

    </ContentWrapper>
  )
}

export default StudyAbroadVisaForm;