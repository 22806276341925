import { FunctionComponent, useEffect, useState } from 'react';
import Section from '../../../components/layout/Section';
import { useTranslation } from "react-i18next";
import { DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT, DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU } from '../../../constants/options';
import SelectField from "../../../components/compound/SelectField";
import MultiTextField from "../../../components/compound/MultiTextField";
import RadioField from "../../../components/compound/RadioField";
import { ExtensionBySelfData } from '../../../types/extensionBySelf/data';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { HowToReceiveNewResidenceCard } from '../../../types/visa/formCommonValues';
import { useValidation } from '../../../hooks';
import { OFFICE_OPTIONS } from '../../../constants/options/office';
import { SelectOption } from '../../../types/option';

interface DetailsOfExtensionSectionProps {
  visaApplicationType: VisaApplicationType;
  extensionBySelfData: ExtensionBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
}

const DetailsOfExtensionSection: FunctionComponent<DetailsOfExtensionSectionProps> = ({
  visaApplicationType,
  extensionBySelfData,
  onChangeExtensionBySelfData,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'detailsOfExtensionSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();

  const [desiredPeriodOfStay, setDesiredPeriodOfStay] = useState('');
  const [reasonForExtension, setReasonForExtension] = useState('');
  const [reasonForExtensionError, setReasonForExtensionError] = useState('');
  const [receivingMethod, setReceivingMethod] = useState('');
  const [officeToPickUp, setOfficeToPickUp] = useState('');

  //Used to determine which office to pick up at
  const [prefecture, setPrefecture] = useState('');
  const [municipality, setMunicipality] = useState('');
  const [desiredPeriodOfStayOptions, setDesiredPeriodOfStayOptions] = useState<SelectOption[]>([]);

  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field") : '';
  const saveExtensionData = (data: Partial<ExtensionBySelfData>) => {
    onChangeExtensionBySelfData(data as Partial<ExtensionBySelfData>);
  }

  const isAddressInfoInsufficient = !prefecture || !municipality;
  
  useEffect(() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendDependentVisaBySelf:
        setDesiredPeriodOfStayOptions(DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT);
        break;
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
        setDesiredPeriodOfStayOptions(DESIRED_PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU);
        break;
    }
  }, [visaApplicationType])

  useEffect(() => {
    if (!extensionBySelfData) 
      return;

    const data = extensionBySelfData;

    setReasonForExtension(data['WCIBS010Dto:txtKusnReason'] || '');
    setReceivingMethod(data['WCIBS010Dto:radZiryCardJryHuhu'] || '');
    setOfficeToPickUp(data['WCIBS010Dto:selJryuKnsh'] || '');

    if (data['WCIBS010Dto:hdnSearchedAddress']) {
      setPrefecture(data['WCIBS010Dto:hdnSearchedAddress'].slice(0, 2) ?? '');
      setMunicipality(data['WCIBS010Dto:hdnSearchedAddress'].slice(2) ?? '');
    }

    setDesiredPeriodOfStay(data['WCIBS010Dto:selNewZiryPeriod'] ?? '');  
  }, [extensionBySelfData]);


  useEffect(() => {
    const receivingOffice = 
      receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice 
        ? [officeToPickUp] 
        : [];

    const areAllRequiredFieldsFilled = 
      [
        desiredPeriodOfStay,
        reasonForExtension,
        receivingMethod,
        ...receivingOffice
      ].every(val => !!val);

    const areThereNoErrors =
      [
        reasonForExtensionError
      ].every(err => !err);

    onSectionReadinessChange(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    desiredPeriodOfStay,
    reasonForExtension,
    reasonForExtensionError,
    receivingMethod,
    officeToPickUp
  ])

  return (
    <Section>
      {/* Desired period of stay */}
      <SelectField
        required
        label={t("desiredPeriodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        //TODO: We might share this section with other visa types
        //In that case, the following options should be reveiwed
        //(and changed if necessary)
        options={desiredPeriodOfStayOptions ?? []}
        value={desiredPeriodOfStay}
        error={getEmptyError(desiredPeriodOfStay)}
        onValueChange={val => {
          setDesiredPeriodOfStay(val);
          saveExtensionData({ 'WCIBS010Dto:selNewZiryPeriod': val });
        }}
      />

      {/* Reason for extension */}
      <MultiTextField
        required
        label={t("reasonOfExtention")}
        placeholder={"この先も引き続き日本において夫と暮らしていくため"}
        value={reasonForExtension}
        error={reasonForExtensionError || getEmptyError(reasonForExtension)}
        restriction={tWithoutPrefix('inputNote.fullwidthJapanese')}
        maxLength={600}
        validators={[validation.isFullwidth, validation.createLengthValidator(600)]}
        onValueChange={setReasonForExtension}
        onErrorChange={setReasonForExtensionError}
        onBlur={() => {
          saveExtensionData({ 'WCIBS010Dto:txtKusnReason': reasonForExtension });
        }}
      />

      {/* How will you receive your new residence card? */}
      <RadioField
        required
        label={t("recievingNewResidenceCard")}
        options={[
          {
            label: t("byMail"),
            value: HowToReceiveNewResidenceCard.Mail,
          },
          {
            label: t("pickUpAtImmigrationOffice"),
            value: HowToReceiveNewResidenceCard.VisitImmigrationOffice,
          },
        ]}
        value={receivingMethod}
        error={getEmptyError(receivingMethod)}
        onValueChange={val => {
          if (val === HowToReceiveNewResidenceCard.Mail) {
            saveExtensionData({ 
              'WCIBS010Dto:radZiryCardJryHuhu': val,
              'WCIBS010Dto:selJryuKnsh': ''
            });
          }
          
          if (val === HowToReceiveNewResidenceCard.VisitImmigrationOffice) {
            saveExtensionData({ 'WCIBS010Dto:radZiryCardJryHuhu': val });
          }

          setReceivingMethod(val);
        }}
      />

      {/* Which office to pick up at */}
      {receivingMethod === HowToReceiveNewResidenceCard.VisitImmigrationOffice && (
        <SelectField
          disabled={isAddressInfoInsufficient}
          label={t("pickUpOffice")}
          options={
            OFFICE_OPTIONS[`${prefecture}${municipality}`] 
            ?? OFFICE_OPTIONS[prefecture] 
            ?? []
          }
          value={officeToPickUp}
          error={
            (isAddressInfoInsufficient && t("pleaseEnterAddressFirst")) ||
            getEmptyError(officeToPickUp) 
          }
          onValueChange={val => {
            setOfficeToPickUp(val);
            saveExtensionData({ 'WCIBS010Dto:selJryuKnsh': val });
          }}
        />
      )}
    </Section>
  );
};

export default DetailsOfExtensionSection;