import { FunctionComponent, useEffect, useState } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import {
  getLabelFromOfficeCode,
  getLabelFromDesiredPeriodOfStay,
  getTransKeyForHowToReceiveNewResidenceCard,
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import { PreviewHeading } from "../../previewBodyCommonStyle";
import { HowToReceiveNewResidenceCard } from "../../../../../../types/visa/formCommonValues";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";
import PreviewList from "../../../PreviewList";

interface DetailsOfExtensionSectionProps {
  visaApplicationType: VisaApplicationType;
  data: ExtensionBySelfData;
}

//As the name implies, this section only handles ExtensionBySelfData, not ChangeBySelfData
export const DetailsOfExtensionSection: FunctionComponent<
  DetailsOfExtensionSectionProps
> = ({ visaApplicationType, data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'detailsOfExtensionSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const [prefecture, setPrefecture] = useState("");
  const [municipality, setMunicipality] = useState("");

  useEffect(() => {
    if (data["WCIBS010Dto:hdnSearchedAddress"]) {
      setPrefecture(data["WCIBS010Dto:hdnSearchedAddress"].slice(0, 2) ?? "");
      setMunicipality(data["WCIBS010Dto:hdnSearchedAddress"].slice(2) ?? "");
    }
  }, []);

  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      <PreviewList
        items={[
          {
            label: t("desiredPeriodOfStay"),
            value: tWithoutPrefix(
              `${getLabelFromDesiredPeriodOfStay(
                data["WCIBS010Dto:selNewZiryPeriod"]
              )}`
            ),
          },
          {
            label: t("reasonOfExtention"),
            value: data["WCIBS010Dto:txtKusnReason"],
          },
          {
            label: t("recievingNewResidenceCard"),
            value: getTransKeyForHowToReceiveNewResidenceCard(data["WCIBS010Dto:radZiryCardJryHuhu"])
              ? t(`${
                  getTransKeyForHowToReceiveNewResidenceCard(data["WCIBS010Dto:radZiryCardJryHuhu"])
                }`)
              : ""
          },
          {
            label: t("pickUpOffice"),
            value: tWithoutPrefix(
              `${getLabelFromOfficeCode(
                data["WCIBS010Dto:selJryuKnsh"],
                prefecture,
                municipality
              )}`
            ),
            skip:
              data["WCIBS010Dto:radZiryCardJryHuhu"] ===
              HowToReceiveNewResidenceCard.Mail,
          },
        ]}
      />
    </section>
  );
};

export default DetailsOfExtensionSection;
