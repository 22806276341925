import { FunctionComponent } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "styled-components";
import Modal from "./Modal";
import Button from "../form/Button";
import Text from "../text/Text";
import Column from "../layout/Column";
import { CONTENT_MAX_WIDTH } from "../../constants/styles";
import { ReactComponent as CloseIcon } from "../../assets/icon-cross.svg";
import PageHeading from "../text/PageHeading";
import EmailAnchor from "../text/EmailAnchor";
import { FEE_PER_ONE_APPLICATION, VISADAS_SUPPORT_EMAIL } from "../../constants/values/commonValues";
import Row from "../layout/Row";

interface PaymentPromptModalProps {
  onClose: () => void;
  promptType?: "required" | "general"
  paymentUrl: string;
}

const ContentContainer = styled.div`
  background-color: #ffffff;
  width: 100%;
  height: 100%;
  overflow: auto;
`;

const Content = styled(Column)`
  padding: 20px;
  max-width: ${CONTENT_MAX_WIDTH}px;
  height: auto;
  min-height: 100%;
  margin: 0 auto;
  gap: 30px;
`;

const DetailContainer = styled(Column)`
  gap: 20px;
`;

const FeeInfoContrainer = styled(Column)`
  width: 100%;
  background-color: #f7f7f7;
  padding: 10px;
  gap: 5px;
`;

const SmallText = styled(Text)`
  white-space: "pre-wrap";
  display: inline;
  font-size: 12px;
  color: #999999;
  line-height: 20px;
`;

const ButtonContainer = styled.div`
  margin-top: 58px;
  width: 100%;

  @media (max-width: ${CONTENT_MAX_WIDTH}px) {
    flex-grow: 1;
    display: flex;
    flex-direction: column-reverse;
  }
`;

const PaymentPromptModal: FunctionComponent<PaymentPromptModalProps> = ({
  paymentUrl,
  promptType = "general",
  onClose,
}) => {
  const { t } = useTranslation();
  const onProceed = () => { window.location.href = paymentUrl; }

  return (
    <Modal>
      <ContentContainer>
        <Content>
          <Row style={{ justifyContent: 'space-between' }}>
            <PageHeading>
              { promptType === "general" && t("modalPaymentPrompt.title.paymentForVisadas")}
              { promptType === "required" && t("modalPaymentPrompt.title.paymentRequired")}
            </PageHeading>
            <CloseIcon onClick={onClose} style={{ cursor: 'pointer'}}/>
          </Row>
          <DetailContainer>
            <Text style={{ fontWeight: 500 }}>
              {t("modalPaymentPrompt.description1")}
            </Text>
            <FeeInfoContrainer>
              <Text style={{ textDecoration: 'underline' }}>
                {t("modalPaymentPrompt.feeFor1Application")}
              </Text>
              <Text>{t("modalPaymentPrompt.yenTaxIncluded", { amount: FEE_PER_ONE_APPLICATION })}</Text>
            </FeeInfoContrainer>
            <span>
              <Text>{t("modalPaymentPrompt.description2")}</Text>
              <Text>{t("modalPaymentPrompt.description3")}</Text>
              <Text>{t("modalPaymentPrompt.description4")}</Text>
            </span>
            <Text>{t(("modalPaymentPrompt.description5"))}</Text>
          </DetailContainer>
          <SmallText>
            <Trans
              i18nKey={"common.ifYouAreHavingTrouble"}
              components={{
                email: VISADAS_SUPPORT_EMAIL,
                anchor: <EmailAnchor mailTo={VISADAS_SUPPORT_EMAIL} />,
              }}
            />
          </SmallText>
          <ButtonContainer>
            <Button variant="primary" onClick={onProceed}>
              {t("modalPaymentPrompt.proceedToPayment")}
            </Button>
          </ButtonContainer>
        </Content>
      </ContentContainer>
    </Modal>
  );
};

export default PaymentPromptModal;
