
//TODO: since the stored value is directly printed out in the PDF, 
//we should use more human-readable values instead of numerical codes.
//Used for "How do you prove your Japanese language ability"
export enum JapaneseLanguageAbilityProof {
  Test = 'test',
  Education = 'education',
  Other = 'other',
}

export enum MethodOfSupport {
  Self = "self",
  SupporterLivingAbroad = "supporter_living_abroad",
  SupporterInJapan = "supporter_in_japan",
  Scholarship = "scholarship",
  Others = "others",
}

export enum OrgWhichProvidesScholarship {
  ForeignGovernment = "foreign_government",
  JapaneseGovernment = "japanese_government",
  LocalGovernment = "local_government",
  PublicInterestIncorporatedAssociationOrFoundation = "public_interest_incorporated_association_or_foundation",
  Others = "others",
}

export enum RemittancesFromAbroadOrCarryingCash {
  CarryingFromAbroad = "carrying_from_abroad",
  RemittancesFromAbroad = "remittances_from_abroad",
  Others = "others",
}

//"Are you engaging in activities other than those permitted under the status of residence previously granted?"
export enum EngagingInActivitiesOtherThanPermitted {
  Yes = "はい",
  No = "いいえ",
}

export enum ActivitySalaryType {
  Monthly = "月給",
  Daily = "日給",
}

//"Is the applicant participating in a student exchange program?"
export enum IsParticipatingInStudentExProgram {
  Yes = "はい",
  No = "いいえ",
}

//Is the applicant to study at a junior high school or elementary school.
export enum IsApplicantToStudyAtSchool {
  Yes = "はい",
  No = "いいえ",
}

//Is this application being made by a legal representative
export enum IsMadeByLegalRepresentative {
  Yes = "はい",
  No = "いいえ",
}

//The following values are part of option values in this form.
//We use them to decide if we need to show some fileds or not.
export const FINAL_EDUCATION_OTHER_VALUE = "その他";
export const PLAN_AFTER_GRADUATION_OTHER_VALUE = "その他";
export const RELATIONSHIP_W_SUPPORTER_ABROAD_OTHER_VALUE = "その他";
export const RELATIONSHIP_W_SUPPORTER_IN_JAPAN_OTHER_VALUE = "その他";
export const ORG_IN_CHARGE_OTHER_VALUE = "その他";

/*  from the original form (ref: https://www.moj.go.jp/isa/content/930004106.pdf):
  "大学院，大学，短期大学（いずれも聴講生・科目等履修生及び研究生の場合を含む）を選択した場合に記入）"
*/
export const REGISTRATION_VALUES_WHICH_REQUIRE_FACULTY_COURSE = [
  "大学院(博士)",
  "大学院(修士)",
  "大学院(研究生/専ら聴講によらない)",
  "大学院(研究生/専ら聴講による)",
  "大学(学部生)",
  "大学(聴講生・科目等履修生)",
  "大学(研究生/専ら聴講によらない)",
  "大学(研究生/専ら聴講による)",
  "大学(別科生)",
  "短期大学(学科生)",
  "短期大学(聴講生・科目等履修生)",
  "短期大学(別科生)"
];


/* from the original form:
  "大学院を選択した場合に記入"
*/
export const REGISTRATION_VALUES_WHICH_REQUIRE_RESEARCH_ROOM_INFO = [
  "大学院(博士)",
  "大学院(修士)",
  "大学院(研究生/専ら聴講によらない)",
  "大学院(研究生/専ら聴講による)"
];

/* from the original form:
  "高等専門学校～各種学校を選択した場合に記入"
*/
export  const REGISTRATION_VALUES_WHICH_REQUIRE_SPECIALIZED_COURSE_NAME = [
  "高等専門学校",
  "専修学校(専門課程)",
  "専修学校(高等課程)",
  "専修学校(一般課程)",
  "各種学校"
];