import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { VisaAttachmentKind } from "../../../../../../../../types/visa/attachment";
import { extractVisaAttachments, getTransKeyForFirstRenewal } from "../../../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { AttachmentsBodyProps } from "../../../../../../../../types/modal/attachment";
import PreviewList from "../../../../../PreviewList";
import { FirstRenewalApplication, OrganizationType } from "../../../../../../../../types/uncommonFormParts/supplementaryInfo";
import styled from "styled-components";
import { PreviewAttachment, PreviewItem } from "../../../../../../../../types/modal/preview";
import { isThisVisaChange, isThisVisaExtension } from "../../../../../../../../utils/visaApplicationHelper";


const CustomList = styled.li`
  list-style: none;
  padding-left: 0;

  &::before {
    content: "-";
    margin-right: 5px;
  }
`;

export const GijinkokuVisaAttachments: FunctionComponent<AttachmentsBodyProps> = ({
  visaApplication,
  visaAttachments
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'attachmentsSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const [orgDescription, setOrgDescription] = useState<ReactNode>();
  const [orgTypeDependentItems, setOrgTypeDependentItems] = useState<(PreviewItem | PreviewAttachment)[]>([]);
  const supplementaryInfo = visaApplication.supplementary_info;
  const isVisaChange = isThisVisaChange(visaApplication);
  const isVisaExtension = isThisVisaExtension(visaApplication);

  useEffect(() => {
    const orgOption = visaApplication.supplementary_info["appropriate_organization_description"];
    let relatedItems: (PreviewItem | PreviewAttachment) [] = [];

    //Since this option part is large, we handle it here
    switch (orgOption) {
      case OrganizationType.OptionA:
        setOrgDescription((
          <span>
            {t("selectAppropriateDescriptionForYourOrg.optionA.description")}
            <br />
            <ul style={{ padding: 0, margin: 0 }}>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition1")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition2")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition3")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition4")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition5")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition6")}</CustomList>
              <CustomList>{t("selectAppropriateDescriptionForYourOrg.optionA.condition7")}</CustomList>
            </ul>
          </span>
        ))
        relatedItems = [
          {
            label: t('docsCertifyingThatTheAboveApplies.label'),
            files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsProvingCategory)
          }
        ];

        break;
      case OrganizationType.OptionB:
        setOrgDescription(
          <span>
            <Trans
              i18nKey={t("selectAppropriateDescriptionForYourOrg.optionB.description")}
              components={{
                b: <span />, //In the preview modal, we don't want to display the bold text
              }}
            />
          </span>
        );
        relatedItems = [
          {
            label: t('docsCertifyingThatTheAboveApplies.label'),
            files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsProvingCategory)
          }
        ];
        break;
      case OrganizationType.OptionC:
        setOrgDescription(
          <span>
            <Trans
              i18nKey={t("selectAppropriateDescriptionForYourOrg.optionC.description")}
              components={{
                b: <span />,
              }}
            />
          </span>
        );

        {
          const firstRenewalApp = supplementaryInfo["first_renewal_application"] as FirstRenewalApplication;
          relatedItems = [
            {
              label: t('docsCertifyingThatTheAboveApplies.label'),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsProvingCategory)
            },
            {
              label: t('firstRenewalAppSinceApplicantHasEnteredThisOrg.label'),
              value: tWithoutPrefix(`${getTransKeyForFirstRenewal(firstRenewalApp)}`)
            },
            {
              label: t("docThatDescribesApplicantsJobRespsAndDuties.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("certificateOfRegisteredMatters.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.RegistrationCertificate),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("docsThatDescribeNatureOfBusiness.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("copiesOfFinancialDocsForMostRecentFiscalYear.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.FinancialStatements),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate)
            }
          ];
        }
        break;
      case OrganizationType.OptionD:
        setOrgDescription(
          <span>
            <Trans
              i18nKey={t("selectAppropriateDescriptionForYourOrg.optionD.description")}
              components={{
                b: <span />,
              }}
            />
          </span>
        );

        {
          const firstRenewalApp = supplementaryInfo["first_renewal_application"] as FirstRenewalApplication;
          relatedItems = [
            {
              label: t("docsCertifyingThatTheAboveApplies.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsProvingCategory)
            },
            {
              label: t("firstRenewalAppSinceApplicantHasEnteredThisOrg.label"),
              value: tWithoutPrefix(`${getTransKeyForFirstRenewal(firstRenewalApp)}`)
            },
            {
              label: t("docThatDescribesApplicantsJobRespsAndDuties.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("certificateOfRegisteredMatters.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.RegistrationCertificate),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("docsThatDescribeNatureOfBusiness.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("copiesOfFinancialDocsForMostRecentFiscalYear.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.FinancialStatements),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("docsExplainingReasonForNotBeingAbleToSubmit.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.MaterialsThatClarifyTheReason),
              skip: firstRenewalApp !== FirstRenewalApplication.Yes
            },
            {
              label: t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocsCertifyingApplicantsEduBackgroundWorkHistoryAndOtherBackground),
              skip: isVisaExtension
            },
            {
              label: t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.label"),
              files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate),
              skip: isVisaChange
            }
          ];
        }
        break;
    }

    setOrgTypeDependentItems(relatedItems);
  }, [visaApplication]); 

  return (
    <PreviewList
      items={[
        {
          label: t('passport.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Passport)
        },
        {
          label: t('residenceCard.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.ResidenceCard)
        },
        {
          label: t('photo.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Photo)
        },
        {
          label: t('diplomaForProfessionalTrainingCollege.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.DocCertifyingGrantedTitleOfSpecialistOrAdvancedSpecialist),
          skip: isVisaExtension
        },
        {
          label: t('selectAppropriateDescriptionForYourOrg.label'),
          value: orgDescription
        },

        ...orgTypeDependentItems,

        {
          label: t('other.label'),
          files: extractVisaAttachments(visaAttachments, VisaAttachmentKind.Other)
        },
      ]}
    />
  )
}

export default GijinkokuVisaAttachments;
