//Be careful. This includes Japan as a nationality option

import { SelectOption } from "../../types/option";

//If it should be excluded, use FOREIGNER_NATIONALITY_OPTIONS instead
export const ALL_NATIONALITY_OPTIONS: SelectOption[] = [
    {
        "label": "nationality.afghanistan",
        "value": "001"
    },
    {
        "label": "nationality.albania",
        "value": "002"
    },
    {
        "label": "nationality.algeria",
        "value": "003"
    },
    {
        "label": "nationality.andorra",
        "value": "505"
    },
    {
        "label": "nationality.angola",
        "value": "501"
    },
    {
        "label": "nationality.antigua_and_barbuda",
        "value": "502"
    },
    {
        "label": "nationality.argentina",
        "value": "004"
    },
    {
        "label": "nationality.armenia",
        "value": "503"
    },
    {
        "label": "nationality.australia",
        "value": "005"
    },
    {
        "label": "nationality.austria",
        "value": "006"
    },
    {
        "label": "nationality.azerbaijan",
        "value": "504"
    },
    {
        "label": "nationality.the_bahamas",
        "value": "022"
    },
    {
        "label": "nationality.bahrain",
        "value": "015"
    },
    {
        "label": "nationality.bangladesh",
        "value": "021"
    },
    {
        "label": "nationality.barbados",
        "value": "018"
    },
    {
        "label": "nationality.belarus",
        "value": "025"
    },
    {
        "label": "nationality.belgium",
        "value": "010"
    },
    {
        "label": "nationality.belize",
        "value": "023"
    },
    {
        "label": "nationality.benin",
        "value": "060"
    },
    {
        "label": "nationality.bhutan",
        "value": "016"
    },
    {
        "label": "nationality.bolivia",
        "value": "011"
    },
    {
        "label": "nationality.bosnia_and_herzegovina",
        "value": "602"
    },
    {
        "label": "nationality.botswana",
        "value": "019"
    },
    {
        "label": "nationality.brazil",
        "value": "012"
    },
    {
        "label": "nationality.brunei",
        "value": "024"
    },
    {
        "label": "nationality.bulgaria",
        "value": "013"
    },
    {
        "label": "nationality.burkina_faso",
        "value": "266"
    },
    {
        "label": "nationality.burundi",
        "value": "017"
    },
    {
        "label": "nationality.cambodia",
        "value": "030"
    },
    {
        "label": "nationality.cameroon",
        "value": "032"
    },
    {
        "label": "nationality.canada",
        "value": "033"
    },
    {
        "label": "nationality.cape_verde",
        "value": "050"
    },
    {
        "label": "nationality.central_africa",
        "value": "034"
    },
    {
        "label": "nationality.chad",
        "value": "036"
    },
    {
        "label": "nationality.chile",
        "value": "037"
    },
    {
        "label": "nationality.people's_republic_of_china",
        "value": "039"
    },
    {
        "label": "nationality.colombia",
        "value": "043"
    },
    {
        "label": "nationality.comoros",
        "value": "051"
    },
    {
        "label": "nationality.democratic_republic_of_the_congo",
        "value": "045"
    },
    {
        "label": "nationality.republic_of_congo",
        "value": "044"
    },
    {
        "label": "nationality.costa_rica",
        "value": "046"
    },
    {
        "label": "nationality.cote_d'ivoire",
        "value": "118"
    },
    {
        "label": "nationality.croatia",
        "value": "052"
    },
    {
        "label": "nationality.cuba",
        "value": "047"
    },
    {
        "label": "nationality.cyprus",
        "value": "048"
    },
    {
        "label": "nationality.czech_republic",
        "value": "053"
    },
    {
        "label": "nationality.denmark",
        "value": "061"
    },
    {
        "label": "nationality.djibouti",
        "value": "063"
    },
    {
        "label": "nationality.dominica",
        "value": "064"
    },
    {
        "label": "nationality.dominican_republic",
        "value": "062"
    },
    {
        "label": "nationality.ecuador",
        "value": "070"
    },
    {
        "label": "nationality.egypt",
        "value": "263"
    },
    {
        "label": "nationality.el_salvador",
        "value": "071"
    },
    {
        "label": "nationality.equatorial_guinea",
        "value": "073"
    },
    {
        "label": "nationality.eritrea",
        "value": "075"
    },
    {
        "label": "nationality.estonia",
        "value": "074"
    },
    {
        "label": "nationality.ethiopia",
        "value": "072"
    },
    {
        "label": "nationality.fiji",
        "value": "083"
    },
    {
        "label": "nationality.finland",
        "value": "080"
    },
    {
        "label": "nationality.france",
        "value": "081"
    },
    {
        "label": "nationality.gabon",
        "value": "090"
    },
    {
        "label": "nationality.the_gambia",
        "value": "097"
    },
    {
        "label": "nationality.georgia",
        "value": "550"
    },
    {
        "label": "nationality.germany",
        "value": "091"
    },
    {
        "label": "nationality.ghana",
        "value": "093"
    },
    {
        "label": "nationality.greece",
        "value": "094"
    },
    {
        "label": "nationality.grenada",
        "value": "500"
    },
    {
        "label": "nationality.guatemala",
        "value": "095"
    },
    {
        "label": "nationality.guinea",
        "value": "096"
    },
    {
        "label": "nationality.guinea-bissau",
        "value": "099"
    },
    {
        "label": "nationality.guyana",
        "value": "098"
    },
    {
        "label": "nationality.haiti",
        "value": "100"
    },
    {
        "label": "nationality.honduras",
        "value": "101"
    },
    {
        "label": "nationality.hungary",
        "value": "102"
    },
    {
        "label": "nationality.iceland",
        "value": "110"
    },
    {
        "label": "nationality.india",
        "value": "111"
    },
    {
        "label": "nationality.indonesia",
        "value": "112"
    },
    {
        "label": "nationality.iran",
        "value": "113"
    },
    {
        "label": "nationality.iraq",
        "value": "114"
    },
    {
        "label": "nationality.ireland",
        "value": "115"
    },
    {
        "label": "nationality.israel",
        "value": "116"
    },
    {
        "label": "nationality.italy",
        "value": "117"
    },
    {
        "label": "nationality.jamaica",
        "value": "130"
    },
    {
        "label": "nationality.japan",
        "value": "900"
    },
    {
        "label": "nationality.jordan",
        "value": "131"
    },
    {
        "label": "nationality.kazakhstan",
        "value": "146"
    },
    {
        "label": "nationality.kenya",
        "value": "143"
    },
    {
        "label": "nationality.kiribati",
        "value": "144"
    },
    {
        "label": "nationality.republic_of_korea",
        "value": "140"
    },
    {
        "label": "nationality.kosovo",
        "value": "607"
    },
    {
        "label": "nationality.kuwait",
        "value": "142"
    },
    {
        "label": "nationality.kyrgyz_republic",
        "value": "145"
    },
    {
        "label": "nationality.laos",
        "value": "150"
    },
    {
        "label": "nationality.latvia",
        "value": "157"
    },
    {
        "label": "nationality.lebanon",
        "value": "151"
    },
    {
        "label": "nationality.lesotho",
        "value": "156"
    },
    {
        "label": "nationality.liberia",
        "value": "152"
    },
    {
        "label": "nationality.libya",
        "value": "153"
    },
    {
        "label": "nationality.liechtenstein",
        "value": "154"
    },
    {
        "label": "nationality.lithuania",
        "value": "158"
    },
    {
        "label": "nationality.luxembourg",
        "value": "155"
    },
    {
        "label": "nationality.north_macedonia",
        "value": "177"
    },
    {
        "label": "nationality.madagascar",
        "value": "160"
    },
    {
        "label": "nationality.malawi",
        "value": "169"
    },
    {
        "label": "nationality.malaysia",
        "value": "161"
    },
    {
        "label": "nationality.maldives",
        "value": "171"
    },
    {
        "label": "nationality.mali",
        "value": "162"
    },
    {
        "label": "nationality.malta",
        "value": "170"
    },
    {
        "label": "nationality.marshall",
        "value": "174"
    },
    {
        "label": "nationality.mauritania",
        "value": "163"
    },
    {
        "label": "nationality.mauritius",
        "value": "172"
    },
    {
        "label": "nationality.mexico",
        "value": "164"
    },
    {
        "label": "nationality.micronesia",
        "value": "175"
    },
    {
        "label": "nationality.moldova",
        "value": "176"
    },
    {
        "label": "nationality.monaco",
        "value": "165"
    },
    {
        "label": "nationality.mongolia",
        "value": "166"
    },
    {
        "label": "nationality.montenegro",
        "value": "605"
    },
    {
        "label": "nationality.morocco",
        "value": "167"
    },
    {
        "label": "nationality.mozambique",
        "value": "173"
    },
    {
        "label": "nationality.myanmar",
        "value": "014"
    },
    {
        "label": "nationality.namibia",
        "value": "188"
    },
    {
        "label": "nationality.nauru",
        "value": "187"
    },
    {
        "label": "nationality.nepal",
        "value": "180"
    },
    {
        "label": "nationality.netherlands",
        "value": "181"
    },
    {
        "label": "nationality.new_zealand",
        "value": "182"
    },
    {
        "label": "nationality.nicaragua",
        "value": "183"
    },
    {
        "label": "nationality.niger",
        "value": "184"
    },
    {
        "label": "nationality.nigeria",
        "value": "185"
    },
    {
        "label": "nationality.norway",
        "value": "186"
    },
    {
        "label": "nationality.oman",
        "value": "168"
    },
    {
        "label": "nationality.pakistan",
        "value": "200"
    },
    {
        "label": "nationality.palau",
        "value": "208"
    },
    {
        "label": "nationality.palestine",
        "value": "606"
    },
    {
        "label": "nationality.panama",
        "value": "201"
    },
    {
        "label": "nationality.papua_new_guinea",
        "value": "207"
    },
    {
        "label": "nationality.paraguay",
        "value": "202"
    },
    {
        "label": "nationality.peru",
        "value": "203"
    },
    {
        "label": "nationality.philippines",
        "value": "204"
    },
    {
        "label": "nationality.poland",
        "value": "205"
    },
    {
        "label": "nationality.portugal",
        "value": "206"
    },
    {
        "label": "nationality.qatar",
        "value": "210"
    },
    {
        "label": "nationality.romania",
        "value": "220"
    },
    {
        "label": "nationality.russia",
        "value": "222"
    },
    {
        "label": "nationality.rwanda",
        "value": "221"
    },
    {
        "label": "nationality.saint_christopher_and_nevis",
        "value": "249"
    },
    {
        "label": "nationality.saint_lucia",
        "value": "247"
    },
    {
        "label": "nationality.saint_vincent",
        "value": "248"
    },
    {
        "label": "nationality.samoa",
        "value": "290"
    },
    {
        "label": "nationality.san_marino",
        "value": "230"
    },
    {
        "label": "nationality.sao_tome_and_principe",
        "value": "243"
    },
    {
        "label": "nationality.saudi_arabia",
        "value": "231"
    },
    {
        "label": "nationality.senegal",
        "value": "232"
    },
    {
        "label": "nationality.serbia",
        "value": "604"
    },
    {
        "label": "nationality.seychelles",
        "value": "245"
    },
    {
        "label": "nationality.sierra_leone",
        "value": "233"
    },
    {
        "label": "nationality.singapore",
        "value": "240"
    },
    {
        "label": "nationality.slovakia",
        "value": "601"
    },
    {
        "label": "nationality.slovenia",
        "value": "600"
    },
    {
        "label": "nationality.solomon",
        "value": "246"
    },
    {
        "label": "nationality.somalia",
        "value": "234"
    },
    {
        "label": "nationality.republic_of_south_africa",
        "value": "261"
    },
    {
        "label": "nationality.south_sudan",
        "value": "608"
    },
    {
        "label": "nationality.spain",
        "value": "235"
    },
    {
        "label": "nationality.sri_lanka",
        "value": "035"
    },
    {
        "label": "nationality.sudan",
        "value": "236"
    },
    {
        "label": "nationality.suriname",
        "value": "244"
    },
    {
        "label": "nationality.eswatini",
        "value": "241"
    },
    {
        "label": "nationality.sweden",
        "value": "237"
    },
    {
        "label": "nationality.switzerland",
        "value": "238"
    },
    {
        "label": "nationality.syria",
        "value": "239"
    },
    {
        "label": "nationality.taiwan",
        "value": "042"
    },
    {
        "label": "nationality.tajikistan",
        "value": "259"
    },
    {
        "label": "nationality.tanzania",
        "value": "251"
    },
    {
        "label": "nationality.thailand",
        "value": "250"
    },
    {
        "label": "nationality.timor-leste",
        "value": "076"
    },
    {
        "label": "nationality.togo",
        "value": "252"
    },
    {
        "label": "nationality.tonga",
        "value": "256"
    },
    {
        "label": "nationality.trinidad_and_tobago",
        "value": "253"
    },
    {
        "label": "nationality.tunisia",
        "value": "254"
    },
    {
        "label": "nationality.turkey",
        "value": "255"
    },
    {
        "label": "nationality.turkmenistan",
        "value": "258"
    },
    {
        "label": "nationality.tuvalu",
        "value": "257"
    },
    {
        "label": "nationality.uganda",
        "value": "260"
    },
    {
        "label": "nationality.ukraine",
        "value": "269"
    },
    {
        "label": "nationality.united_arab_emirates",
        "value": "009"
    },
    {
        "label": "nationality.united_kingdom",
        "value": "264"
    },
    {
        "label": "nationality.united_states_of_america",
        "value": "265"
    },
    {
        "label": "nationality.uruguay",
        "value": "267"
    },
    {
        "label": "nationality.uzbekistan",
        "value": "270"
    },
    {
        "label": "nationality.vanuatu",
        "value": "284"
    },
    {
        "label": "nationality.vatican",
        "value": "280"
    },
    {
        "label": "nationality.venezuela",
        "value": "281"
    },
    {
        "label": "nationality.viet_nam",
        "value": "283"
    },
    {
        "label": "nationality.yemen",
        "value": "300"
    },
    {
        "label": "nationality.zambia",
        "value": "310"
    },
    {
        "label": "nationality.zimbabwe",
        "value": "311"
    },
    {
        "label": "nationality.korea",
        "value": "139"
    },
    {
        "label": "nationality.statelessness",
        "value": "401"
    }
];

//Exclude Japan from the list of nationalities because it shouldn't be an option for foreigners
export const FOREIGNER_NATIONALITY_OPTIONS: SelectOption[] = ALL_NATIONALITY_OPTIONS.filter(v => v.label !== 'nationality.japan');