import { SelectOption } from "../../types/option";
import { CurrentStatusOfResidenceCode_CB19, NewStatusOfResidenceCode_CB19 } from "../../types/visa/currentStatusOfResidence";

const getExtTKey = (key: string) => `currentStatusOfResidence.extension.${key}`;
const getChgTKey = (key: string) => `currentStatusOfResidence.change.${key}`;

export const CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_EXTENSION_OPTIONS: SelectOption[] = [
    {
        "label": getExtTKey("dependent"),
        "value": CurrentStatusOfResidenceCode_CB19.Dependent
    },
    {
        "label": getExtTKey("technical"),
        "value": CurrentStatusOfResidenceCode_CB19.EngineerOrSpecialistInHumanitiesOrInternationalServices
    },
    {
        "label": getExtTKey("spouseOrChildOfPermanentResident"),
        "value": CurrentStatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident
    },
    {
        "label": getExtTKey("longTermResident"),
        "value": CurrentStatusOfResidenceCode_CB19.LongTermResident
    }
];

export const CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_CHANGE_OPTIONS: SelectOption[] = [
    {
        "label": getChgTKey("diplomat"),
        "value": NewStatusOfResidenceCode_CB19.Diplomat
    },
    {
        "label": getChgTKey("official"),
        "value": NewStatusOfResidenceCode_CB19.Official
    },
    {
        "label": getChgTKey("professor"),
        "value": NewStatusOfResidenceCode_CB19.Professor
    },
    {
        "label": getChgTKey("artist"),
        "value": NewStatusOfResidenceCode_CB19.Artist
    },
    {
        "label": getChgTKey("religiousActivities"),
        "value": NewStatusOfResidenceCode_CB19.ReligiousActivities
    },
    {
        "label": getChgTKey("journalist"),
        "value": NewStatusOfResidenceCode_CB19.Journalist
    },
    {
        "label": getChgTKey("legalAccountingServices"),
        "value": NewStatusOfResidenceCode_CB19.LegalAccountingServices
    },
    {
        "label": getChgTKey("medicalServices"),
        "value": NewStatusOfResidenceCode_CB19.MedicalServices
    },
    {
        "label": getChgTKey("researcher"),
        "value": NewStatusOfResidenceCode_CB19.Researcher
    },
    {
        "label": getChgTKey("instructor"),
        "value": NewStatusOfResidenceCode_CB19.Instructor
    },
    {
        "label": getChgTKey("intraCompanyTransferee"),
        "value": NewStatusOfResidenceCode_CB19.IntraCompanyTransferee
    },
    {
        "label": getChgTKey("entertainer"),
        "value": NewStatusOfResidenceCode_CB19.Entertainer
    },
    {
        "label": getChgTKey("skilledLabor"),
        "value": NewStatusOfResidenceCode_CB19.SkilledLabor
    },
    {
        "label": getChgTKey("technicalInternTraining_i_a"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_i_a
    },
    {
        "label": getChgTKey("technicalInternTraining_i_b"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_i_b
    },
    {
        "label": getChgTKey("technicalInternTraining_ii_a"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_ii_a
    },
    {
        "label": getChgTKey("technicalInternTraining_ii_b"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_ii_b
    },
    {
        "label": getChgTKey("businessManager"),
        "value": NewStatusOfResidenceCode_CB19.BusinessManager
    },
    {
        "label": getChgTKey("engineerSpecialistInHumanitiesInternationalServices"),
        "value": NewStatusOfResidenceCode_CB19.EngineerSpecialistInHumanitiesInternationalServices
    },
    {
        "label": getChgTKey("technicalInternTraining_iii_a"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_iii_a
    },
    {
        "label": getChgTKey("technicalInternTraining_iii_b"),
        "value": NewStatusOfResidenceCode_CB19.TechnicalInternTraining_iii_b
    },
    {
        "label": getChgTKey("nursingCare"),
        "value": NewStatusOfResidenceCode_CB19.NursingCare
    },
    {
        "label": getChgTKey("culturalActivities"),
        "value": NewStatusOfResidenceCode_CB19.CulturalActivities
    },
    {
        "label": getChgTKey("temporaryVisitor"),
        "value": NewStatusOfResidenceCode_CB19.TemporaryVisitor
    },
    {
        "label": getChgTKey("student"),
        "value": NewStatusOfResidenceCode_CB19.Student
    },
    {
        "label": getChgTKey("trainee"),
        "value": NewStatusOfResidenceCode_CB19.Trainee
    },
    {
        "label": getChgTKey("dependent"),
        "value": NewStatusOfResidenceCode_CB19.Dependent
    },
    {
        "label": getChgTKey("designatedActivities"),
        "value": NewStatusOfResidenceCode_CB19.DesignatedActivities
    },
    {
        "label": getChgTKey("specialPermanentResidents"),
        "value": NewStatusOfResidenceCode_CB19.SpecialPermanentResidents
    },
    {
        "label": getChgTKey("spouseOrChildOfJapaneseNational"),
        "value": NewStatusOfResidenceCode_CB19.SpouseOrChildOfJapaneseNational
    },
    {
        "label": getChgTKey("spouseOrChildOfPermanentResident"),
        "value": NewStatusOfResidenceCode_CB19.SpouseOrChildOfPermanentResident
    },
    {
        "label": getChgTKey("longTermResident"),
        "value": NewStatusOfResidenceCode_CB19.LongTermResident
    },
    {
        "label": getChgTKey("specifiedSkilledWorker_i"),
        "value": NewStatusOfResidenceCode_CB19.SpecifiedSkilledWorker_i
    },
    {
        "label": getChgTKey("specifiedSkilledWorker_ii"),
        "value": NewStatusOfResidenceCode_CB19.SpecifiedSkilledWorker_ii
    },
    {
        "label": getChgTKey("highlySkilledProfessional_i_a"),
        "value": NewStatusOfResidenceCode_CB19.HighlySkilledProfessional_i_a
    },
    {
        "label": getChgTKey("highlySkilledProfessional_i_b"),
        "value": NewStatusOfResidenceCode_CB19.HighlySkilledProfessional_i_b
    },
    {
        "label": getChgTKey("highlySkilledProfessional_i_c"),
        "value": NewStatusOfResidenceCode_CB19.HighlySkilledProfessional_i_c
    },
    {
        "label": getChgTKey("highlySkilledProfessional_ii"),
        "value": NewStatusOfResidenceCode_CB19.HighlySkilledProfessional_ii
    },
    {
        "label": getChgTKey("permanentResident"),
        "value": NewStatusOfResidenceCode_CB19.PermanentResident
    },
];