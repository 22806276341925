import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Section from "../../../../../components/layout/Section";
import UploadField from "../../../../../components/compound/UploadField";
import { AttachmentBodyProps } from "../../../../../types/visa/attachmentProp";
import { VisaAttachmentKind } from "../../../../../types/visa/attachment";
import InlineMessage from "../../../../../components/form/InlineMessage";
import RadioField from "../../../../../components/compound/RadioField";
import styled from "styled-components";
import { FirstRenewalApplication, OrganizationType, SupplementaryInfo } from "../../../../../types/uncommonFormParts/supplementaryInfo";
import { extractVisaAttachments } from "../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { NoteContainer, NoteParagraph, OrderedNoteNumList, UnorderedNoteDiscList } from "../commonStyles";

interface StudentVisaAttachmentsProps extends AttachmentBodyProps {
  supplementaryInfo: SupplementaryInfo;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  showRequiredAlert: boolean;
}


const StudentVisaAttachments: FunctionComponent<StudentVisaAttachmentsProps> = ({
  visaAttachments,
  supplementaryInfo,
  onChangeSupplementaryInfo,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onVisaAttachmentsDelete,
  onSectionReadinessChange,
  showRequiredAlert
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "attachmentsSection" });
  const { t : tWithoutPrefix } = useTranslation();

  const [organizationType, setOrganizationType] = useState<OrganizationType>();
  const [firstRenewalApp, setFirstRenewalApp] = useState<FirstRenewalApplication>();
  const getEmptyError = (val: any) => (showRequiredAlert && !!!val)? tWithoutPrefix("inputAlert.field"): '';
  const saveSupplementaryInfo = (data: Partial<SupplementaryInfo>) => onChangeSupplementaryInfo(data);

  const deleteAttachments = (kinds: VisaAttachmentKind[]) => {
    let attachmentIds: number [] = [];
    for (const kind of kinds) {
      const ids = extractVisaAttachments(visaAttachments, kind).map(a => a.id);
      attachmentIds = [...attachmentIds, ...ids]
    }
    onVisaAttachmentsDelete?.(attachmentIds);
  }

  const resetOrganizationRelatedAttachments = () => {
    const optionRelatedKinds = [
      VisaAttachmentKind.DocsProvingCategory,
      VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
      VisaAttachmentKind.RegistrationCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
      VisaAttachmentKind.FinancialStatements,
      VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheReason,
    ];

    deleteAttachments(optionRelatedKinds);
  }

  const resetFirstRenewalAppRelatedAttachments = () => {
    const conditionalKinds = 
      organizationType === OrganizationType.OptionD
        ? [VisaAttachmentKind.MaterialsThatClarifyTheReason]
        : [];

    const renewalAppRelatedKinds = [
      VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
      VisaAttachmentKind.RegistrationCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
      VisaAttachmentKind.FinancialStatements,
      ...conditionalKinds
    ];

    deleteAttachments(renewalAppRelatedKinds);
  }

  useEffect(() => {
    let conditionallyRequiredKinds: VisaAttachmentKind[] = [];
    let conditionallyRequiredFields: any[] = [];

    switch (organizationType) {
      case OrganizationType.OptionA:
      case OrganizationType.OptionB:
        break;
      case OrganizationType.OptionC:
        if (firstRenewalApp === FirstRenewalApplication.Yes) {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
            VisaAttachmentKind.RegistrationCertificate,
            VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
            VisaAttachmentKind.FinancialStatements,
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        } else {
          conditionallyRequiredKinds.push(
              VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        }
        
        conditionallyRequiredFields = [
          firstRenewalApp
        ];
        break;
      case OrganizationType.OptionD:
        if (firstRenewalApp === FirstRenewalApplication.Yes) {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
            VisaAttachmentKind.RegistrationCertificate,
            VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
            VisaAttachmentKind.FinancialStatements,
            VisaAttachmentKind.MaterialsThatClarifyTheReason,
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate,
          );
        } else {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        }

        conditionallyRequiredFields = [
          firstRenewalApp
        ];
        break;
    }

    //Todo: This depends on some option values in the form. Need to update later
    const requiredKinds = [
      VisaAttachmentKind.Passport,
      VisaAttachmentKind.ResidenceCard,
      VisaAttachmentKind.Photo,
      VisaAttachmentKind.DocsProvingCategory,
      ...conditionallyRequiredKinds
    ];

    const requiredFields = [
      organizationType,
      ...conditionallyRequiredFields
    ];

    const givenKinds = new Set(visaAttachments.map((va) => va.kind));
    const areAllKindsGiven = requiredKinds.every((rk) => givenKinds.has(rk));
    const areAllFieldsGiven = requiredFields.every((rf) => !!rf);
    onSectionReadinessChange(areAllKindsGiven && areAllFieldsGiven);
  }, [
    visaAttachments,
    organizationType,
    firstRenewalApp
  ]);

  
  useEffect(() => {
    if (!supplementaryInfo)
      return;

    setFirstRenewalApp(supplementaryInfo["first_renewal_application"] as FirstRenewalApplication ?? '');
    setOrganizationType(supplementaryInfo["appropriate_organization_description"] as OrganizationType ?? '');
    
  }, [
    supplementaryInfo
  ]);

  return (
    <Section>
      <InlineMessage variant="caution">{t("under5MB")}</InlineMessage>

      {/* Passport / パスポート */}
      <UploadField
        required
        label={t("passport.label")}
        note={t("passport.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Passport}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Residence Card / 在留カード */}
      <UploadField
        required
        label={t("residenceCard.label")}
        note={t("residenceCard.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.ResidenceCard}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Are you 16 years old or older */}
      <RadioField
        required
        label={t("areYou16YearsOldOrOlder.label")}
        options={[
          {
            label: tWithoutPrefix("common.yes"),
            value: true
          },
          {
            label: tWithoutPrefix("common.no"),
            value: false
          }
        ]}
        value={true}
        error={getEmptyError(true)}
        onValueChange={val => {

        }}
      />

      {/* Photo / 顔写真 */}
      <UploadField
        required
        label={t("photo.label")}
        note={
          <NoteContainer>
            <div>{t("photo.notes.description")}</div>
            <OrderedNoteNumList>
              <li>{t("photo.notes.req1")}</li>
              <li>{t("photo.notes.req2")}</li>
              <li>{t("photo.notes.req3")}</li>
              <li>{t("photo.notes.req4")}</li>
              <li>{t("photo.notes.req5")}</li>
              <li>{t("photo.notes.req6")}</li>
            </OrderedNoteNumList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Photo}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Academic transcript (Previous school) */}
      <UploadField
        label={t("academicTranscriptPrevSchool.label")}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t("academicTranscriptPrevSchool.notes.instruction")}
            </NoteParagraph>
            <br/> 
            <UnorderedNoteDiscList>
              <li>{t("academicTranscriptPrevSchool.notes.item1")}</li>
            </UnorderedNoteDiscList>
            <br/>
            <NoteParagraph>
              {t("academicTranscriptPrevSchool.notes.ifAvailableAlsoAttach")}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t("academicTranscriptPrevSchool.notes.item2")}</li>
              <li>{t("academicTranscriptPrevSchool.notes.item3")}</li>
            </UnorderedNoteDiscList> 
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Transcript}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Declaration form regarding payment of living expenses */}
      <UploadField
        label={t('declarationFormRegardingPaymentOfLivingExp.label')}
        note={t('declarationFormRegardingPaymentOfLivingExp.note')}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.DeclarationConcerningPaymentOfLivingExpenses}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents to prove the amount of income and assets in the home country */}
      <UploadField
        label={t('docsToProveAmountOfIncomeAndAssetsInHomeCountry.label')}
        note={t('docsToProveAmountOfIncomeAndAssetsInHomeCountry.note')}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.DocsProvingAmountOfIncomeAssetsInHomeCountry}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required if the individual will be covering their own living expenses */}
      <UploadField
        label={t('docsReqIfIndivualWillBeCoveringLivingExpenses.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.description1')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>
                {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.item1')}
                <UnorderedNoteDiscList>
                  <li>
                    {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.noteOnItem1')}
                  </li>
                </UnorderedNoteDiscList>
              </li>
            </UnorderedNoteDiscList>
            <NoteParagraph>
              {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.description2')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>
                {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.item2')}
                <UnorderedNoteDiscList>
                  <li>
                    {t('docsReqIfIndivualWillBeCoveringLivingExpenses.notes.noteOnItem2')}
                  </li>
                </UnorderedNoteDiscList>
              </li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required if someone else will be covering the living expenses. */}
      <UploadField
        label={t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.item1')}</li>
            </UnorderedNoteDiscList>
            <NoteParagraph>
              {t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.description2')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>
                {t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.item2')}
                <UnorderedNoteDiscList>
                  <li>{t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.item3')}</li>
                  <li>{t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.item4')}</li>
                  <li>{t('docsReqIfSomeoneElseWillBeCoveringLivingExpenses.notes.item5')}</li>
                </UnorderedNoteDiscList>
              </li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required if living expenses are being paid through remittances from overseas */}
      <UploadField
        label={t('docsReqIfLivingExpensesPaidThruRemittancesFromOverseas.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqIfLivingExpensesPaidThruRemittancesFromOverseas.notes.item')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t('docsReqIfLivingExpensesPaidThruRemittancesFromOverseas.notes.description')}</li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required if living expenses are being paid through funds brought in from overseas */}
      <UploadField
        label={t('docsReqIfLivingExpensesPaidThruFundsFromOverseas.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqIfLivingExpensesPaidThruFundsFromOverseas.notes.item')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t('docsReqIfLivingExpensesPaidThruFundsFromOverseas.notes.description1')}</li>
              <li>{t('docsReqIfLivingExpensesPaidThruFundsFromOverseas.notes.description2')}</li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />


      {/* Documents required if living expenses are being paid through scholarships */}
      <UploadField  
        label={t('docsReqIfLivingExpensesPaidThruScholarships.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqIfLivingExpensesPaidThruScholarships.notes.item')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>
                {t('docsReqIfLivingExpensesPaidThruScholarships.notes.description1')}
                <UnorderedNoteDiscList>
                  <li>{t('docsReqIfLivingExpensesPaidThruScholarships.notes.description1a')}</li>
                </UnorderedNoteDiscList>
              </li>
            </UnorderedNoteDiscList>
            <UnorderedNoteDiscList>
              <li>{t('docsReqIfLivingExpensesPaidThruScholarships.notes.description2')}</li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* List of Submitted Documents */}
      <UploadField
        required
        label={t("listOfSubmittedDocs.label")}
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Certificate of Enrollment */}
      <UploadField
        required
        label={t("certificateOfEnrollment.label")}
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Academic transcript (Current school) */}
       <UploadField 
        label={t('academicTranscriptCurrentSchool.label')}
        note={t('academicTranscriptCurrentSchool.notes')}
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required for graduate school/university enrollment, not solely for research or auditing */}
      <UploadField
        label={t('docsReqForGradUnivEnrollmentNotSolelyForResearchOrAuditing.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqForGradUnivEnrollmentNotSolelyForResearchOrAuditing.notes.item')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t('docsReqForGradUnivEnrollmentNotSolelyForResearchOrAuditing.notes.description')}</li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required for being an auditor or taking specific courses at a university or junior college */}
      <UploadField
        label={t('docsReqForBeingAuditorOrTakingSpecificCourse.label')}
        note={
          <NoteContainer>
            <NoteParagraph>
              {t('docsReqForBeingAuditorOrTakingSpecificCourse.notes.item')}
            </NoteParagraph>
            <UnorderedNoteDiscList>
              <li>{t('docsReqForBeingAuditorOrTakingSpecificCourse.notes.description')}</li>
            </UnorderedNoteDiscList>
          </NoteContainer>
        }
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />

      {/* Documents required for exclusively attending evening classes at the graduate school */}
      <UploadField
        label={t('docsReqForAttendingEveningClassesAtGradSchool.label')}
        note={t('docsReqForAttendingEveningClassesAtGradSchool.note')}
        visaAttachments={visaAttachments}
        // visaAttachmentKind={VisaAttachmentKind.}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />
  </Section>
  );  
}

export default StudentVisaAttachments;