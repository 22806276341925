import { StatusOfResidenceOfUser } from "../types/user/data";
import { StatusOfResidenceOfUserTransKey } from "../types/user/transKey";

//Convert the current status of residence value (defined by backend) to
//the corresponding translation key. The latter freely can be defined at frontend
//NOTE: This status of residence value is only used internaly; in other words, not sent to Myna/Immg.
//Don't confuse this with other status of residence codes (e.g. "T44"), which are sent to Myna/Immg.
export const convertStatusOfResidenceToTransKey = (statusOfResidenceCode: StatusOfResidenceOfUser): string => {
  switch (statusOfResidenceCode) {
    case StatusOfResidenceOfUser.Dependent:
      return StatusOfResidenceOfUserTransKey.Dependent;
    case StatusOfResidenceOfUser.Gijinkoku:
      return StatusOfResidenceOfUserTransKey.Gijinkoku;
    case StatusOfResidenceOfUser.StudyAbroad:
      return StatusOfResidenceOfUserTransKey.StudyAbroad;
    default: 
      return "";
  }
}