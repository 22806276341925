import { SelectOption } from "../../types/option";

export const PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT: SelectOption[] = [
  {
      "label": "periodOfStayForDependent.0001000",
      "value": "0001000"
  },
  {
      "label": "periodOfStayForDependent.0002000",
      "value": "0002000"
  },
  {
      "label": "periodOfStayForDependent.0003000",
      "value": "0003000"
  },
  {
      "label": "periodOfStayForDependent.0004000",
      "value": "0004000"
  },
  {
      "label": "periodOfStayForDependent.0005000",
      "value": "0005000"
  },
  {
      "label": "periodOfStayForDependent.0006000",
      "value": "0006000"
  },
  {
      "label": "periodOfStayForDependent.0007000",
      "value": "0007000"
  },
  {
      "label": "periodOfStayForDependent.0008000",
      "value": "0008000"
  },
  {
      "label": "periodOfStayForDependent.0009000",
      "value": "0009000"
  },
  {
      "label": "periodOfStayForDependent.0010000",
      "value": "0010000"
  },
  {
      "label": "periodOfStayForDependent.0011000",
      "value": "0011000"
  },
  {
      "label": "periodOfStayForDependent.0100000",
      "value": "0100000"
  },
  {
      "label": "periodOfStayForDependent.0101000",
      "value": "0101000"
  },
  {
      "label": "periodOfStayForDependent.0102000",
      "value": "0102000"
  },
  {
      "label": "periodOfStayForDependent.0103000",
      "value": "0103000"
  },
  {
      "label": "periodOfStayForDependent.0104000",
      "value": "0104000"
  },
  {
      "label": "periodOfStayForDependent.0105000",
      "value": "0105000"
  },
  {
      "label": "periodOfStayForDependent.0106000",
      "value": "0106000"
  },
  {
      "label": "periodOfStayForDependent.0107000",
      "value": "0107000"
  },
  {
      "label": "periodOfStayForDependent.0108000",
      "value": "0108000"
  },
  {
      "label": "periodOfStayForDependent.0109000",
      "value": "0109000"
  },
  {
      "label": "periodOfStayForDependent.0110000",
      "value": "0110000"
  },
  {
      "label": "periodOfStayForDependent.0111000",
      "value": "0111000"
  },
  {
      "label": "periodOfStayForDependent.0200000",
      "value": "0200000"
  },
  {
      "label": "periodOfStayForDependent.0201000",
      "value": "0201000"
  },
  {
      "label": "periodOfStayForDependent.0202000",
      "value": "0202000"
  },
  {
      "label": "periodOfStayForDependent.0203000",
      "value": "0203000"
  },
  {
      "label": "periodOfStayForDependent.0204000",
      "value": "0204000"
  },
  {
      "label": "periodOfStayForDependent.0205000",
      "value": "0205000"
  },
  {
      "label": "periodOfStayForDependent.0206000",
      "value": "0206000"
  },
  {
      "label": "periodOfStayForDependent.0207000",
      "value": "0207000"
  },
  {
      "label": "periodOfStayForDependent.0208000",
      "value": "0208000"
  },
  {
      "label": "periodOfStayForDependent.0209000",
      "value": "0209000"
  },
  {
      "label": "periodOfStayForDependent.0210000",
      "value": "0210000"
  },
  {
      "label": "periodOfStayForDependent.0211000",
      "value": "0211000"
  },
  {
      "label": "periodOfStayForDependent.0300000",
      "value": "0300000"
  },
  {
      "label": "periodOfStayForDependent.0301000",
      "value": "0301000"
  },
  {
      "label": "periodOfStayForDependent.0302000",
      "value": "0302000"
  },
  {
      "label": "periodOfStayForDependent.0303000",
      "value": "0303000"
  },
  {
      "label": "periodOfStayForDependent.0304000",
      "value": "0304000"
  },
  {
      "label": "periodOfStayForDependent.0305000",
      "value": "0305000"
  },
  {
      "label": "periodOfStayForDependent.0306000",
      "value": "0306000"
  },
  {
      "label": "periodOfStayForDependent.0307000",
      "value": "0307000"
  },
  {
      "label": "periodOfStayForDependent.0308000",
      "value": "0308000"
  },
  {
      "label": "periodOfStayForDependent.0309000",
      "value": "0309000"
  },
  {
      "label": "periodOfStayForDependent.0310000",
      "value": "0310000"
  },
  {
      "label": "periodOfStayForDependent.0311000",
      "value": "0311000"
  },
  {
      "label": "periodOfStayForDependent.0400000",
      "value": "0400000"
  },
  {
      "label": "periodOfStayForDependent.0401000",
      "value": "0401000"
  },
  {
      "label": "periodOfStayForDependent.0402000",
      "value": "0402000"
  },
  {
      "label": "periodOfStayForDependent.0403000",
      "value": "0403000"
  },
  {
      "label": "periodOfStayForDependent.0404000",
      "value": "0404000"
  },
  {
      "label": "periodOfStayForDependent.0405000",
      "value": "0405000"
  },
  {
      "label": "periodOfStayForDependent.0406000",
      "value": "0406000"
  },
  {
      "label": "periodOfStayForDependent.0407000",
      "value": "0407000"
  },
  {
      "label": "periodOfStayForDependent.0408000",
      "value": "0408000"
  },
  {
      "label": "periodOfStayForDependent.0409000",
      "value": "0409000"
  },
  {
      "label": "periodOfStayForDependent.0410000",
      "value": "0410000"
  },
  {
      "label": "periodOfStayForDependent.0411000",
      "value": "0411000"
  },
  {
      "label": "periodOfStayForDependent.0500000",
      "value": "0500000"
  }
];


export const PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
    {
        "label": "periodOfStayForGijinkoku.0003000",
        "value": "0003000"
    },
    {
        "label": "periodOfStayForGijinkoku.0100000",
        "value": "0100000"
    },
    {
        "label": "periodOfStayForGijinkoku.0300000",
        "value": "0300000"
    },
    {
        "label": "periodOfStayForGijinkoku.0500000",
        "value": "0500000"
    }
];
