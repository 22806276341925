
import styled from "styled-components";


export const NoteContainer = styled.div`
  display: flex;
  flex-direction: column;;
  gap: 5px;
`;

export const NoteParagraph = styled.p`
  margin: 0;
`;

export const UnorderedNoteDiscList = styled.ul`
  padding-left: 18px;
  margin: 0;
  list-style-type: disc;
`;

export const OrderedNoteNumList = styled.ol`
  padding-left: 18px;
  margin: 0;
`;

export const HyphenList = styled.li`
    list-style: none;
    padding-left: 0;
  
    &::before {
      content: "-";
      margin-right: 5px;
    }
`;