import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FieldGroup from "../../../components/layout/FieldGroup";
import MultiTextField from "../../../components/compound/MultiTextField";
import RadioField from "../../../components/compound/RadioField";
import TextField from "../../../components/compound/TextField";
import Section from "../../../components/layout/Section";
import { useValidation } from "../../../hooks";
import { VisaApplicationType } from "../../../types/visa/applicationType";
import styled from "styled-components";
import { FormP } from "../../../types/uncommonFormParts/formP/data";
import { formatDateStrWithJaUnits, parseDateWithJaUnits } from "../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import DateSelectField from "../../../components/compound/DateSelectField";
import { JapaneseLanguageAbilityProof } from "../../../types/uncommonFormParts/formP/value";

interface JaLangAbilityAndEduHistorySectionProps {
  visaApplicationType: VisaApplicationType;
  formP: FormP;
  onChangeFormP: (data: Partial<FormP>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
}

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #ffffff;
  border: solid 1px #d5d5d5;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const JaLangAbilityAndEduHistorySection: FunctionComponent<
  JaLangAbilityAndEduHistorySectionProps
> = ({
  visaApplicationType,
  formP,
  onChangeFormP,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "jaLangAbilityAndEduHistorySection" });
  const validation = useValidation();
  const [proofOfJaLangAbility, setProofOfJaLangAbility] = useState<string>("");
  const [nameOfJaLangTest, setNameOfJaLangTest] = useState<string>("");
  const [jaLangTestLevelOrScore, setJaLangTestLevelOrScore] = useState<string>("");
  const [jaLangAbilityOrgName, setJaLangAbilityOrgName] = useState<string>("");
  const [jaLangAbilityOrgStartDate, setJaLangAbilityOrgStartDate] = useState<string>("");
  const [jaLangAbilityOrgEndDate, setJaLangAbilityOrgEndDate] = useState<string>("");
  const [jaLangAbilityOrgOther, setJaLangAbilityOrgOther] = useState<string>("");
  const [jaEduHistoryOrgName, setJaEduHistoryOrgName] = useState<string>("");
  const [jaEducationHistoryStartDate, setJaEducationHistoryStartDate] = useState<string>("");
  const [jaEducationHistoryEndDate, setJaEducationHistoryEndDate] = useState<string>("");

  const [nameOfJaLangTestError, setNameOfJaLangTestError] = useState<string>("");
  const [jaLangTestLevelOrScoreError, setJaLangTestLevelOrScoreError] = useState<string>("");
  const [jaLangAbilityOrgNameError, setJaLangAbilityOrgNameError] = useState<string>("");
  const [jaLangAbilityOrgOtherError, setJaLangAbilityOrgOtherError] = useState<string>("");
  const [jaEduHistoryOrgNameError, setJaEduHistoryOrgNameError] = useState<string>("");

  //Currently, the item names for this section are common between visa extension and change,
  //so using the same function.
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  };

  useEffect(() => {

    if (!formP) return;
    
    setProofOfJaLangAbility(formP.japanese_language_ability ?? "");
    setNameOfJaLangTest(formP.japanese_language_ability_exam_name ?? "");
    setJaLangTestLevelOrScore(
      formP.japanese_language_ability_exam_attained_level_or_score ?? ""
    );
    
    const lngAbilityPeriodStartDate = parseDateWithJaUnits(
      formP.japanese_language_ability_organization_period_from
    );
    const lngAbilityPeriodEndDate = parseDateWithJaUnits(
      formP.japanese_language_ability_organization_period_to
    );
    setJaLangAbilityOrgStartDate(lngAbilityPeriodStartDate.yearMonth ?? "");
    setJaLangAbilityOrgEndDate(lngAbilityPeriodEndDate.yearMonth ?? "");

    setJaLangAbilityOrgOther(formP.japanese_language_ability_other ?? "");
    setJaEduHistoryOrgName(formP.japanese_education_history_organization ?? "");
    
    const eduHistoryPeriodStartDate = parseDateWithJaUnits(
      formP.japanese_education_history_period_from
    );
    const eduHistoryPeriodEndDate = parseDateWithJaUnits(
      formP.japanese_education_history_period_to
    );
    setJaEducationHistoryStartDate(eduHistoryPeriodStartDate.yearMonth ?? "");
    setJaEducationHistoryEndDate(eduHistoryPeriodEndDate.yearMonth ?? "");
  }, [formP]);

  useEffect(() => {
    const areThereNoErrors = [
      nameOfJaLangTestError,
      jaLangTestLevelOrScoreError,
      jaLangAbilityOrgNameError,
      jaLangAbilityOrgOtherError,
      jaEduHistoryOrgNameError,
    ].every((val) => !val);

    onSectionReadinessChange(areThereNoErrors);
  }, [
    nameOfJaLangTestError,
    jaLangTestLevelOrScoreError,
    jaLangAbilityOrgNameError,
    jaLangAbilityOrgOtherError,
    jaEduHistoryOrgNameError,
  ]);

  return (
    <Section>
      {/* Japanese language ability */}
      <LocalFieldGroup
        title={t("japaneseLanguageAbility")}
        note={t("noteOnJaLangAbility")}
        theme="light"
      >
        {/* How do you prove your Japanese language ability */}
        <RadioField
          label={t("howToProveJaLangAbility")}
          options={[
            {
              label: t("proofBasedOnJaLangTest"),
              value: JapaneseLanguageAbilityProof.Test,
            },
            {
              label: t("orgAndReceivedJaLangEdu"),
              value: JapaneseLanguageAbilityProof.Education,
            },
            {
              label: t("others"),
              value: JapaneseLanguageAbilityProof.Other,
            },
          ]}
          value={proofOfJaLangAbility}
          onValueChange={(val) => {
            setProofOfJaLangAbility(val);

            switch (val) {
              case JapaneseLanguageAbilityProof.Test:
                saveData({
                  japanese_language_ability: JapaneseLanguageAbilityProof.Test,
                  japanese_language_ability_organization: "",
                  japanese_language_ability_organization_period_from: "",
                  japanese_language_ability_organization_period_to: "",
                  japanese_language_ability_other: "",
                });
                setJaLangAbilityOrgName("");
                setJaLangAbilityOrgStartDate("");
                setJaLangAbilityOrgEndDate("");
                setJaLangAbilityOrgOther("");

                setJaLangAbilityOrgNameError("");
                setJaLangAbilityOrgOtherError("");
                break;
              case JapaneseLanguageAbilityProof.Education:
                saveData({
                  japanese_language_ability: JapaneseLanguageAbilityProof.Education,
                  japanese_language_ability_exam_name: "",
                  japanese_language_ability_exam_attained_level_or_score: "",
                  japanese_language_ability_other: "",
                });
                setNameOfJaLangTest("");
                setJaLangTestLevelOrScore("");
                setJaLangAbilityOrgOther("");

                setNameOfJaLangTestError("");
                setJaLangTestLevelOrScoreError("");
                setJaLangAbilityOrgOtherError("");
                break;
              case JapaneseLanguageAbilityProof.Other:
                saveData({
                  japanese_language_ability: JapaneseLanguageAbilityProof.Other,
                  japanese_language_ability_exam_name: "",
                  japanese_language_ability_exam_attained_level_or_score: "",
                  japanese_language_ability_organization: "",
                  japanese_language_ability_organization_period_from: "",
                  japanese_language_ability_organization_period_to: "",
                });
                setNameOfJaLangTest("");
                setJaLangTestLevelOrScore("");
                setJaLangAbilityOrgName("");
                setJaLangAbilityOrgStartDate("");
                setJaLangAbilityOrgEndDate("");

                setNameOfJaLangTestError("");
                setJaLangTestLevelOrScoreError("");
                setJaLangAbilityOrgNameError("");
                break;
            }
          }}
        />

        {proofOfJaLangAbility === JapaneseLanguageAbilityProof.Test && (
          <Group>
            {/* Name of the Japanese language test you took */}
            <TextField
              label={t("nameOfJaLangTest")}
              placeholder="JLPT"
              value={nameOfJaLangTest}
              error={nameOfJaLangTestError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onValueChange={setNameOfJaLangTest}
              onErrorChange={setNameOfJaLangTestError}
              onBlur={() => {
                saveData({
                  japanese_language_ability_exam_name: nameOfJaLangTest,
                });
              }}
            />

            <TextField
              label={t("attainedLevelOrScore")}
              placeholder={"Attained level or score"}
              value={jaLangTestLevelOrScore}
              error={jaLangTestLevelOrScoreError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onValueChange={setJaLangTestLevelOrScore}
              onErrorChange={setJaLangTestLevelOrScoreError}
              onBlur={() => {
                saveData({
                  japanese_language_ability_exam_attained_level_or_score:
                    jaLangTestLevelOrScore,
                });
              }}
            />
          </Group>
        )}

        {proofOfJaLangAbility === JapaneseLanguageAbilityProof.Education && (
          <Group>
            {/* Name of organization */}
            <TextField
              label={t("orgNameOfOrganization")}
              placeholder="Visadas University"
              value={jaLangAbilityOrgName}
              error={jaLangAbilityOrgNameError}
              maxLength={172}
              validators={[validation.createLengthValidator(172)]}
              onValueChange={setJaLangAbilityOrgName}
              onErrorChange={setJaLangAbilityOrgNameError}
              onBlur={() => {
                saveData({
                  japanese_language_ability_organization: jaLangAbilityOrgName,
                });
              }}
            />

            {/* Start month and year */}
            <DateSelectField
              hideDayField
              label={t("orgStartMonthAndYear")}
              value={jaLangAbilityOrgStartDate}
              onValueChange={val => {
                setJaLangAbilityOrgStartDate(val);
                saveData({
                  japanese_language_ability_organization_period_from: formatDateStrWithJaUnits(val)
                });
              }}
            />

            {/** End month and year */}
            <DateSelectField
              hideDayField
              label={t("orgEndMonthAndYear")}
              value={jaLangAbilityOrgEndDate}
              onValueChange={val => {
                setJaLangAbilityOrgEndDate(val);
                saveData({
                  japanese_language_ability_organization_period_to: formatDateStrWithJaUnits(val)
                });
              }}
            />
          </Group>
        )}

        {proofOfJaLangAbility === JapaneseLanguageAbilityProof.Other && (
          /* Other */
          <MultiTextField
            label={t("other")}
            value={jaLangAbilityOrgOther}
            error={jaLangAbilityOrgOtherError}
            maxLength={172}
            validators={[validation.createLengthValidator(172)]}
            onValueChange={setJaLangAbilityOrgOther}
            onErrorChange={setJaLangAbilityOrgOtherError}
            onBlur={() => {
              saveData({
                japanese_language_ability_other: jaLangAbilityOrgOther,
              });
            }}
          />
        )}
      </LocalFieldGroup>

      {/* Japanese Education History */}
      <LocalFieldGroup
        title={t("japaneseEduHistory")}
        note={
          <>
            {t("noteOnJaEduHistory1")}<br />
            <br />
            {t("noteOnJaEduHistory2")}
          </>
        }
        theme="light"
      >
        {/* Name of organization */}
        <TextField
          label={t("orgNameOfOrganization")}
          placeholder="Visadas University"
          value={jaEduHistoryOrgName}
          error={jaEduHistoryOrgNameError}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setJaEduHistoryOrgName}
          onErrorChange={setJaEduHistoryOrgNameError}
          onBlur={() => {
            saveData({
              japanese_education_history_organization: jaEduHistoryOrgName,
            });
          }}
        />

        {/* Start month and year */}
        <DateSelectField
          hideDayField
          label={t("eduStartMonthAndYear")}
          value={jaEducationHistoryStartDate}
          onValueChange={val => {
            setJaEducationHistoryStartDate(val);
            saveData({
              japanese_education_history_period_from: formatDateStrWithJaUnits(val)
            });
          }}
        />

        {/* End month and year */}
        <DateSelectField
          hideDayField
          label={t("eduEndMonthAndYear")}
          value={jaEducationHistoryEndDate}
          onValueChange={val => {
            setJaEducationHistoryEndDate(val);
            saveData({
              japanese_education_history_period_to: formatDateStrWithJaUnits(val)
            });
          }}
        />
      </LocalFieldGroup>
    </Section>
  );
};

export default JaLangAbilityAndEduHistorySection;
