import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { ReactComponent as PlusIcon } from "../../../assets/icon-plus.svg";
import DateSelectField from "../../../components/compound/DateSelectField";
import TextField from "../../../components/compound/TextField";
import FieldGroupNumber from "../../../components/display/FieldGroupNumber";
import Button from "../../../components/form/Button";
import Text from "../../../components/text/Text";
import FieldGroup from "../../../components/layout/FieldGroup";
import Section from "../../../components/layout/Section";
import { useValidation } from "../../../hooks";
import { VisaApplicationType } from "../../../types/visa/applicationType";
import Row from "../../../components/layout/Row";
import { ReactComponent as DeleteIcon } from "../../../assets/icon-trash-can.svg";
import { FormN } from "../../../types/uncommonFormParts/formN/data";
import { getWorkHistoryLength } from "../../../utils/visaFormDataHelpers/gijinkokuVisaFormDataHelper";
import Column from "../../../components/layout/Column";
import { MAX_WORK_HISTORIES } from "../../../constants/values/gijinkokuVisaValues";


interface WorkHistorySectionProps {
  visaApplicationType: VisaApplicationType;
  formN: FormN;
  onChangeFormN: (data: Partial<FormN>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
}

type WorkHistory = {
  placeOfEmployment: string;
  entryDate: string;
  leaveDate: string;
};

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #FFFFFF;
  border: solid 1px #D5D5D5;
`;

const LocalRow = styled(Row)`
  justify-content: space-between;
`;

const LocalColumn = styled(Column)`
  gap: 10px;
`;

const ButtonTextWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const AddIcon = styled(PlusIcon)`
  path {
    fill: #494C50;
  }
`;

const DeleteIconContainer = styled(Column)`
  cursor: pointer;
  width: 40px;
  height: 30px;
  background-color: #EDEDED;
  border-radius: 100px;
  border: 1px solid #D5D5D5;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 6px 0px #00000040;
`;

const WorkHistorySection: FunctionComponent<WorkHistorySectionProps> = ({
  visaApplicationType,
  formN,
  onChangeFormN,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'workHistorySection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const [workHistories, setWorkHistories] = useState<WorkHistory[]>([]);
  const [placeOfEmploymentErrors, setPlaceOfEmploymentErrors] = useState<string[]>(new Array(MAX_WORK_HISTORIES).fill(""));
  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)
    ? tWithoutPrefix("inputAlert.field")
    : '';
  const getEmptyWorkHistory = (): WorkHistory => ({
    placeOfEmployment: "",
    entryDate: "",
    leaveDate: ""
  });
  const saveData = (data: Partial<FormN>) => { onChangeFormN(data) };
  const updateWorkHistory = (index: number, fieldName: keyof WorkHistory, val: string) => {
    setWorkHistories(prevState =>
      prevState.map((workHistory, i) => i === index
        ? { ...workHistory, [fieldName]: val }
        : workHistory
      )
    );
  };
  const updateWorkHistoryError = (index: number, val: string) => {
    setPlaceOfEmploymentErrors(prevState =>
      prevState.map((error, i) => i === index
        ? val
        : error
      )
    );
  }
  const onClickAddWorkHistory = () => {
    const updatedWorkHistories = [...workHistories, getEmptyWorkHistory()];
    const updatedPlaceOfEmploymentErrors = [...placeOfEmploymentErrors, ''];

    setWorkHistories(updatedWorkHistories);
    setPlaceOfEmploymentErrors(updatedPlaceOfEmploymentErrors);
  }
  const onClickDeleteIcon = (index: number) => {
    const updatedWorkHistories = 
      workHistories.filter((workHistory, i) => i !== index);
    const updatedPlaceOfEmploymentErrors = 
      placeOfEmploymentErrors.filter((error, i) => i !== index);

    let data: Partial<FormN> = {};
    for (let i = 1; i <= workHistories.length; i++) {
      const newWorkHistory = updatedWorkHistories[i - 1];
      const placeOfEmployment = newWorkHistory?.placeOfEmployment || "";
      const entryDate = newWorkHistory?.entryDate || "";
      const leaveDate = newWorkHistory?.leaveDate || "";
      
      data[`work_experience_${i}_place_of_employment` as keyof FormN] = placeOfEmployment;
      data[`work_experience_${i}_date_of_joining` as keyof FormN] = entryDate;
      data[`work_experience_${i}_date_of_leaving` as keyof FormN] = leaveDate; 
    }
      
    setWorkHistories(updatedWorkHistories);
    setPlaceOfEmploymentErrors(updatedPlaceOfEmploymentErrors);
    saveData(data);
  };

  useEffect(() => {
    const newWorkHistories: WorkHistory[] = [];

    for (let i = 1; i <= getWorkHistoryLength(formN); i++) {
      const placeOfEmployment = formN[`work_experience_${i}_place_of_employment` as keyof FormN] || "";
      const entryDate = formN[`work_experience_${i}_date_of_joining` as keyof FormN] || "";
      const leaveDate = formN[`work_experience_${i}_date_of_leaving` as keyof FormN] || "";

      const workHistory: WorkHistory = {
        placeOfEmployment,
        entryDate,
        leaveDate
      };

      newWorkHistories.push(workHistory);
    }

    setWorkHistories(newWorkHistories);
  }, [formN]);

  useEffect(() => {
    const workHistoryValues = workHistories.flatMap(workHistory => ([
      workHistory.placeOfEmployment,
      workHistory.entryDate,
      workHistory.leaveDate
    ]));

    const areAllRequiredFieldsFilled = [
      ...workHistoryValues
    ].every((val) => !!val);

    const areThereNoErrors = [
        placeOfEmploymentErrors
    ].every((errors) => errors.every((err) => !!!err));

    onSectionReadinessChange(areAllRequiredFieldsFilled && areThereNoErrors);
  }, [workHistories, placeOfEmploymentErrors]);
  
  return (
    <Section>
      {workHistories.map((workHistory, index) => (
        <LocalFieldGroup key={index}>
          <LocalRow>
            <FieldGroupNumber>{index + 1}</FieldGroupNumber>
            <DeleteIconContainer onClick={() => onClickDeleteIcon(index)}>
              <DeleteIcon/>
            </DeleteIconContainer>
          </LocalRow>

          {/* Place of employment */}
          <TextField
            required
            label={t("placeOfEmployment")}
            value={workHistory.placeOfEmployment}
            error={placeOfEmploymentErrors[index] || getEmptyError(workHistory.placeOfEmployment)}
            maxLength={172}
            validators={[validation.createLengthValidator(172)]}
            onValueChange={(val) => {
              updateWorkHistory(index, "placeOfEmployment", val);
            }}
            onErrorChange={(newErr) => {
              updateWorkHistoryError(index, newErr);
            }}
            onBlur={() => {
              saveData({
                [`work_experience_${index + 1}_place_of_employment`]: workHistory.placeOfEmployment
              })
            }}
          />

          {/* Entry date */}
          <DateSelectField
            required
            label={t("entryDate")}
            minDate={new Date(1950, 0, 1)}
            maxDate={new Date()}
            value={workHistory.entryDate}
            error={getEmptyError(workHistory.entryDate)}
            onValueChange={(val) => {
              if (val) 
                saveData({[`work_experience_${index + 1}_date_of_joining`]: val });
              
              updateWorkHistory(index, "entryDate", val);
            }}
          />

          {/* Leave date */}
          <DateSelectField
            required
            label={t("leaveDate")}
            minDate={new Date(1950, 0, 1)}
            maxDate={new Date()}
            value={workHistory.leaveDate}
            error={getEmptyError(workHistory.leaveDate)}
            onValueChange={(val) => {
              if (val) 
                saveData({[`work_experience_${index + 1}_date_of_leaving`]: val });
              
              updateWorkHistory(index, "leaveDate", val);
            }}
          />
        </LocalFieldGroup>
      ))}

      <LocalColumn>
        <Button 
          disabled={workHistories.length >= MAX_WORK_HISTORIES}
          variant="inline" 
          onClick={onClickAddWorkHistory}
        >
          <ButtonTextWrapper>
            <AddIcon />
            {t("addWorkHistory")}
          </ButtonTextWrapper>
        </Button>
        { workHistories.length >= MAX_WORK_HISTORIES &&
          <Text color="#626161">
              {t("noteOnMoreWorkHistories")}
          </Text>
        }
      </LocalColumn>
    </Section>
  );
};

export default WorkHistorySection;
