export enum VisaAttachmentKind {
  Application = 'application',
  Photo = 'photo',
  Passport = 'passport',
  ResidenceCard = 'residence_card',
  //The following item name says "Supporter" but the string says "dependent". This difference is intentional.
  //Backend uses the official translation and it uses the word "dependent", actually meaning "supporter". 
  //Most likely, it is a mistranslation
  DocsProvingRelationshipBtwApplicantAndSupporter = 'documents_proving_the_relationship_between_the_applicant_and_the_dependent',
  SupporterResidenceCardOrPassport = 'supporter_residence_card_or_passport',
  DocsCertifyingOccupationAndIncomeOfDependent = 'documents_certifying_occupation_and_income_of_dependent',

  //企業区分に該当することを証明する文書
  DocsProvingCategory = 'documents_proving_category',

  //本人の職務内容等を明らかにする資料
  MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc = 'materials_that_clarify_the_details_of_the_applicants_activities_etc',

  //登記事項証明書
  RegistrationCertificate = 'registration_certificate',

  //事業内容を明らかにする資料
  MaterialsThatClarifyTheBusinessDetails = 'materials_that_clarify_the_business_details',

  //直近の年度の決算文書の写し
  FinancialStatements = 'financial_statements',

  //住民税の課税・納税証明書
  ResidentTaxTaxationCertificateAndTaxPaymentCertificate = 'resident_tax_taxation_certificate_and_tax_payment_certificate',

  //前年分の給与所得の源泉徴収票等の法定調書合計表を提出できない理由を明らかにする資料
  MaterialsThatClarifyTheReason = 'materials_that_clarify_the_reason',

  //提出書類一覧表
  ListOfSubmittedDocuments = 'list_of_submitted_documents',

  //在学証明書
  EnrollmentCertificate = 'enrollment_certificate',

  //成績証明書
  Transcript = 'transcript',

  //以前の通学先の成績証明書
  PastSchoolTranscript = 'past_school_transcript',

  //生活費の支払いに関する申告書
  DeclarationConcerningPaymentOfLivingExpenses = 'declaration_concerning_payment_of_living_expenses',

  //本国での収入・資産の額を証明する資料
  DocsProvingAmountOfIncomeAssetsInHomeCountry = 'documents_proving_amount_of_income_assets_in_home_country',

  //住民税の課税・納税証明書（各一通）or 給与明細書
  MunicipalTaxCertificatesOrPaySlips = 'municipal_tax_certificates_or_pay_slips',

  //支援者との関係を明らかにする資料
  DocsClarifyingRelationshipWithTheSponsor = 'documents_clarifying_relationship_with_the_sponsor',

  //送金証明書
  RemittanceCertificate = 'remittance_certificate',

  //携行者の身分を証明する資料
  DocsProvingTheIdentityOfThePersonCarryingTheFunds = 'documents_proving_the_identity_of_the_person_carrying_the_funds',

  //奨学金の給付に関する証明書
  CertificateRegardingScholarshipDisbursement = 'certificate_regarding_scholarship_disbursement',

  //研究内容を証明する文書
  DocsProvingResearchContents = 'documents_proving_research_contents',

  //履修届けの写し又は聴講科目及び聴講時間を証明する文書
  CopyOfCourseRegistrationOrDocProvingSubjectsAndLectureHours = 'copy_of_course_registration_or_document_proving_subjects_and_lecture_hours',

  //大学の管理体制を説明した文書
  DocExplainingTheUniversitysManagementStructure = 'document_explaining_the_universitys_management_structure',
  
  //専門士又は高度専門士の称号を付与されたことを証明する文書
  DocCertifyingGrantedTitleOfSpecialistOrAdvancedSpecialist = 'document_certifying_that_they_were_granted_the_title_of_Specialist_or_Advanced_Specialist',

  //学歴及び職歴その他経歴等を証明する文書
  DocsCertifyingApplicantsEduBackgroundWorkHistoryAndOtherBackground = 'documents_certifying_the_applicants_educational_background_work_history_and_other_backgrounds',

  //派遣先での活動内容を明らかにする資料
  DocClarifyingDetailsOfApplicantsActivitiesAtPlaceOfDispatch = 'document_clarifying_the_details_of_the_applicants_activities_at_the_place_of_dispatch',  

  Other = 'other'
}

export type VisaAttachment = {
  id: number;
  created_at: string;
  updated_at: string;

  visa_application_id: number;
  kind: VisaAttachmentKind;
  url: string;
  size: number;
}