import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Section from '../../../../../components/layout/Section';
import UploadField from '../../../../../components/compound/UploadField';
import { AttachmentBodyProps } from '../../../../../types/visa/attachmentProp';
import { VisaAttachmentKind } from '../../../../../types/visa/attachment';
import InlineMessage from '../../../../../components/form/InlineMessage';

interface DependentVisaAttachmentsProps extends AttachmentBodyProps {}


const DependentVisaAttachments: FunctionComponent<DependentVisaAttachmentsProps> = ({
    visaAttachments,
    onVisaAttachmentUpload,
    onVisaAttachmentDelete,
    onSectionReadinessChange,
    showRequiredAlert
}) => {
    const { t } = useTranslation('translation', { keyPrefix: 'attachmentsSection' });

    useEffect(() => {
        const requiredKinds = [
            VisaAttachmentKind.Passport,
            VisaAttachmentKind.ResidenceCard,
            VisaAttachmentKind.Photo,
            VisaAttachmentKind.SupporterResidenceCardOrPassport,
            VisaAttachmentKind.DocsProvingRelationshipBtwApplicantAndSupporter,
            VisaAttachmentKind.DocsCertifyingOccupationAndIncomeOfDependent
          ]
        
          const givenKinds = new Set(visaAttachments.map(va => va.kind));
          onSectionReadinessChange(requiredKinds.every(rk => givenKinds.has(rk)))
    }, [visaAttachments]);

    return (
        <Section>
            <InlineMessage variant='caution'>
                {t("under5MB")}
            </InlineMessage>

            {/* Passport / パスポート */}
            <UploadField
                required
                label={t("passport.label")}
                note={t("passport.note")}
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.Passport}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Residence Card / 在留カード */}
            <UploadField
                required
                label={t("residenceCard.label")}
                note={t("residenceCard.note")}
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.ResidenceCard}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Photo / 顔写真 */}
            <UploadField
                required
                label={t("photo.label")}
                note={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{t("photo.notes.description")}</div>
                        <ol style={{ paddingInlineStart: 18 }}>
                            <li>{t("photo.notes.req1")}</li>
                            <li>{t("photo.notes.req2")}</li>
                            <li>{t("photo.notes.req3")}</li>
                            <li>{t("photo.notes.req4")}</li>
                            <li>{t("photo.notes.req5")}</li>
                            <li>{t("photo.notes.req6")}</li>                            
                        </ol>
                    </div>
                }
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.Photo}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Supporter's residence card or passport / 扶養者の在留カードまたはパスポート */}
            <UploadField
                required
                label={t("supportsResidenceCardOrPassport.label")}
                note={t("supportsResidenceCardOrPassport.note")}
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.SupporterResidenceCardOrPassport}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Document that proves your relationship with your supporter / 扶養者との関係を証明するもの */}
            <UploadField
                required
                label={t("docThatProvesRelationshipWithSupporter.label")}
                note={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
                        <div>{t("docThatProvesRelationshipWithSupporter.notes.description1")}</div>
                        <div>{t("docThatProvesRelationshipWithSupporter.notes.description2")}</div>
                    </div>
                }
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.DocsProvingRelationshipBtwApplicantAndSupporter}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Document that proves your supporter's job and income / 扶養者の職業・収入を証明するもの */}
            <UploadField
                required
                label={t("docThatProvesSupportersJobAndIncome.label")}
                note={
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div>{t("docThatProvesSupportersJobAndIncome.notes.description1")}</div>
                        <ol style={{ paddingInlineStart: 18 }} type="1">
                            <li>
                                {t("docThatProvesSupportersJobAndIncome.notes.item1")}
                                <ol style={{ paddingInlineStart: 18 }} type="a">
                                    <li>{t("docThatProvesSupportersJobAndIncome.notes.item1a")}</li>
                                </ol>
                            </li>
                            <li>{t("docThatProvesSupportersJobAndIncome.notes.item2")}</li>
                            <li>
                                {t("docThatProvesSupportersJobAndIncome.notes.item3")}
                                <ol style={{ paddingInlineStart: 18 }} type="a">
                                    <li>{t("docThatProvesSupportersJobAndIncome.notes.item3a")}</li>
                                    <li>{t("docThatProvesSupportersJobAndIncome.notes.item3b")}</li>
                                    <li>{t("docThatProvesSupportersJobAndIncome.notes.item3c")}</li>
                                </ol>
                            </li>
                        </ol>
                        <div>{t("docThatProvesSupportersJobAndIncome.notes.description2")}</div>
                        <ul style={{ paddingInlineStart: 18, marginBottom: 0 }}>
                            <li>{t("docThatProvesSupportersJobAndIncome.notes.item4")}</li>
                        </ul>
                    </div>
                }
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.DocsCertifyingOccupationAndIncomeOfDependent}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
                enableRequiredAlert={showRequiredAlert}
            />

            {/* Other / その他 */}
            <UploadField
                label={t("other.label")}
                note={t("other.note")}
                visaAttachments={visaAttachments}
                visaAttachmentKind={VisaAttachmentKind.Other}
                onAttachmentUpload={onVisaAttachmentUpload}
                onAttachmentDelete={onVisaAttachmentDelete}
            />
        </Section>
    );
};

export default DependentVisaAttachments;
