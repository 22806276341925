import { RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options";
import { ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD, ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD, FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD, FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD, SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD, TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options/education";
import { ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD } from "../../constants/options/organization";
import { MAX_EMPLOYMENTS, MAX_SUPPORTERS } from "../../constants/values/studentVisaValues";
import { FormP } from "../../types/uncommonFormParts/formP/data";

export const getLengthOfSupporters = (data: FormP): number => {
  let length = 0;

  for (let i = 1; i <= MAX_SUPPORTERS; i++) 
  {
    const isThereValue = [
      data[`expenses_supporter_${i}_name` as keyof FormP],
      data[`expenses_supporter_${i}_address_prefecture` as keyof FormP],
      data[`expenses_supporter_${i}_address_municipality` as keyof FormP],
      data[`expenses_supporter_${i}_address_street` as keyof FormP],
      data[`expenses_supporter_${i}_telephone_no` as keyof FormP],
      data[`expenses_supporter_${i}_place_of_employment` as keyof FormP],
      data[`expenses_supporter_${i}_place_of_employment_telephone_no` as keyof FormP],
      data[`expenses_supporter_${i}_annual_income` as keyof FormP]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const getLengthOfEmployments = (data: FormP): number => {
  let length = 0;

  for (let i = 1; i <= MAX_EMPLOYMENTS; i++) 
  {
    const isThereValue = [
      data[`other_activities_${i}_type_of_work` as keyof FormP],
      data[`other_activities_${i}_place_of_employment` as keyof FormP],
      data[`other_activities_${i}_telephone_no` as keyof FormP],
      data[`other_activities_${i}_work_time_per_week` as keyof FormP],
      data[`other_activities_${i}_salary_monthly_or_daily` as keyof FormP],
      data[`other_activities_${i}_salary` as keyof FormP]
    ].some(v => !!v);

    if (isThereValue) {
      length = i;
    }
  }

  return length;
}

export const getLabelFromEnrollmentStatus = (value: string): string => {
  return ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromFinalEducation = (value: string): string => {
  return FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromRelationshipWithApplicant = (value: string): string => {
  return RELATIONSHIP_WITH_SUPPORTER_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromTypeOfClass = (value: string): string => {
  return TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromOrgInCharge = (value: string): string => {
  return ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromRegistration = (value: string): string => {
  return ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromFacultyCourse = (value: string): string => {
  return FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}

export const getLabelFromNameOfSpecializedCourse = (value: string): string => {
  return SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD.find(op => op.value === value)?.label ?? "";
}