import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { LocalColumn } from "./previewBodyCommonStyle";
import { PreviewModalBodyProps } from "../../../../types/modal/preview";
import {
  isChangeBySelfData,
  isExtensionBySelfData,
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../utils/visaApplicationHelper";
import BasicInformationSection from "./previewBodySections/ApplicantBlock/BasicInformationSection";
import StatusOfResidenceSection from "./previewBodySections/ApplicantBlock/StatusOfResidenceSection";
import AttachmentsSection from "./previewBodySections/ApplicantBlock/AttachmentsSection/AttachmentsSection";
import DependentSection from "./previewBodySections/ApplicantBlock/DependentSection";
import OtherSection from "./previewBodySections/ApplicantBlock/OtherSection";
import DetailsOfExtensionSection from "./previewBodySections/ApplicantBlock/DetailsOfExtensionSection";
import FamilyCoresidentsSection from "./previewBodySections/ApplicantBlock/FamilyCoresidentsSection";
import DetailsOfChangeSection from "./previewBodySections/ApplicantBlock/DetailsOfChangeSection";

interface DependentVisaPreviewBodyProps extends PreviewModalBodyProps {}

const DependentVisaPreviewBody: FunctionComponent<DependentVisaPreviewBodyProps> = ({
  visaApplication,
  visaApplicationType,
}) => {
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const data = isVisaExtension
    ? visaApplication.koushin_honnin
    : visaApplication.henkou_honnin;

  if (!data) return null;

  return (
    <LocalColumn>
      <BasicInformationSection 
        visaApplication={visaApplication}
        data={data} 
      />

      <StatusOfResidenceSection
        visaApplicationType={visaApplicationType}
        data={data}
      />

      {isVisaExtension && isExtensionBySelfData(data) && (
        <DetailsOfExtensionSection
          data={data}
          visaApplicationType={visaApplicationType}
        />
      )}

      {isVisaChange && isChangeBySelfData(data) && (
        <DetailsOfChangeSection
          data={data}
          visaApplicationType={visaApplicationType}
        />
      )}

      <FamilyCoresidentsSection data={data} />

      <DependentSection
        visaApplicationType={visaApplicationType}
        visaAttachments={visaApplication.visa_attachments}
      />

      <OtherSection visaApplicationType={visaApplicationType} data={data} />

      <AttachmentsSection
        visaApplication={visaApplication}
        visaApplicationType={visaApplicationType}
        visaAttachments={visaApplication.visa_attachments}
      />
    </LocalColumn>
  );
};

export default DependentVisaPreviewBody;
