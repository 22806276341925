//This value is not used in the form for the user. We directly assign the value to the field.
//This value must be used for Koushin Honnin (extension by self) / Henkou Honnin (change by self) data 
//Ref: https://drive.google.com/file/d/1gDVnFJFv9KbmdKu7WYBTyZ3A0oVqEB1-/view
export const No = '1';

//This value is not used in the form for the user. We directly assign the value to the field.
//The original field is "申請内容の控え有無"
//We only use Yes, so "No" is actually not necessary to be defined here 
export enum KeptVisaApplicationContent {
  Yes = "1",
  No = "0"
}