//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

import { SelectOption } from "../../types/option";

export const TYPE_OF_CONTRACT_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
  {
      "label": "typeOfContractForGijinkoku.0",
      "value": "雇用"
  },
  {
      "label": "typeOfContractForGijinkoku.1",
      "value": "委任"
  },
  {
      "label": "typeOfContractForGijinkoku.2",
      "value": "請負"
  },
  {
      "label": "typeOfContractForGijinkoku.9",
      "value": "その他"
  }
];