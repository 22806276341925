
import { FormP } from "../types/uncommonFormParts/formP/data";
import { axiosInstance, hasErrorStatus, throwRequestError } from "./common";

//TODO: Test following APIs when we actually use the form R at frontend. Never tested yet.

export const createFormP = async (visaApplicationId: number | string, data?: Partial<FormP>) => {
  const response = await axiosInstance.request({
      method: 'POST',
      url: `form_ps`,
      data: {
        ...data,
        visa_application_id: visaApplicationId
      }
  });

  if (hasErrorStatus(response)) 
    throwRequestError('createFormP', response.status);

  return response.data as FormP;
};

export const getFormP = async (FormPId: number | string) => {
  const response = await axiosInstance.request({
      method: 'GET',
      url: `form_ps/${FormPId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('getFormP', response.status);

  return response.data as FormP;
};

export const updateFormP = async (FormPId: number | string, data: Partial<FormP>) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `form_ps/${FormPId}`,
      data
  });

  if (hasErrorStatus(response))
    throwRequestError('updateFormP', response.status);

  return response.data as FormP;
};

export const updateFormPAsInvitee = async (FormPId: number | string, data: Partial<FormP>, invitationToken: string) => {
  const response = await axiosInstance.request({
      method: 'PUT',
      url: `form_ps/${FormPId}/invitee_update`,
      data: {
        ...data,
        invitation_token: invitationToken
      }
  });

  if (hasErrorStatus(response))
    throwRequestError('updateFormPAsInvitee', response.status);

  return response.data as FormP;
}

export const deleteFormP = async (FormPId: number | string) => {
  const response = await axiosInstance.request({
      method: 'DELETE',
      url: `form_ps/${FormPId}`
  });

  if (hasErrorStatus(response))
    throwRequestError('deleteFormP', response.status); 

  return response.data as FormP;
};


