import { FunctionComponent, useEffect, useState } from "react";
import styled, { css } from "styled-components";
import Button from "../../components/form/Button";
import Page from "../../components/layout/Page";
import Text from "../../components/text/Text";
import { ReactComponent as PlusIcon } from "../../assets/icon-plus-thick.svg";
import Row from "../../components/layout/Row";
import { useTranslation } from "react-i18next";
import PageHeading from "../../components/text/PageHeading";
import Column from "../../components/layout/Column";
import CreateNewAppModal from "./CreateNewAppModal";
import CategoryHeading from "../../components/text/CategoryHeading";
import { getVisaApplications } from "../../apis/visaApplication";
import { VisaApplication } from "../../types/visa/applicationData";
import { useDispatcher } from "../../hooks";
import { VisaApplicationStatus } from "../../types/visa/applicationStatus";
import {  getVisaInfoTransKeys } from "../../utils/visaApplicationHelper";
import { useNavigate } from "react-router-dom";
import PaymentPromptModal from "../../components/modal/PaymentPromptModal";
import AppStatusLabel from "../../components/form/AppStatusLabel";
import { clearURLParameters, formatDateString, isEnumValIncludedInURLParams } from "../../utils/utils";
import UserAuthenticationModal from "./UserAuthenticationModal";
import { NextAction as AuthModalAction } from "./UserAuthenticationModal";
import {  UserRegistrationApplication } from "../../types/userRegistration/data";
import { getLatestUserRegistrationApplication } from "../../apis/userRegistration";
import { getSubmissionDate, isUserRegistrationApplicationBeingProcessed, isUserRegistrationApplicationDenied } from "../../utils/userRegistrationHelper";
import { getCurrentUser } from "../../apis/user";
import { StatusOfResidenceOfUser } from "../../types/user/data";


interface AppCardsProps {
  visaApplications: VisaApplication[];
}


//This action is only used in the homepage to see if the payment modal should show or not, so not defined in the modal.
//The string value actually doesn't have a special meaning so it's okay to change later
//but since the backend uses it inside a welcome email, we have to be careful when changing it
enum PaymentModalAction {
  ShowModal = 'shwpymntmdl'
}

const LocalPage = styled(Page)`
  padding: 20px;
  gap: 30px;
`;

const LocalButton = styled(Button)`
  width: auto;
  min-width: 114px;
  align-self: flex-start;
`;

const LocalRow = styled(Row)`
  justify-content: space-between;
`;

const LocalColumn = styled(Column)`
  gap: 15px;
`;

const cardShapeStyle = css`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 280px;
  width: 100%;
  padding: 20px;
`;

const UserAuthStatusContainer = styled.div`
  ${cardShapeStyle}
  border-radius: 5px;
  border: 2px dashed #1f82ff;
  background-color: #F5F9FF;
  gap: 20px;
`;

const PaymentPromtContainer = styled.div`
  ${cardShapeStyle}
  border: 2px dashed #E93232;
  border-radius: 5px;
  background-color: #fff0f0;
  gap: 20px;
`;

const CreatePromptContainer = styled.div`
  ${cardShapeStyle}
  border: 1px solid #EDEDED;
  background-color: #ffffff;
  gap: 20px;
`;

const AppCardContainer = styled.div`
  ${cardShapeStyle}
  border-radius: 10px;
  border: 1px solid #d5d5d5;
  background-color: #ffffff;
  box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
`;

const SmallText = styled(Text)`
  font-size: 12px;
  color: #999999;
`;

const ActionButton = styled(Button).attrs(() => ({
  variant: 'inline',
}))`
  width: auto;
  min-width: 123px;
  align-self: flex-start;
`;

const ApplicationCards: FunctionComponent<AppCardsProps> = ({
  visaApplications
}) => { 
  const { t } = useTranslation("translation", { keyPrefix: "homepage" });
  const navigate = useNavigate();
  const onClick = (id: number, isSubmitted: boolean) => { 
    const path = isSubmitted 
      ? `/application/${id}/review` 
      : `/application/${id}/edit`;

    navigate(path); 
  }

  return (
    <>
      {
        visaApplications.map((visaApplication, i) => {
          const lastSubmission = visaApplication.last_submitted_visa_submission;
          const isSubmitted = !!lastSubmission;
     
          return (
            <AppCardContainer 
              onClick={() => onClick(visaApplication.id, isSubmitted)}
              key={`visa-application-card-${i}`}
            >
              <LocalRow>
                <CategoryHeading style={{ margin: 0 }}>
                  {
                    getVisaInfoTransKeys(visaApplication).type &&
                    t(`types.${getVisaInfoTransKeys(visaApplication).type}`)
                  }
                </CategoryHeading>
                <AppStatusLabel status={
                  lastSubmission?.app_status ?? VisaApplicationStatus.Working
                }/>
              </LocalRow>
              <Row>
                <Text>
                  { getVisaInfoTransKeys(visaApplication).visa && 
                    t(`visas.${getVisaInfoTransKeys(visaApplication).visa}`) 
                  }
                </Text>
              </Row>
              <Row style={{ flexDirection: "row-reverse" }}>
                <SmallText>
                  {`${t("created")} ${formatDateString(visaApplication.created_at)}`}
                </SmallText>
              </Row>
            </AppCardContainer>
          )
        })
      }
    </>
  )
}


const HomePage: FunctionComponent = () => {
  const { state, dispatcher } = useDispatcher();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'homepage' });
  const [visaApplications, setVisaApplications] = useState<VisaApplication[]>([]);
  const [userRegistrationApplication, setUserRegistrationApplication] = useState<UserRegistrationApplication>();
  const [userStatusOfResidence, setUserStatusOfResidence] = useState<StatusOfResidenceOfUser>();
  const [paymentUrl, setPaymentUrl] = useState<string>('/');
  const [showCreateAppModal, setShowCreateAppModal] = useState<boolean>(false);
  const [showPaymentPromptModal, setShowPaymentPromptModal] = useState<boolean>(false);
  const [showUserAuthModal, setShowUserAuthModal] = useState<boolean>(false);
  const [hasUserPaid, setHasUserPaid] = useState<boolean>(false);
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false);
  const isUserRegistrationBeingProcessed = isUserRegistrationApplicationBeingProcessed(state.userRegistrationAppStatus);
  const isUserRegistrationDenied = isUserRegistrationApplicationDenied(state.userRegistrationAppStatus);
  const showPromtpsToUser = isUserRegistrationBeingProcessed || isUserRegistrationDenied || !hasUserPaid;

  useEffect(() => {
    (async () => {
      dispatcher.startLoading();
      try {
        const userRegistrationApplication = await getLatestUserRegistrationApplication();
        const visaApplications = await getVisaApplications();
        const user = await getCurrentUser();

        setVisaApplications(visaApplications.reverse());
        setUserStatusOfResidence(user.status_of_residence);
        setUserRegistrationApplication(userRegistrationApplication);
        setPaymentUrl(user.stripe_invoice_url);
        setIsDataLoaded(true);

        //For first MVP, if the user has paid at least one application,
        //then, we won't show the payment prompt card & modal
        if (user.is_paid)
          setHasUserPaid(true);
        
        if(isEnumValIncludedInURLParams(AuthModalAction)) {
          setShowUserAuthModal(true);           
          return;
        }

        if (isEnumValIncludedInURLParams(PaymentModalAction)) {
          setShowPaymentPromptModal(true);
          clearURLParameters();
          return;
        }

      } catch (e) {
      } finally {
        dispatcher.stopLoading();
      }
    })();
  }, []);

  return (
    <>
      <LocalPage>
        {/* Heading + Create Button */}
        <LocalRow>
          <PageHeading>{t("applications")}</PageHeading>
          <LocalButton variant="secondary" onClick={() => setShowCreateAppModal(true)}>
            <PlusIcon style={{ marginRight: 10 }} />
            {t("create")}
          </LocalButton>
        </LocalRow>


        {/* Prompts to the user */}
        { isDataLoaded && showPromtpsToUser &&
          <LocalColumn>
            {/* User Auth Status prompt (Show while the user registaration application is still in process) */}
            {isUserRegistrationBeingProcessed && (
              <UserAuthStatusContainer>
                <Text>{t("userAuth.beingProcessed.description")}</Text>
                <ActionButton onClick={() => setShowUserAuthModal(true)}>
                  {t("userAuth.beingProcessed.checkResult")}
                </ActionButton>
                <SmallText>{t("userAuth.beingProcessed.submitted")} {
                  userRegistrationApplication && getSubmissionDate(userRegistrationApplication)
                }</SmallText>
              </UserAuthStatusContainer>
            )}

            {/* User Auth Status prompt (Show only if the user registration is denied) */}
            {isUserRegistrationDenied && (
              <UserAuthStatusContainer>
                <Text>{t("userAuth.denied.description")}</Text>
                <ActionButton onClick={() => navigate("/onboarding")}>
                  {t("userAuth.denied.tryAgain")}
                </ActionButton>
                <SmallText>{t("userAuth.denied.submitted")} {
                  userRegistrationApplication && getSubmissionDate(userRegistrationApplication)
                }</SmallText>
              </UserAuthStatusContainer>
            )}

            {/* Payment prompt (Show only if the user has not paid yet) */}
            { !hasUserPaid &&
              <PaymentPromtContainer>
                <Text>
                  {t("payment.description")}
                </Text>
                <ActionButton onClick={() => setShowPaymentPromptModal(true)}>
                  {t("payment.viewDetails")}
                </ActionButton>
              </PaymentPromtContainer>
            }
          </LocalColumn>
        }

        {/* Create Prompt */}
        {isDataLoaded && visaApplications.length === 0 &&
          <CreatePromptContainer>
            <Text>{t("getStarted")}</Text>
              <LocalButton
                variant="secondary"
                onClick={() => setShowCreateAppModal(true)}
              >
              <PlusIcon style={{ marginRight: 10 }} />
              {t("create")}
            </LocalButton>
          </CreatePromptContainer>
        }
        
        {/* Application Cards */}
        {visaApplications.length > 0 && (
          <LocalColumn>
            { visaApplications &&
              <ApplicationCards visaApplications={visaApplications} />
            }
          </LocalColumn>
        )}
      </LocalPage>


      {showPaymentPromptModal && (
        <PaymentPromptModal 
          paymentUrl={paymentUrl}
          onClose={() => setShowPaymentPromptModal(false)} 
        />
      )}

      {showCreateAppModal && userStatusOfResidence && (
        <CreateNewAppModal 
          statusOfResidence={userStatusOfResidence}
          onClose={() => setShowCreateAppModal(false)} 
        />
      )}

      {showUserAuthModal && (
        <UserAuthenticationModal
          onClose={() => setShowUserAuthModal(false)}
          onConfirmAuthStatus={(status) => {
            dispatcher.setUserRegistrationAppStatus(status);
          }}
        />
      )}
    </>
  );
};

export default HomePage;
