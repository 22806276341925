import { FunctionComponent } from "react";
import { VisaApplicationType } from "../../../../types/visa/applicationType";
import GijinkokuVisaAttachments from "./SectionBodies/GijinkokuVisaAttachments";
import { VisaAttachment, VisaAttachmentKind } from "../../../../types/visa/attachment";
import { SupplementaryInfo } from "../../../../types/uncommonFormParts/supplementaryInfo";
import StudentVisaAttachments from "./SectionBodies/StudyAbroadVisaAttachments";
import { isThisVisaChange, isThisVisaExtension } from "../../../../utils/visaApplicationHelper";

interface AttachmentsSectionProps {
  visaAttachments: VisaAttachment [],
  visaApplicationType: VisaApplicationType;
  supplementaryInfo: SupplementaryInfo;
  onVisaAttachmentUpload: (file: File, kind: VisaAttachmentKind, uploaderId?: string) => void;
  onVisaAttachmentDelete: (visaAttachmentId: number) => void;
  onVisaAttachmentsDelete: (visaAttachmentIds: number[]) => void;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  showRequiredAlert: boolean;
}

const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  visaAttachments,
  visaApplicationType,
  supplementaryInfo,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onVisaAttachmentsDelete,
  onChangeSupplementaryInfo,
  onSectionReadinessChange,
  showRequiredAlert,
}) => {
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);

  switch (visaApplicationType) {
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return (
        <GijinkokuVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onVisaAttachmentUpload={onVisaAttachmentUpload}
          onVisaAttachmentDelete={onVisaAttachmentDelete}
          onVisaAttachmentsDelete={onVisaAttachmentsDelete}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          onSectionReadinessChange={onSectionReadinessChange}
          showRequiredAlert={showRequiredAlert}
          isVisaExtension={isVisaExtension}
          isVisaChange={isVisaChange}
        />
      );
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
    case VisaApplicationType.ChangeToStudyAbroadVisaBySelf:
      return (
        <StudentVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onVisaAttachmentUpload={onVisaAttachmentUpload}
          onVisaAttachmentDelete={onVisaAttachmentDelete}
          onVisaAttachmentsDelete={onVisaAttachmentsDelete}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          onSectionReadinessChange={onSectionReadinessChange}
          showRequiredAlert={showRequiredAlert}
        />
      )
    default:
      //return null;
      return (
        <StudentVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onVisaAttachmentUpload={onVisaAttachmentUpload}
          onVisaAttachmentDelete={onVisaAttachmentDelete}
          onVisaAttachmentsDelete={onVisaAttachmentsDelete}
          supplementaryInfo={supplementaryInfo}
          onChangeSupplementaryInfo={onChangeSupplementaryInfo}
          onSectionReadinessChange={onSectionReadinessChange}
          showRequiredAlert={showRequiredAlert}
        />
      )
  }
};

export default AttachmentsSection;
