import { SelectOption } from "../../types/option";

// Ref: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=308892565#gid=308892565 
export const ORGANIZATGION_IN_CHARGE_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
  {
    "label": "orgInChargeForStudyAbroad.01",
    "value": "国又は地方公共団体の機関"
  },
  {
    "label": "orgInChargeForStudyAbroad.02",
    "value": "独立行政法人"
  },
  {
    "label": "orgInChargeForStudyAbroad.03",
    "value": "学校法人"
  },
  {
    "label": "orgInChargeForStudyAbroad.04",
    "value": "公益社団法人又は公益財団法人"
  },
  {
    "label": "orgInChargeForStudyAbroad.05",
    "value": "国立大学法人"
  },
  {
    "label": "orgInChargeForStudyAbroad.99",
    "value": "その他"
  }
];