import { FunctionComponent, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import Section from "../../../../../components/layout/Section";
import UploadField from "../../../../../components/compound/UploadField";
import { AttachmentBodyProps } from "../../../../../types/visa/attachmentProp";
import { VisaAttachmentKind } from "../../../../../types/visa/attachment";
import InlineMessage from "../../../../../components/form/InlineMessage";
import RadioField from "../../../../../components/compound/RadioField";
import styled from "styled-components";
import { FirstRenewalApplication, OrganizationType, SupplementaryInfo } from "../../../../../types/uncommonFormParts/supplementaryInfo";
import { extractVisaAttachments } from "../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";

interface GijinkokuVisaAttachmentsProps extends AttachmentBodyProps {
  supplementaryInfo: SupplementaryInfo;
  onChangeSupplementaryInfo: (data: Partial<SupplementaryInfo>) => void;
  showRequiredAlert: boolean;
}


const CustomList = styled.li`
  list-style: none;
  padding-left: 0;

  &::before {
    content: "-";
    margin-right: 5px;
  }
`;

const UnorderedNoteList = styled.ul`
  padding-left: 18px;
  margin: 0;
  list-style-type: disc;
`;

const GijinkokuVisaAttachments: FunctionComponent<GijinkokuVisaAttachmentsProps> = ({
  visaAttachments,
  supplementaryInfo,
  isVisaExtension,
  isVisaChange,
  onChangeSupplementaryInfo,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onVisaAttachmentsDelete,
  onSectionReadinessChange,
  showRequiredAlert
}) => {
  const { t } = useTranslation("translation", { keyPrefix: "attachmentsSection" });
  const { t : tWithoutPrefix } = useTranslation();

  const [organizationType, setOrganizationType] = useState<OrganizationType>();
  const [firstRenewalApp, setFirstRenewalApp] = useState<FirstRenewalApplication>();
  const getEmptyError = (val: any) => (showRequiredAlert && !!!val)? tWithoutPrefix("inputAlert.field"): '';
  const saveSupplementaryInfo = (data: Partial<SupplementaryInfo>) => onChangeSupplementaryInfo(data);

  const deleteAttachments = (kinds: VisaAttachmentKind[]) => {
    let attachmentIds: number [] = [];
    for (const kind of kinds) {
      const ids = extractVisaAttachments(visaAttachments, kind).map(a => a.id);
      attachmentIds = [...attachmentIds, ...ids]
    }
    onVisaAttachmentsDelete?.(attachmentIds);
  }

  const resetOrganizationRelatedAttachments = () => {
    const optionRelatedKinds = [
      VisaAttachmentKind.DocsProvingCategory,
      VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
      VisaAttachmentKind.RegistrationCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
      VisaAttachmentKind.FinancialStatements,
      VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheReason,
    ];

    deleteAttachments(optionRelatedKinds);
  }

  const resetFirstRenewalAppRelatedAttachments = () => {
    const conditionalKinds = 
      organizationType === OrganizationType.OptionD
        ? [VisaAttachmentKind.MaterialsThatClarifyTheReason]
        : [];

    const renewalAppRelatedKinds = [
      VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
      VisaAttachmentKind.RegistrationCertificate,
      VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
      VisaAttachmentKind.FinancialStatements,
      ...conditionalKinds
    ];

    deleteAttachments(renewalAppRelatedKinds);
  }

  useEffect(() => {
    let conditionallyRequiredKinds: VisaAttachmentKind[] = [];
    let conditionallyRequiredFields: any[] = [];

    switch (organizationType) {
      case OrganizationType.OptionA:
      case OrganizationType.OptionB:
        break;
      case OrganizationType.OptionC:
        if (firstRenewalApp === FirstRenewalApplication.Yes) {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
            VisaAttachmentKind.RegistrationCertificate,
            VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
            VisaAttachmentKind.FinancialStatements,
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        } else {
          conditionallyRequiredKinds.push(
              VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        }
        
        conditionallyRequiredFields = [
          firstRenewalApp
        ];
        break;
      case OrganizationType.OptionD:
        if (firstRenewalApp === FirstRenewalApplication.Yes) {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc,
            VisaAttachmentKind.RegistrationCertificate,
            VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails,
            VisaAttachmentKind.FinancialStatements,
            VisaAttachmentKind.MaterialsThatClarifyTheReason,
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate,
          );
        } else {
          conditionallyRequiredKinds.push(
            VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate
          );
        }

        conditionallyRequiredFields = [
          firstRenewalApp
        ];
        break;
    }

    //Todo: This depends on some option values in the form. Need to update later
    const requiredKinds = [
      VisaAttachmentKind.Passport,
      VisaAttachmentKind.ResidenceCard,
      VisaAttachmentKind.Photo,
      VisaAttachmentKind.DocsProvingCategory,
      ...conditionallyRequiredKinds
    ];

    const requiredFields = [
      organizationType,
      ...conditionallyRequiredFields
    ];

    const givenKinds = new Set(visaAttachments.map((va) => va.kind));
    const areAllKindsGiven = requiredKinds.every((rk) => givenKinds.has(rk));
    const areAllFieldsGiven = requiredFields.every((rf) => !!rf);
    onSectionReadinessChange(areAllKindsGiven && areAllFieldsGiven);
  }, [
    visaAttachments,
    organizationType,
    firstRenewalApp
  ]);

  
  useEffect(() => {
    if (!supplementaryInfo)
      return;

    setFirstRenewalApp(supplementaryInfo["first_renewal_application"] as FirstRenewalApplication ?? '');
    setOrganizationType(supplementaryInfo["appropriate_organization_description"] as OrganizationType ?? '');
    
  }, [
    supplementaryInfo
  ]);



  return (
    <Section>
      <InlineMessage variant="caution">{t("under5MB")}</InlineMessage>

      {/* Passport / パスポート */}
      <UploadField
        required
        label={t("passport.label")}
        note={t("passport.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Passport}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Residence Card / 在留カード */}
      <UploadField
        required
        label={t("residenceCard.label")}
        note={t("residenceCard.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.ResidenceCard}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Photo / 顔写真 */}
      <UploadField
        required
        label={t("photo.label")}
        note={
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div>{t("photo.notes.description")}</div>
            <ol style={{ paddingLeft: 18 }}>
              <li>{t("photo.notes.req1")}</li>
              <li>{t("photo.notes.req2")}</li>
              <li>{t("photo.notes.req3")}</li>
              <li>{t("photo.notes.req4")}</li>
              <li>{t("photo.notes.req5")}</li>
              <li>{t("photo.notes.req6")}</li>
            </ol>
          </div>
        }
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Photo}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
        enableRequiredAlert={showRequiredAlert}
      />

      {/* Diploma or advanced diploma for professional training college /
          専門士又は高度専門士の称号を付与されたことを証明する文書 */}
        {/* (*Required for visa change only) */}   
      { isVisaChange &&
        <UploadField
          required
          label={t("diplomaForProfessionalTrainingCollege.label")}
          note={t("diplomaForProfessionalTrainingCollege.note")}
          visaAttachments={visaAttachments}
          visaAttachmentKind={VisaAttachmentKind.DocCertifyingGrantedTitleOfSpecialistOrAdvancedSpecialist}
          onAttachmentUpload={onVisaAttachmentUpload}
          onAttachmentDelete={onVisaAttachmentDelete}
          enableRequiredAlert={showRequiredAlert}
        />
      }

      {/* Select the appropriate description for your organization */}
      <RadioField
        required
        label={t("selectAppropriateDescriptionForYourOrg.label")}
        options={[
          {
            label: (
              <div>
                <b>
                  {t(
                    "selectAppropriateDescriptionForYourOrg.optionA.description"
                  )}
                </b>
                <br />
                <ul style={{ padding: 0, margin: 0 }}>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition1"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition2"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition3"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition4"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition5"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition6"
                    )}
                  </CustomList>
                  <CustomList>
                    {t(
                      "selectAppropriateDescriptionForYourOrg.optionA.condition7"
                    )}
                  </CustomList>
                </ul>
              </div>
            ),
            value: OrganizationType.OptionA,
          },
          {
            label: (
              <div>
                <Trans
                  i18nKey={t(
                    "selectAppropriateDescriptionForYourOrg.optionB.description"
                  )}
                  components={{
                    b: <b />,
                  }}
                />
              </div>
            ),
            value: OrganizationType.OptionB,
          },
          {
            label: (
              <div>
                <Trans
                  i18nKey={t(
                    "selectAppropriateDescriptionForYourOrg.optionC.description"
                  )}
                  components={{
                    b: <b />,
                  }}
                />
              </div>
            ),
            value: OrganizationType.OptionC,
          },
          {
            label: (
              <div>
                <Trans
                  i18nKey={t(
                    "selectAppropriateDescriptionForYourOrg.optionD.description"
                  )}
                  components={{
                    b: <b />,
                  }}
                />
              </div>
            ),
            value: OrganizationType.OptionD,
          },
        ]}
        value={organizationType}
        error={getEmptyError(organizationType)}
        onValueChange={(val) => {
          resetOrganizationRelatedAttachments();
          saveSupplementaryInfo({ 
            appropriate_organization_description: val,
            first_renewal_application: null
          });
          setOrganizationType(val);
          setFirstRenewalApp(undefined);
        }}
      />

      {/* Option A */}
      {organizationType === OrganizationType.OptionA && <>
        {/* Documents certifying that the above description applies */}
        <UploadField
          required
          label={t("docsCertifyingThatTheAboveApplies.label")}
          note={t("docsCertifyingThatTheAboveApplies.notes.forListedCompanies")}
          visaAttachments={visaAttachments}
          visaAttachmentKind={VisaAttachmentKind.DocsProvingCategory}
          onAttachmentUpload={onVisaAttachmentUpload}
          onAttachmentDelete={onVisaAttachmentDelete}
          enableRequiredAlert={showRequiredAlert}
        />
      </>}

      {/* Option B */}
      {organizationType === OrganizationType.OptionB && <>
        {/* Documents certifying that the above description applies
            (Note: This is not the same as the one in Option A. The note text is different.) */}
        <UploadField
          required
          label={t("docsCertifyingThatTheAboveApplies.label")}
          note={t("docsCertifyingThatTheAboveApplies.notes.summaryOfStatutoryStatementForms")}
          visaAttachments={visaAttachments}
          visaAttachmentKind={VisaAttachmentKind.DocsProvingCategory}
          onAttachmentUpload={onVisaAttachmentUpload}
          onAttachmentDelete={onVisaAttachmentDelete}
          enableRequiredAlert={showRequiredAlert}
        />
      </>}

      {/* Option C */}
      {organizationType === OrganizationType.OptionC && <>
        {/* Documents certifying that the above description applies
            (Note: This is not the same as the one in Option A. The note text is different.) */}
        <UploadField
          required
          label={t("docsCertifyingThatTheAboveApplies.label")}
          note={t("docsCertifyingThatTheAboveApplies.notes.summaryOfStatutoryStatementForms")}
          visaAttachments={visaAttachments}
          visaAttachmentKind={VisaAttachmentKind.DocsProvingCategory}
          onAttachmentUpload={onVisaAttachmentUpload}
          onAttachmentDelete={onVisaAttachmentDelete}
          enableRequiredAlert={showRequiredAlert}
        />

        {/* Is this the first renewal application since the applicant
            has entered this organization? */}
        <RadioField
          required
          label={t("firstRenewalAppSinceApplicantHasEnteredThisOrg.label")}
          options={[
            {
              label: tWithoutPrefix("common.yes"),
              value: FirstRenewalApplication.Yes
            },
            {
              label: tWithoutPrefix("common.no"),
              value: FirstRenewalApplication.No
            }
          ]}
          value={firstRenewalApp}
          error={getEmptyError(firstRenewalApp)}
          onValueChange={val => {
            if (val === FirstRenewalApplication.No)
            resetFirstRenewalAppRelatedAttachments();
            
            setFirstRenewalApp(val);
            saveSupplementaryInfo({
              first_renewal_application: val
            });
          }}
        />

        {firstRenewalApp === FirstRenewalApplication.Yes && <>
          {/* Document that describes the applicant's job responsibilities and duties */}
          <UploadField
            required
            label={t("docThatDescribesApplicantsJobRespsAndDuties.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description1")}</li>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description2")}</li>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description3")}</li>
              </UnorderedNoteList>
            )}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Certificate of Registered Matters */}
          <UploadField
            required
            label={t("certificateOfRegisteredMatters.label")}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.RegistrationCertificate}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Documents that describe the nature of the business */}
          <UploadField
            required
            label={t("docsThatDescribeNatureOfBusiness.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docsThatDescribeNatureOfBusiness.notes.description1")}</li>
                <li>{t("docsThatDescribeNatureOfBusiness.notes.description2")}</li>
              </UnorderedNoteList>
            )} 
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Copies of financial documents for the most recent fiscal year */}
          <UploadField
            required
            label={t("copiesOfFinancialDocsForMostRecentFiscalYear.label")}
            note={t("copiesOfFinancialDocsForMostRecentFiscalYear.note")}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.FinancialStatements}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

        </>}

        {/* Applicant's Certificate of Residence Taxation (or Non-Taxation) 
              and Tax Payment Certificate (indicating total income and 
              tax payment status for one year). */}

          {/* THIS ITEM iS REQUIRED FOR VISA EXTENSION ONLY */}
          <UploadField
            required
            label={t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.notes.description1")}</li>
                <li>{t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.notes.description2")}</li>
              </UnorderedNoteList>
            )} 
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />
      </>}

      {/* OptionD */}
      {organizationType === OrganizationType.OptionD && <>
        {/* Documents certifying that the above description applies */}
        <UploadField
          required
          label={t("docsCertifyingThatTheAboveApplies.label")}
          note={t("docsCertifyingThatTheAboveApplies.notes.summaryOfStatutoryStatementForms")}
          visaAttachments={visaAttachments}
          visaAttachmentKind={VisaAttachmentKind.DocsProvingCategory }
          onAttachmentUpload={onVisaAttachmentUpload}
          onAttachmentDelete={onVisaAttachmentDelete}
          enableRequiredAlert={showRequiredAlert}
        />

        {/* Is this the first renewal application since the applicant
            has entered this organization? */}
        <RadioField
          required
          label={t("firstRenewalAppSinceApplicantHasEnteredThisOrg.label")}
          options={[
            {
              label: tWithoutPrefix("common.yes"),
              value: FirstRenewalApplication.Yes
            },
            {
              label: tWithoutPrefix("common.no"),
              value: FirstRenewalApplication.No
            }
          ]}
          value={firstRenewalApp}
          error={getEmptyError(firstRenewalApp)}
          onValueChange={val => {
            if (val === FirstRenewalApplication.No)
              resetFirstRenewalAppRelatedAttachments();
          
            setFirstRenewalApp(val);
            saveSupplementaryInfo({
              first_renewal_application: val
            });
          }}
        />

        {firstRenewalApp === FirstRenewalApplication.Yes && <>
          {/* Document that describes the applicant's job responsibilities and duties */}
          <UploadField
            required
            label={t("docThatDescribesApplicantsJobRespsAndDuties.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description1")}</li>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description2")}</li>
                <li>{t("docThatDescribesApplicantsJobRespsAndDuties.notes.description3")}</li>
              </UnorderedNoteList>
            )}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.MaterialsThatClarifyTheDetailsOfTheApplicantsActivitiesEtc}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Certificate of Registered Matters */}
          <UploadField
            required
            label={t("certificateOfRegisteredMatters.label")}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.RegistrationCertificate}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Documents that describe the nature of the business */}
          <UploadField
            required
            label={t("docsThatDescribeNatureOfBusiness.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docsThatDescribeNatureOfBusiness.notes.description1")}</li>
                <li>{t("docsThatDescribeNatureOfBusiness.notes.description2")}</li>
              </UnorderedNoteList>
            )} 
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.MaterialsThatClarifyTheBusinessDetails}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Copies of financial documents for the most recent fiscal year */}
          <UploadField
            required
            label={t("copiesOfFinancialDocsForMostRecentFiscalYear.label")}
            note={t("copiesOfFinancialDocsForMostRecentFiscalYear.note")}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.FinancialStatements}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

          {/* Documents explaining the reason for not being able to submit 
              the annual total statement of statutory statements, such as 
              withholding tax certificates, for the previous year's earned income */}
          <UploadField
            required
            label={t("docsExplainingReasonForNotBeingAbleToSubmit.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docsExplainingReasonForNotBeingAbleToSubmit.notes.description1")}</li>
                <li>
                  {t("docsExplainingReasonForNotBeingAbleToSubmit.notes.description2")}
                  <UnorderedNoteList>
                    <li>{t("docsExplainingReasonForNotBeingAbleToSubmit.notes.item1")}</li>
                    <li>
                      {t("docsExplainingReasonForNotBeingAbleToSubmit.notes.item2")}
                      <UnorderedNoteList>
                        <li>{t("docsExplainingReasonForNotBeingAbleToSubmit.notes.item2a")}</li>
                        <li>{t("docsExplainingReasonForNotBeingAbleToSubmit.notes.item2b")}</li>
                      </UnorderedNoteList>
                    </li>
                  </UnorderedNoteList>
                </li>
              </UnorderedNoteList>
            )} 
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.MaterialsThatClarifyTheReason}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />

        </>}

        {/* Documents certifying academic and professional background and other career information
            (required for visa change AND the organization type [Option D] is selected only) */}
        { isVisaChange && 
          <UploadField
            required
            label={t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description1")}</li>
                <li>
                  {t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2")}
                  <UnorderedNoteList>
                    <li>{t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2_i")}</li>
                    <li>{t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2_ii")}</li>
                    <li>
                      {t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2_iii")}
                      <UnorderedNoteList>
                        <li>{t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2_iii_note")}</li>
                      </UnorderedNoteList>
                    </li>
                    <li>{t("docsCertifyingAcademicAndProfessionalBackgroundAndOtherCareerInfo.notes.description2_iv")}</li>
                  </UnorderedNoteList>
                </li>
              </UnorderedNoteList>
            )}
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.DocsCertifyingApplicantsEduBackgroundWorkHistoryAndOtherBackground}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />
        }


        {/* Applicant's Certificate of Residence Taxation (or Non-Taxation) 
            and Tax Payment Certificate (indicating total income and 
            tax payment status for one year). */}
          {/* THIS ITEM iS REQUIRED FOR VISA EXTENSION ONLY */}
        { isVisaExtension &&
          <UploadField
            required
            label={t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.label")}
            note={(
              <UnorderedNoteList>
                <li>{t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.notes.description1")}</li>
                <li>{t("applicantsCertOfResidenceTaxationAndTaxPaymentCert.notes.description2")}</li>
              </UnorderedNoteList>
            )} 
            visaAttachments={visaAttachments}
            visaAttachmentKind={VisaAttachmentKind.ResidentTaxTaxationCertificateAndTaxPaymentCertificate}
            onAttachmentUpload={onVisaAttachmentUpload}
            onAttachmentDelete={onVisaAttachmentDelete}
            enableRequiredAlert={showRequiredAlert}
          />
        }
      </>}

      {/* Other / その他 */}
      <UploadField
        label={t("other.label")}
        note={t("other.note")}
        visaAttachments={visaAttachments}
        visaAttachmentKind={VisaAttachmentKind.Other}
        onAttachmentUpload={onVisaAttachmentUpload}
        onAttachmentDelete={onVisaAttachmentDelete}
      />
    </Section>
  );
};

export default GijinkokuVisaAttachments;
