import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import FieldGroup from '../../../components/layout/FieldGroup';
import MultiTextField from '../../../components/compound/MultiTextField';
import SelectField from '../../../components/compound/SelectField';
import TextField from '../../../components/compound/TextField';
import Section from '../../../components/layout/Section';
import {
    MUNICIPALITY_WITH_JP_VALUE_OPTIONS,
    PREFECTURE_WITH_JP_VALUE_OPTIONS
} from '../../../constants/options';
import { useValidation } from '../../../hooks';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import styled from 'styled-components';
import { FormP } from '../../../types/uncommonFormParts/formP/data';

interface PlaceOfStudySectionProps {
  visaApplicationType: VisaApplicationType;
  formP?: FormP | null;
  onChangeFormP: (data: Partial<FormP>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
};

const LocalFieldGroup = styled(FieldGroup)`
  background-color: #FFFFFF;
  border: solid 1px #D5D5D5;
`;

const PlaceOfStudySection: FunctionComponent<PlaceOfStudySectionProps> = ({
  visaApplicationType,
  formP,
  onChangeFormP,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'placeOfStudySection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const [nameOfSchool, setNameOfSchool] = useState<string>('');
  const [prefecture, setPrefecture] = useState<string>('');
  const [municipality, setMunicipality] = useState<string>('');
  const [townStreetApartment, setTownStreetApartment] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');

  const [nameOfSchoolError, setNameOfSchoolError] = useState<string>('');
  const [townStreetApartmentError, setTownStreetApartmentError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');

  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field"): '';

  //Currently, the item names for this section are common between visa extension and change, 
  //so using the same function.
  const saveData = (data: Partial<FormP>) => {
    onChangeFormP(data);
  }

  useEffect(() => {
    if (!formP) 
      return;

    setNameOfSchool(formP["applicant_place_of_study_name"] ?? '');
    setPrefecture(formP["applicant_place_of_study_address_prefecture"] ?? '');
    setMunicipality(formP["applicant_place_of_study_address_municipality"] ?? '');
    setTownStreetApartment(formP["applicant_place_of_study_address_street"] ?? '');
    setPhoneNumber(formP["applicant_place_of_study_telephone_no"] ?? '');
  }, [formP]);


  useEffect(() => {   
    const areAllRequiredFieldsFilled = [
      nameOfSchool,
      prefecture,
      municipality,
      townStreetApartment,
    ].every(val => !!val);

    const areThereNoErrors = [
      nameOfSchoolError,
      townStreetApartmentError,
      phoneNumberError,
    ].every(val => !val);

    onSectionReadinessChange(
      areAllRequiredFieldsFilled && areThereNoErrors
    );
  }, [
    nameOfSchool, 
    prefecture,
    municipality,
    townStreetApartment,
    nameOfSchoolError,
    townStreetApartmentError,
    phoneNumberError,
  ]);
  

  return (
    <Section>
      {/* Name of school */}
      <TextField
        required
        label={t('nameOfSchool')}
        placeholder="tokuty k.k."
        value={nameOfSchool}
        error={nameOfSchoolError || getEmptyError(nameOfSchool)}
        maxLength={172}
        validators={[validation.createLengthValidator(172)]}
        onValueChange={setNameOfSchool}
        onErrorChange={setNameOfSchoolError}
        onBlur={() => { 
          saveData({
            applicant_place_of_study_name: nameOfSchool
          });
        }}
      />

      {/* Address (school) */}
      <LocalFieldGroup 
        title={t('address')}
        note={t('noteOnAddress')}
        theme="light"
      >

        {/* Prefecture */}
        <SelectField
          required
          label={tWithoutPrefix('common.prefecture')}
          placeholder={tWithoutPrefix("placeholder.select")}
          options={PREFECTURE_WITH_JP_VALUE_OPTIONS}
          value={prefecture}
          error={getEmptyError(prefecture)}
          onValueChange={val => {
            setPrefecture(val);
            setMunicipality('');

            saveData({
              applicant_place_of_study_address_prefecture: val,
              applicant_place_of_study_address_municipality: ''
            });
          }}
        />

        {/* Municipality */}
        <SelectField
          disabled={!prefecture}
          required
          label={tWithoutPrefix('common.municipality')}
          placeholder={
            prefecture 
              ? tWithoutPrefix("placeholder.select")
              : t('enterPrefectureFirst')
          }
          options={MUNICIPALITY_WITH_JP_VALUE_OPTIONS[prefecture] ?? []}
          value={municipality}
          error={prefecture 
            ? getEmptyError(municipality)
            : t('enterPrefectureFirst')
          }
          onValueChange={val => {
            setMunicipality(val);
            
            saveData({
              applicant_place_of_study_address_municipality: val
            });
          }}
        />

        {/* Town/Street/Apartment */}
        <MultiTextField
          required
          label={tWithoutPrefix('common.townStreetApartment')}
          placeholder="品川１ー２ー３ビザダスビル３F"
          value={townStreetApartment}
          error={townStreetApartmentError || getEmptyError(townStreetApartment)}
          maxLength={172}
          validators={[validation.createLengthValidator(172)]}
          onValueChange={setTownStreetApartment}
          onErrorChange={setTownStreetApartmentError}
          onBlur={() => {
            saveData({
              applicant_place_of_study_address_street: townStreetApartment
            })
          }}
        />
      </LocalFieldGroup>

      {/* Phone number */}
      <TextField
        label={tWithoutPrefix('common.phoneNumber')}
        placeholder="0312345678"
        value={phoneNumber}
        error={phoneNumberError || getEmptyError(phoneNumber)}
        restriction={tWithoutPrefix('inputNote.halfwidthNumberNoHyphens')}
        maxLength={12}
        validators={[validation.isNumeric, validation.createLengthValidator(12)]}
        onValueChange={setPhoneNumber}
        onErrorChange={setPhoneNumberError}
        onBlur={() => {
          saveData({
            applicant_place_of_study_telephone_no: phoneNumber
          });
        }}
      />
    
    </Section>
  );
};

export default PlaceOfStudySection;