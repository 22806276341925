import { SelectOption } from "../../types/option";

export const TIME_PERIOD_YEAR_OPTIONS: SelectOption[] = [
  {
      "label": "timePeriod.year.0",
      "value": "0"
  },
  {
      "label": "timePeriod.year.1",
      "value": "1"
  },
  {
      "label": "timePeriod.year.2",
      "value": "2"
  },
  {
      "label": "timePeriod.year.3",
      "value": "3"
  },
  {
      "label": "timePeriod.year.4",
      "value": "4"
  },
  {
      "label": "timePeriod.year.5",
      "value": "5"
  }
];


export const TIME_PERIOD_MONTH_OPTIONS: SelectOption[] = [
  {
      "label": "timePeriod.month.0",
      "value": "0"
  },
  {
      "label": "timePeriod.month.1",
      "value": "1"
  },
  {
      "label": "timePeriod.month.2",
      "value": "2"
  },
  {
      "label": "timePeriod.month.3",
      "value": "3"
  },
  {
      "label": "timePeriod.month.4",
      "value": "4"
  },
  {
      "label": "timePeriod.month.5",
      "value": "5"
  },
  {
      "label": "timePeriod.month.6",
      "value": "6"
  },
  {
      "label": "timePeriod.month.7",
      "value": "7"
  },
  {
      "label": "timePeriod.month.8",
      "value": "8"
  },
  {
      "label": "timePeriod.month.9",
      "value": "9"
  },
  {
      "label": "timePeriod.month.10",
      "value": "10"
  },
  {
      "label": "timePeriod.month.11",
      "value": "11"
  }
];