import { FunctionComponent, HTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';
import InlineMessage from '../form/InlineMessage';

type Theme = 'light' | 'dark';

interface CompoundFieldProps extends HTMLAttributes<HTMLDivElement> {
    title?: string;
    note?: string | ReactNode;
    theme?: Theme;
}

interface ThemeProps {
    theme?: Theme;
}

const Container = styled.div<ThemeProps>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding: 20px;
  border-radius: 3px;
  border: ${(props: ThemeProps) => (props.theme === 'dark' ? 'none' : 'solid 1px #D5D5D5')};
  background: ${(props: ThemeProps) => (props.theme === 'dark' ? '#EDEDED' : '#FFFFFF')};
`;

const Title = styled.div<ThemeProps>`
  width: 100%;
  font-family: "Noto Sans", sans-serif;
  font-weight: ${(props: ThemeProps) => (props.theme === 'dark' ? 500 : 'bold' )};
  font-size: 14px;
  line-height: 20px;
  color: #444444;
`;

const FieldGroup: FunctionComponent<CompoundFieldProps> = ({
    title,
    note,
    theme = 'dark',
    children,
    ...props
}) => {
    return (
        <Container theme={theme} {...props}>
            {title && (
                <Title theme={theme}>{title}</Title>
            )}
            {note && (
                <InlineMessage>{note}</InlineMessage>
            )}
            {children}
        </Container>
    );
};

export default FieldGroup;