//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

import { SelectOption } from "../../types/option";
export const FINAL_EDUCATION_OPTIONS_FOR_GIJINKOKU: SelectOption[] = [
  {
      "label": "finalEducationForGijinkoku.1",
      "value": "大学院(博士)"
  },
  {
      "label": "finalEducationForGijinkoku.2",
      "value": "大学院(修士)"
  },
  {
      "label": "finalEducationForGijinkoku.3",
      "value": "大学"
  },
  {
      "label": "finalEducationForGijinkoku.4",
      "value": "短期大学"
  },
  {
      "label": "finalEducationForGijinkoku.5",
      "value": "専門学校"
  },
  {
      "label": "finalEducationForGijinkoku.6",
      "value": "高等学校"
  },
  {
      "label": "finalEducationForGijinkoku.7",
      "value": "中学校"
  },
  {
      "label": "finalEducationForGijinkoku.9",
      "value": "その他"
  }
];

//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit?gid=308892565#gid=308892565
export const FINAL_EDUCATION_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
  {
      "label": "finalEducationForStudyAbroad.1",
      "value": "大学院(博士)"
  },
  {
      "label": "finalEducationForStudyAbroad.2",
      "value": "大学院(修士)"
  },
  {
      "label": "finalEducationForStudyAbroad.3",
      "value": "大学"
  },
  {
      "label": "finalEducationForStudyAbroad.4",
      "value": "短期大学"
  },
  {
      "label": "finalEducationForStudyAbroad.5",
      "value": "専門学校"
  },
  {
      "label": "finalEducationForStudyAbroad.6",
      "value": "高等学校"
  },
  {
      "label": "finalEducationForStudyAbroad.7",
      "value": "中学校"
  },
  {
      "label": "finalEducationForStudyAbroad.A",
      "value": "小学校"
  },
  {
      "label": "finalEducationForStudyAbroad.9",
      "value": "その他"
  }
];

export const ENROLLMENT_STATUS_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "enrollmentStatusForStudyAbroad.1",
        "value": "卒業"
    },
    {
        "label": "enrollmentStatusForStudyAbroad.2",
        "value": "在学中"
    },
    {
        "label": "enrollmentStatusForStudyAbroad.3",
        "value": "休学中"
    },
    {
        "label": "enrollmentStatusForStudyAbroad.4",
        "value": "中退"
    }
];

export const ENROLLMENT_CATEGORY_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "enrollmentCategoryForStudyAbroad.11",
        "value": "大学院(博士)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.12",
        "value": "大学院(修士)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.14",
        "value": "大学院(研究生/専ら聴講によらない)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.15",
        "value": "大学院(研究生/専ら聴講による)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.21",
        "value": "大学(学部生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.22",
        "value": "大学(聴講生・科目等履修生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.27",
        "value": "大学(研究生/専ら聴講によらない)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.25",
        "value": "大学(研究生/専ら聴講による)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.24",
        "value": "大学(別科生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.30",
        "value": "短期大学(学科生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.32",
        "value": "短期大学(聴講生・科目等履修生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.33",
        "value": "短期大学(別科生)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.41",
        "value": "高等専門学校"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.51",
        "value": "専修学校(専門課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.52",
        "value": "専修学校(高等課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.53",
        "value": "専修学校(一般課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.71",
        "value": "各種学校"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.61",
        "value": "日本語教育機関(専修学校専門課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.65",
        "value": "日本語教育機関(専修学校一般課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.63",
        "value": "日本語教育機関(準備教育課程)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.64",
        "value": "日本語教育機関(各種学校)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.62",
        "value": "日本語教育機関(その他)"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.81",
        "value": "高等学校"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.91",
        "value": "中学校"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.95",
        "value": "小学校"
    },
    {
        "label": "enrollmentCategoryForStudyAbroad.99",
        "value": "その他"
    }
];

export const FACULTY_COURSE_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "facultyCourseForStudyAbroad.102",
        "value": "経済学"
    },
    {
        "label": "facultyCourseForStudyAbroad.103",
        "value": "政治学"
    },
    {
        "label": "facultyCourseForStudyAbroad.104",
        "value": "商学"
    },
    {
        "label": "facultyCourseForStudyAbroad.105",
        "value": "経営学"
    },
    {
        "label": "facultyCourseForStudyAbroad.106",
        "value": "文学"
    },
    {
        "label": "facultyCourseForStudyAbroad.107",
        "value": "語学"
    },
    {
        "label": "facultyCourseForStudyAbroad.108",
        "value": "社会学"
    },
    {
        "label": "facultyCourseForStudyAbroad.109",
        "value": "歴史学"
    },
    {
        "label": "facultyCourseForStudyAbroad.110",
        "value": "心理学"
    },
    {
        "label": "facultyCourseForStudyAbroad.111",
        "value": "教育学"
    },
    {
        "label": "facultyCourseForStudyAbroad.112",
        "value": "芸術学"
    },
    {
        "label": "facultyCourseForStudyAbroad.199",
        "value": "その他人文・社会科学"
    },
    {
        "label": "facultyCourseForStudyAbroad.201",
        "value": "理学"
    },
    {
        "label": "facultyCourseForStudyAbroad.202",
        "value": "化学"
    },
    {
        "label": "facultyCourseForStudyAbroad.203",
        "value": "工学"
    },
    {
        "label": "facultyCourseForStudyAbroad.204",
        "value": "農学"
    },
    {
        "label": "facultyCourseForStudyAbroad.205",
        "value": "水産学"
    },
    {
        "label": "facultyCourseForStudyAbroad.206",
        "value": "薬学"
    },
    {
        "label": "facultyCourseForStudyAbroad.207",
        "value": "医学"
    },
    {
        "label": "facultyCourseForStudyAbroad.208",
        "value": "歯学"
    },
    {
        "label": "facultyCourseForStudyAbroad.209",
        "value": "体育学"
    },
    {
        "label": "facultyCourseForStudyAbroad.299",
        "value": "その他自然科学"
    },
    {
        "label": "facultyCourseForStudyAbroad.999",
        "value": "その他"
    }
];

export const SPECIALIZED_COURSE_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "specializedCourseForStudyAbroad.1",
        "value": "工業"
    },
    {
        "label": "specializedCourseForStudyAbroad.2",
        "value": "農業"
    },
    {
        "label": "specializedCourseForStudyAbroad.3",
        "value": "医療・衛生"
    },
    {
        "label": "specializedCourseForStudyAbroad.5",
        "value": "教育・社会福祉"
    },
    {
        "label": "specializedCourseForStudyAbroad.4",
        "value": "法律"
    },
    {
        "label": "specializedCourseForStudyAbroad.6",
        "value": "商業実務"
    },
    {
        "label": "specializedCourseForStudyAbroad.7",
        "value": "服飾・家政"
    },
    {
        "label": "specializedCourseForStudyAbroad.8",
        "value": "文化・教養"
    },
    {
        "label": "specializedCourseForStudyAbroad.99",
        "value": "その他"
    }
];

export const PLAN_AFTER_GRADUATION_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "planAfterGraduationForStudyAbroad.01",
        "value": "帰国"
    },
    {
        "label": "planAfterGraduationForStudyAbroad.02",
        "value": "日本での進学"
    },
    {
        "label": "planAfterGraduationForStudyAbroad.03",
        "value": "日本での就職"
    },
    {
        "label": "planAfterGraduationForStudyAbroad.99",
        "value": "その他"
    }  
];

export const TYPE_OF_CLASS_OPTIONS_FOR_STUDY_ABROAD: SelectOption[] = [
    {
        "label": "typeOfClassForStudyAbroad.1",
        "value": "昼間制"
    },
    {
        "label": "typeOfClassForStudyAbroad.2",
        "value": "昼夜間制"
    },
    {
        "label": "typeOfClassForStudyAbroad.3",
        "value": "夜間制"
    },
    {
        "label": "typeOfClassForStudyAbroad.5",
        "value": "サテライト制(双方向通信による遠隔授業を受ける場合に選択)"
    },
    {
        "label": "typeOfClassForStudyAbroad.4",
        "value": "通信制(単位の一部をビデオ又はインターネット等による教育により取得できる場合を含む。)"
    }
];