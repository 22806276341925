
//Ref for the following codes:
//https://drive.google.com/file/d/1HbnLjpT5WG66zUp4OTHpBmjzhHRar7UF/view
//or
//https://docs.google.com/spreadsheets/d/1fdUHmF_UZyFnrnd901ad7u5_ocdaRnaZtedSM_65lyA/edit#gid=2000493674

//This is for selZiryskk (在留資格 / Status of residence)
//Don't use this for selCurrentZirySkk (現に有する在留資格 / Current status of residence)
export enum StatusOfResidenceCodeForChangeBySelf {
  Dependent = "05R1",
  Gijinkoku = "05N2"
}

//This is for selSnsiShbt (申請種別 / Application category)
export enum ApplicationCategoryCodeForChangeBySelf {
  Dependent = "05R",
  Gijinkoku = "05N"
}
