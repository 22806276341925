import { FunctionComponent } from "react";
import { VisaApplicationType } from "../../../../types/visa/applicationType";
import DependentVisaAttachments from "./SectionBodies/DendentVisaAttachments";
import { VisaAttachment, VisaAttachmentKind } from "../../../../types/visa/attachment";

interface AttachmentsSectionProps {
  visaAttachments: VisaAttachment [],
  visaApplicationType: VisaApplicationType;
  onVisaAttachmentUpload: (file: File, kind: VisaAttachmentKind, uploaderId?: string) => void;
  onVisaAttachmentDelete: (visaAttachmentId: number) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  showUnattachedAlert: boolean;
}

const AttachmentsSection: FunctionComponent<AttachmentsSectionProps> = ({
  visaAttachments,
  visaApplicationType,
  onVisaAttachmentUpload,
  onVisaAttachmentDelete,
  onSectionReadinessChange,
  showUnattachedAlert,
}) => {

  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
      return (
        <DependentVisaAttachments
          visaAttachments ={visaAttachments}
          visaApplicationType={visaApplicationType}
          onVisaAttachmentUpload={onVisaAttachmentUpload}
          onVisaAttachmentDelete={onVisaAttachmentDelete}
          onSectionReadinessChange={onSectionReadinessChange}
          showRequiredAlert={showUnattachedAlert}
        />
      );

    default:
      return null;
  }
};

export default AttachmentsSection;
