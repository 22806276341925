import { FunctionComponent } from "react";
import {
  getLabelFromMunicipalityInJpValue,
  getLabelFromPrefectureInJpValue,
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { getTransKeyForWorkInvolveInfoProcessingService } from "../../../../../../utils/visaFormDataHelpers/gijinkokuVisaFormDataHelper";
import { useTranslation } from "react-i18next";
import {
  Group,
  PreviewHeading,
  PreviewSubHeading,
} from "../../previewBodyCommonStyle";
import { FormN } from "../../../../../../types/uncommonFormParts/formN/data";
import { DoesWorkInvolveInInfoProcessing } from "../../../../../../types/uncommonFormParts/formN/value";
import PreviewList from "../../../PreviewList";

interface PlaceOfEmploymentSectionProps {
  data: FormN
}

export const BasicInformationSection: FunctionComponent<
  PlaceOfEmploymentSectionProps
> = ({ data }) => {
  const { t } = useTranslation('translation', { keyPrefix: 'placeOfEmploymentSection' });
  const { t : tWithoutPrefix } = useTranslation();

  return (
    <section>
      <PreviewHeading>{t("title")}</PreviewHeading>
      <PreviewList
        items={[
          {
            label: t("nameOfOrganization"),
            value: data["place_of_employment_name"]
          },
          {
            label: t("nameOfBranchOffice"),
            value: data["place_of_employment_name_of_branch"]
          },
        ]}
      />

      <Group>
        <PreviewSubHeading>{t("location")}</PreviewSubHeading>
        <PreviewList
          items={[
            {
              label: t("prefecture"),
              value: getLabelFromPrefectureInJpValue(data["place_of_employment_prefecture"])
                ? tWithoutPrefix(`${getLabelFromPrefectureInJpValue(data["place_of_employment_prefecture"])}`)
                : ""
            },
            {
              label: t("municipality"),
              value: data["place_of_employment_prefecture"]
                ? tWithoutPrefix(`${
                  getLabelFromMunicipalityInJpValue(
                    data["place_of_employment_prefecture"],
                    data["place_of_employment_municipality"]
                  )}`)
                : ""
            },
            {
              label: t("townStreetApartment"),
              value: data["place_of_employment_street"],
            },
          ]}
        />
      </Group>

      <PreviewList
        items={[
          {
            label: t("phoneNumber"),
            value: data["place_of_employment_telephone_no"],
          },
          {
            label: t("doesYourWorkInvolveInfoProcessingServices"),
            value: data["qualifications_for_information_processing"]
              ? tWithoutPrefix(`${
                  getTransKeyForWorkInvolveInfoProcessingService(data["qualifications_for_information_processing"])
                }`)
              : "",
          },
          {
            label: t("nameOfQualificationExamForInfoProcessing"),
            value: data["name_of_the_qualification"],
            skip: data["name_of_the_qualification"] === DoesWorkInvolveInInfoProcessing.Yes,
          },
        ]}
      />
    </section>
  );
};

export default BasicInformationSection;
