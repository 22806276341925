//Keep KoushinHonnin (extensionBySelf) specific values here


//Ref for the following codes:
//https://drive.google.com/file/d/1HbnLjpT5WG66zUp4OTHpBmjzhHRar7UF/view
//or
//https://docs.google.com/spreadsheets/d/1fdUHmF_UZyFnrnd901ad7u5_ocdaRnaZtedSM_65lyA/edit#gid=2000493674

//This is for selZiryskk (在留資格 / Status of residence)
//Don't use this for selCurrentZirySkk (現に有する在留資格 / Current status of residence)
export enum StatusOfResidenceCode {
  Family = "04R1",
  Technical = "04N2",
  StudyAbroad = "04P1"
}

//This is for selSnsiShbt (申請種別 / Application category)
export enum ApplicationCategoryCode {
  Family = "04R",
  Technical = "04N",
  StudyAbroad = "04P"
}

export enum StatusOfResidenceCodeForExtensionBySelf {
  Dependent = "04R1",
  Gijinkoku = "04N2",
  StudyAbroad = "04P1"
}

//This is for selSnsiShbt (申請種別 / Application category)
export enum ApplicationCategoryCodeForExtensionBySelf {
  Dependent = "04R",
  Gijinkoku = "04N",
  StudyAbroad = "04P"
}
