//The label numbers come from the spreadsheet:
//See: https://docs.google.com/spreadsheets/d/1DSH9psUEakHitz56tuTrRxzV0y07nI_KhafCmHlUjCI/edit#gid=1606825711

import { SelectOption } from "../../types/option";

export const TYPE_OF_WORK_OPTIONS: SelectOption[]= [
  {
      "label": "typeOfWork.K02",
      "value": "管理業務(経営者を除く)"
  },
  {
      "label": "typeOfWork.K03",
      "value": "調査研究"
  },
  {
      "label": "typeOfWork.K04",
      "value": "技術開発(農林水産分野)"
  },
  {
      "label": "typeOfWork.K05",
      "value": "技術開発(食品分野)"
  },
  {
      "label": "typeOfWork.K06",
      "value": "技術開発(機械器具分野)"
  },
  {
      "label": "typeOfWork.K07",
      "value": "技術開発(その他製造分野)"
  },
  {
      "label": "typeOfWork.K08",
      "value": "生産管理(食品分野)"
  },
  {
      "label": "typeOfWork.K09",
      "value": "生産管理(機械器具分野)"
  },
  {
      "label": "typeOfWork.K10",
      "value": "生産管理(その他製造分野)"
  },
  {
      "label": "typeOfWork.K11",
      "value": "建築・土木・測量技術"
  },
  {
      "label": "typeOfWork.K12",
      "value": "情報処理・通信技術"
  },
  {
      "label": "typeOfWork.K13",
      "value": "法律関係業務"
  },
  {
      "label": "typeOfWork.K14",
      "value": "金融・保険"
  },
  {
      "label": "typeOfWork.K15",
      "value": "コピーライティング"
  },
  {
      "label": "typeOfWork.K16",
      "value": "報道"
  },
  {
      "label": "typeOfWork.K17",
      "value": "編集"
  },
  {
      "label": "typeOfWork.K18",
      "value": "デザイン"
  },
  {
      "label": "typeOfWork.K20",
      "value": "教育(教育機関を除く)"
  },
  {
      "label": "typeOfWork.K21",
      "value": "翻訳・通訳"
  },
  {
      "label": "typeOfWork.K22",
      "value": "海外取引業務"
  },
  {
      "label": "typeOfWork.K23",
      "value": "企画事務(マーケティング，リサーチ)"
  },
  {
      "label": "typeOfWork.K24",
      "value": "企画事務(広報・宣伝)"
  },
  {
      "label": "typeOfWork.K25",
      "value": "会計事務"
  },
  {
      "label": "typeOfWork.K26",
      "value": "法人営業"
  },
  {
      "label": "typeOfWork.K27",
      "value": "CADオペレーション"
  },
  {
      "label": "typeOfWork.K94",
      "value": "接客(販売店)"
  },
  {
      "label": "typeOfWork.K95",
      "value": "接客(飲食店)"
  },
  {
      "label": "typeOfWork.K96",
      "value": "接客(その他)"
  },
  {
      "label": "typeOfWork.K97",
      "value": "製品製造"
  },
  {
      "label": "typeOfWork.K87",
      "value": "その他"
  }
];

export const START_DATE_OF_EMPLOYMENT_UNDECIDED_OPTIONS: SelectOption[] = [
  {
    "label": "startDateOfEmploymentUndecided.appIsApproved",
    "value": "今次申請の許可を受け次第",
  },
  
  {
    "label": "startDateOfEmploymentUndecided.appIsApprovedAfterGraduation",
    "value": "在籍する教育機関を卒業後，今次申請の許可を受け次第",
  },
  {
    "label": "startDateOfEmploymentUndecided.other",
    "value": "その他",
  },  
]