import { ChangeBySelfData } from "../types/changeBySelf/data";
import { ExtensionBySelfData } from "../types/extensionBySelf/data";
import { FormN } from "../types/uncommonFormParts/formN/data";
import { FormP } from "../types/uncommonFormParts/formP/data";
import { CSVSourceKind, FormKind, VisaApplication, VisaMessage, VisaSubmission } from "../types/visa/applicationData";
import { ApplicationCategoryCodeForExtensionBySelf, StatusOfResidenceCodeForExtensionBySelf } from "../types/extensionBySelf/specificValues";
import { VisaApplicationStatus } from "../types/visa/applicationStatus";
import { VisaApplicationType } from "../types/visa/applicationType";
import { ApplicationTypeTransKey, VisaTransKey } from "../types/visa/transKeys";
import { ApplicationCategoryCodeForChangeBySelf, StatusOfResidenceCodeForChangeBySelf } from "../types/changeBySelf/specificValues";

const isThisVisaApplication = (val: unknown): val is VisaApplication => {
  return typeof val === 'object' && val !== null && val.hasOwnProperty('csv_source_kind');
}

export const checkVisaApplicationType = (visaApplication: VisaApplication): VisaApplicationType | undefined => {
  
  //selSnsiShbt : 申請種別 / Application category
  switch (visaApplication.csv_source_kind) {
    case CSVSourceKind.ExtensionBySelf:
    {
      const applicationCategoryCode = visaApplication.koushin_honnin["selSnsiShbt"];

      switch (applicationCategoryCode) {
        case ApplicationCategoryCodeForExtensionBySelf.Dependent:
          return VisaApplicationType.ExtendDependentVisaBySelf;
        case ApplicationCategoryCodeForExtensionBySelf.Gijinkoku:
          return VisaApplicationType.ExtendGijinkokuVisaBySelf;
        case StatusOfResidenceCodeForExtensionBySelf.StudyAbroad:
          return VisaApplicationType.ExtendStudyAbroadVisaBySelf;
      }
      break;
    }

    case CSVSourceKind.ChangeBySelf:
    {
      const applicationCategoryCode = visaApplication?.henkou_honnin["selSnsiShbt"];

      switch (applicationCategoryCode) {
        case ApplicationCategoryCodeForChangeBySelf.Dependent:
          return VisaApplicationType.ChangeToDependentVisaBySelf;
        case ApplicationCategoryCodeForChangeBySelf.Gijinkoku:
          return VisaApplicationType.ChangeToGijinkokuVisaBySelf;
      }
      break;
    }
  }
}

export const isThisVisaExtension = (val: VisaApplication | VisaApplicationType): boolean => {
  let visaApplicationType;

  if (isThisVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else
    visaApplicationType = val;
  
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
      return true;
    default:
      return false;
  }
}

export const isThisVisaExtensionApplication = (val: VisaApplication): val is VisaApplication & { 
  csv_source_kind: CSVSourceKind.ExtensionBySelf,
  koushin_honnin: ExtensionBySelfData, 
} => {
  return (
    val.csv_source_kind === CSVSourceKind.ExtensionBySelf &&
    val.koushin_honnin !== null
  );
}

export const isThisVisaChange = (val: VisaApplication | VisaApplicationType): boolean => {
  let visaApplicationType;

  if (isThisVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else
    visaApplicationType = val;

  switch (visaApplicationType) {
    case VisaApplicationType.ChangeToDependentVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return true;
    default:
      return false;
  }
}

export const isThisVisaChangeApplication = (val: VisaApplication): val is VisaApplication & {
  csv_source_kind: CSVSourceKind.ChangeBySelf,
  henkou_honnin: ChangeBySelfData,
} => {
  return (
    val.csv_source_kind === CSVSourceKind.ChangeBySelf &&
    val.henkou_honnin !== null
  );
}

export const isExtensionBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ExtensionBySelfData => {
  //Checking "Reason for extension" property
  return data.hasOwnProperty('WCIBS010Dto:txtKusnReason');
}

export const isChangeBySelfData = (data: ExtensionBySelfData | ChangeBySelfData): data is ChangeBySelfData => {
  //Checking "Reason for change" property
  return data.hasOwnProperty('WCICS020Dto:txtKusnReason');
}

export const isApplicationWithFormN = (visaApplication?: VisaApplication | null): visaApplication is VisaApplication & ({
  form_kind: FormKind.N,
  form_n: FormN
}) => {
  return visaApplication ? (
    visaApplication.form_kind === FormKind.N &&
    visaApplication.form_n !== null
  ) : false;
}

export const isApplicationWithFormP = (visaApplication?: VisaApplication | null): visaApplication is VisaApplication & ({
  form_kind: FormKind.P,
  form_p: FormP
}) => {
  return visaApplication ? (
    visaApplication.form_kind === FormKind.P &&
    visaApplication.form_p !== null
  ) : false;
}

export const isNecessaryToWorkWithOrganization = (val: VisaApplication | VisaApplicationType): boolean => {
  let visaApplicationType;

  if (isThisVisaApplication(val)) 
    visaApplicationType = checkVisaApplicationType(val);
  else 
    visaApplicationType = val;
  
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return true;
    default:
      return false;
  }
}

//Try to find the corresponding translation keys based on the visa application 
//e.g.
//  VisaApplicationType.ExtendDependentVisaBySelf => { visa: "dependent", type: "extension" } 
export const getVisaInfoTransKeys = (VisaApplication: VisaApplication): Record<"visa" | "type", string> => {
  const visaApplicationType = checkVisaApplicationType(VisaApplication);
  switch (visaApplicationType) {
    case VisaApplicationType.ExtendDependentVisaBySelf:
      return {
        visa: VisaTransKey.Dependent,
        type: ApplicationTypeTransKey.Extension,
      };
    case VisaApplicationType.ExtendGijinkokuVisaBySelf:
      return {
        visa: VisaTransKey.Gijinkoku,
        type: ApplicationTypeTransKey.Extension,
      };
    case VisaApplicationType.ExtendStudyAbroadVisaBySelf:
      return {
        visa: VisaTransKey.StudyAbroad,
        type: ApplicationTypeTransKey.Extension,
      }
    case VisaApplicationType.ChangeToDependentVisaBySelf:
      return {
        visa: VisaTransKey.Dependent,
        type: ApplicationTypeTransKey.Change,
      };
    case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
      return {
        visa: VisaTransKey.Gijinkoku,
        type: ApplicationTypeTransKey.Change,
      };
    default:
      return {
        visa: "",
        type: "",
      };
  }
}

export const getVisaApplicationStatus = (visaApplication: VisaApplication): VisaApplicationStatus => {
  const lastSubmission = visaApplication.last_submitted_visa_submission;

  if (!lastSubmission)
    return VisaApplicationStatus.Working;

  return lastSubmission.app_status;
}

export const getVisaMessages = (val: VisaApplication | VisaSubmission): VisaMessage[] => {
  if (isThisVisaApplication(val)) {
    const lastSubmission = val.last_submitted_visa_submission;
  
    if (!lastSubmission)
      return [];
  
    return lastSubmission.visa_messages;
  }
  else {
    return val.visa_messages;
  }
}

export const isThisVisaApplicationSubmitted = (visaApplication: VisaApplication): boolean => {
  return visaApplication.last_submitted_visa_submission !== null;
}