import { ButtonHTMLAttributes, FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'primary' | 'secondary' | 'inline';
    children?: ReactNode;
}

const Container = styled.button<ButtonProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${(props: ButtonProps) => props.variant === 'inline' ? 30 : 40}px;
  padding: 0 20px;
  border: ${(props: ButtonProps) => props.variant === 'inline' && !props.disabled ? '1px solid #D5D5D5' : 'none'};
  border-radius: 100px;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: ${(props: ButtonProps) => {
      switch (props.variant) {
          case 'secondary':
          case 'inline':
              return '#444444';
          default:
              return  '#FFFFFF';
      }            
  }};
  background: ${(props: ButtonProps) => {
      switch (props.variant) {
          case 'secondary':
              return '#C8E0FF';
          case 'inline':
              return '#EDEDED';
          default:
              return '#0069EC';
      }
  }};
  outline: none;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    cursor: default;
    pointer-events: none;
    background-color: #ACACAC;
    color: #5F6368;

    & * {
        fill: #5F6368;
    }
  }
`;

const Button: FunctionComponent<ButtonProps> = ({
    children,
    ...props
}) => {
    return (
        <Container {...props}>
            {children}
        </Container>
    );
};

export default Button;