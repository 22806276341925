import { FunctionComponent } from "react";
import styled from "styled-components";
import Text from "../../text/Text";
import { PreviewAttachment, PreviewItem } from "../../../types/modal/preview";
import { useTranslation } from "react-i18next";
import { generateRandomString } from "../../../utils/utils";
import DisplayLabel from "../../form/DisplayLabel";
import { isThisPreviewAttachment, isThisPreviewItem } from "../../../utils/previewHelper";

interface PreviewListProps {
  items: (PreviewItem | PreviewAttachment) [];
}

const ListContainer = styled.div`
  width: 100%;
`;

/* Note:
L/R paddings are controlled here rather than in the parent component
because some other items need to span across the entire width of the container */
const ListRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 20px 10px 20px;
  min-height: 40px;
  gap: 5px;
`;

const AttachmentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const AttachmentLabelText = styled(Text)`
  color: #999999;
  display: block;
  width: 100%;
  text-align: left;
`;

const LocalDisplayLabel = styled(DisplayLabel)`
  word-break: break-all;
  color: #999999;
`;

const ItemValue = styled(Text)`
  word-break: break-all;
`;

const TextImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
`;

const Image = styled.img`
  width: 100%;
  max-width: 240px;
  height: auto;
`;

const PreviewList: FunctionComponent<PreviewListProps> = ({
  items
}) => {
  const { t } = useTranslation();

  return (
    <ListContainer>
      {items
        .filter(v => !!!v.skip)
        .map(item => (
          <ListRow 
            key={generateRandomString()}
          >

            { isThisPreviewAttachment(item) && (
              <AttachmentWrapper>
                <AttachmentLabelText>{item.label}</AttachmentLabelText>
    
                {item.files.length === 0 && (
                  <Text>{t("attachmentsSection.noFilesAttached")}</Text>
                )}
    
                {item.files.map(file => {
                  return (
                    <TextImageWrapper key={generateRandomString()}>
                      <Image src={file.url} />
                    </TextImageWrapper>
                  );
                })}
              </AttachmentWrapper>)
            }


            { isThisPreviewItem(item) && (
              <>
                <LocalDisplayLabel>{item.label}</LocalDisplayLabel>
                <ItemValue>{item.value ?? ""}</ItemValue>
              </>
            )}

          </ListRow>
        ))}
    </ListContainer>
  );
};

export default PreviewList;
