import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import DateSelectField from "../../../components/compound/DateSelectField";
import SelectField from "../../../components/compound/SelectField";
import TextField from "../../../components/compound/TextField";
import Section from '../../../components/layout/Section';
import {
    PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT,
    PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU,
    CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_EXTENSION_OPTIONS,
    CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_CHANGE_OPTIONS
} from '../../../constants/options';
import { useValidation } from '../../../hooks';
import { ExtensionBySelfData } from '../../../types/extensionBySelf/data';
import { VisaApplicationType } from '../../../types/visa/applicationType';
import { ChangeBySelfData } from '../../../types/changeBySelf/data';
import { isThisVisaChange, isThisVisaExtension } from '../../../utils/visaApplicationHelper';
import { formatDate } from '../../../utils/visaFormDataHelpers/commonVisaFormDataHelper';
import { SelectOption } from '../../../types/option';

interface StatusOfResidenceSectionProps {
  visaApplicationType: VisaApplicationType;
  extensionBySelfData: ExtensionBySelfData | null;
  changeBySelfData: ChangeBySelfData | null;
  onChangeExtensionBySelfData: (data: Partial<ExtensionBySelfData>) => void;
  onChangeChangeBySelfData: (data: Partial<ChangeBySelfData>) => void;
  onSectionReadinessChange: (isSectionReady: boolean) => void;
  isEmptyRequiredValueError: boolean;
}

const StatusOfResidenceSection: FunctionComponent<StatusOfResidenceSectionProps> = ({
  visaApplicationType,
  extensionBySelfData,
  changeBySelfData,
  onChangeExtensionBySelfData,
  onChangeChangeBySelfData,
  onSectionReadinessChange,
  isEmptyRequiredValueError,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'statusOfResidenceSection' });
  const { t : tWithoutPrefix } = useTranslation();
  const validation = useValidation();
  const [statusOfResidence, setStatusOfResidenceStatus] = useState('');
  const [periodOfStay, setPeriodOfStay] = useState('');
  const [dateOfExpiration, setDateOfExpiration] = useState('');
  const [residencyCardNumber, setResidencyCardNumber] = useState('');
  const [residencyCardNumberError, setResidencyCardNumberError] = useState('');
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const getEmptyError = (val: any) => (isEmptyRequiredValueError && !!!val)? tWithoutPrefix("inputAlert.field") : '';
  const saveExtensionData = (data: Partial<ExtensionBySelfData>) => { onChangeExtensionBySelfData(data) }
  const saveChangeData = (data: Partial<ChangeBySelfData>) => { onChangeChangeBySelfData(data) }
  const periodOfStayOptions: SelectOption[] = (() => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendDependentVisaBySelf:
        return PERIOD_OF_STAY_OPTIONS_FOR_DEPENDENT;
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
      case VisaApplicationType.ChangeToGijinkokuVisaBySelf:
        return PERIOD_OF_STAY_OPTIONS_FOR_GIJINKOKU;
      default:
        return [];
    }
  })();
  const statusOfResidenceOptions = (() => {
    if (isVisaExtension) 
      return CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_EXTENSION_OPTIONS;
    
    if (isVisaChange) 
      return CURRENT_STATUS_OF_RESIDENCE_FOR_VISA_CHANGE_OPTIONS;
    
    return [];
  })();

  useEffect(() => {
    if (isVisaExtension && extensionBySelfData) {
      const data: ExtensionBySelfData = extensionBySelfData;
      setStatusOfResidenceStatus(data['WCIBS010Dto:selCurrentZirySkk'] || '');
      setPeriodOfStay(data['WCIBS010Dto:selZiryPeriod'] || '');
      setDateOfExpiration(
        formatDate(
          data['WCIBS010Dto:selExpirationDateYear'], 
          data['WCIBS010Dto:selExpirationDateMonth'],
          data['WCIBS010Dto:selExpirationDateDay'],
          '-'
        )
      );
      setResidencyCardNumber(data['WCIBS010Dto:txtZiryCardNum'] || '');
    }

    if (isVisaChange && changeBySelfData) {
      const data: ChangeBySelfData = changeBySelfData;
      setStatusOfResidenceStatus(data['WCICS020Dto:selCurrentZirySkk'] || '');
      setPeriodOfStay(data['WCICS020Dto:selZiryPeriod'] || '');
      setDateOfExpiration(
        formatDate(
          data['WCICS020Dto:selExpirationDateYear'], 
          data['WCICS020Dto:selExpirationDateMonth'],
          data['WCICS020Dto:selExpirationDateDay'],
          '-'
        )
      );
      setResidencyCardNumber(data['WCICS020Dto:txtZiryCardNum'] || '');
    }

  }, [extensionBySelfData, changeBySelfData]);

  
  useEffect(() => {
    const areAllRequiredFieldsFilled = [
      statusOfResidence,
      periodOfStay,
      dateOfExpiration,
      residencyCardNumber
    ].every(val => !!val);

    const areThereNoErrors = [
      residencyCardNumberError
    ].every(val => !val);

    onSectionReadinessChange(areAllRequiredFieldsFilled && areThereNoErrors);
  }, [
    statusOfResidence,
    periodOfStay,
    dateOfExpiration,
    residencyCardNumber,
    residencyCardNumberError
  ]);


  return (
    <Section>
      {/* Current status of residence 
        NOTE: this field is read-only when extending a visa.
        but it is editable when changing a visa. 
      */}
      <SelectField
        disabled={isVisaExtension}
        required
        label={t("currentStatusOfResidence")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={statusOfResidenceOptions}
        value={statusOfResidence}
        error={getEmptyError(statusOfResidence)}
        onValueChange={val => {
          if (!isVisaChange) 
            return;

          setStatusOfResidenceStatus(val);
          saveChangeData({ 
            'WCICS020Dto:selCurrentZirySkk': val 
          });
        }}
      />

      {/* Period of stay */}
      <SelectField
        required
        label={t("periodOfStay")}
        placeholder={tWithoutPrefix("placeholder.select")}
        options={periodOfStayOptions}
        value={periodOfStay}
        error={getEmptyError(periodOfStay)}
        onValueChange={val => {
          setPeriodOfStay(val);
          isVisaExtension && saveExtensionData({ 
            'WCIBS010Dto:selZiryPeriod': val 
          });
          isVisaChange && saveChangeData({ 
            'WCICS020Dto:selZiryPeriod': val 
          });
        }}
      />

      {/* Date of expiration */}
      <DateSelectField
        required
        label={t("dateOfExpiration")}
        maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 5))}
        value={dateOfExpiration}
        error={getEmptyError(dateOfExpiration)}
        onValueChange={val => {
          setDateOfExpiration(val);

          if (val) {
            const [year, month, day] = val.split('-');

            isVisaExtension && saveExtensionData({
              'WCIBS010Dto:selExpirationDateYear': year,
              'WCIBS010Dto:selExpirationDateMonth': month,
              'WCIBS010Dto:selExpirationDateDay': day,
            });

            isVisaChange && saveChangeData({
              'WCICS020Dto:selExpirationDateYear': year,
              'WCICS020Dto:selExpirationDateMonth': month,
              'WCICS020Dto:selExpirationDateDay': day,
            });
          }
        }}
      />

      {/* Residence card number */}
      <TextField
        required
        label={t("residenceCardNumber")}
        placeholder="AAAA9999999C"
        value={residencyCardNumber}
        error={residencyCardNumberError || getEmptyError(residencyCardNumber)}
        restriction={tWithoutPrefix("inputNote.halfwidthLetterAndNumber")}
        maxLength={12}
        validators={[validation.isAlphanumeric, validation.createLengthValidator(12)]}
        onValueChange={setResidencyCardNumber}
        onErrorChange={setResidencyCardNumberError}
        onBlur={() => {
          isVisaExtension && saveExtensionData({ 
            'WCIBS010Dto:txtZiryCardNum': residencyCardNumber 
          });

          isVisaChange && saveChangeData({ 
            'WCICS020Dto:txtZiryCardNum': residencyCardNumber 
          });
        }}
      />
    </Section>
  );
};

export default StatusOfResidenceSection;