import { FunctionComponent } from "react";
import { ExtensionBySelfData } from "../../../../../../types/extensionBySelf/data";
import { ChangeBySelfData } from "../../../../../../types/changeBySelf/data";
import {
  convertCurrentStatusOfResidenceToTransKey,
  formatDate
} from "../../../../../../utils/visaFormDataHelpers/commonVisaFormDataHelper";
import { getLabelFromPeriodOfStayCodeForDependent } from "../../../../../../utils/visaFormDataHelpers/dependentVisaformDataHelper";
import { useTranslation } from "react-i18next";
import { PreviewHeading } from "../../previewBodyCommonStyle";
import { VisaApplicationType } from "../../../../../../types/visa/applicationType";
import {
  isThisVisaChange,
  isThisVisaExtension,
} from "../../../../../../utils/visaApplicationHelper";
import PreviewList from "../../../PreviewList";

interface StatusOfResidenceSectionProps {
  visaApplicationType: VisaApplicationType;
  data: ExtensionBySelfData | ChangeBySelfData;
}

export const StatusOfResidenceSection: FunctionComponent<
  StatusOfResidenceSectionProps
> = ({ visaApplicationType, data }) => {
  const { t } = useTranslation();
  const isVisaExtension = isThisVisaExtension(visaApplicationType);
  const isVisaChange = isThisVisaChange(visaApplicationType);
  const getPeriodOfStayLabel = (code: string) => {
    switch (visaApplicationType) {
      case VisaApplicationType.ExtendDependentVisaBySelf:
        return getLabelFromPeriodOfStayCodeForDependent(code);
      case VisaApplicationType.ExtendGijinkokuVisaBySelf:
        return getLabelFromPeriodOfStayCodeForDependent(code);
      default:
        return "";
    }
  };

  return (
    <section>
      <PreviewHeading>{t("statusOfResidenceSection.title")}</PreviewHeading>
      <PreviewList
        items={[
          {
            label: t("statusOfResidenceSection.currentStatusOfResidence"),
            value: (() => {
              let statusOfResidenceCode = "";

              if (isVisaExtension)
                statusOfResidenceCode = (data as ExtensionBySelfData)[
                  "WCIBS010Dto:selCurrentZirySkk"
                ];

              if (isVisaChange)
                statusOfResidenceCode = (data as ChangeBySelfData)[
                  "WCICS020Dto:selCurrentZirySkk"
                ];

              return t(
                `currentStatusOfResidence.${convertCurrentStatusOfResidenceToTransKey(
                  statusOfResidenceCode
                )}`
              );
            })(),
          },
          {
            label: t("statusOfResidenceSection.periodOfStay"),
            value: (() => {
              let code = "";

              if (isVisaExtension)
                code = (data as ExtensionBySelfData)[
                  "WCIBS010Dto:selZiryPeriod"
                ];

              if (isVisaChange)
                code = (data as ChangeBySelfData)["WCICS020Dto:selZiryPeriod"];

              return t(`${getPeriodOfStayLabel(code)}`);
            })(),
          },
          {
            label: t("statusOfResidenceSection.dateOfExpiration"),
            value: (() => {
              if (isVisaExtension) {
                const d = data as ExtensionBySelfData;
                return formatDate(
                  d["WCIBS010Dto:selExpirationDateYear"],
                  d["WCIBS010Dto:selExpirationDateMonth"],
                  d["WCIBS010Dto:selExpirationDateDay"]
                );
              }

              if (isVisaChange) {
                const d = data as ChangeBySelfData;
                return formatDate(
                  d["WCICS020Dto:selExpirationDateYear"],
                  d["WCICS020Dto:selExpirationDateMonth"],
                  d["WCICS020Dto:selExpirationDateDay"]
                );
              }

              return "";
            })(),
          },
          {
            label: t("statusOfResidenceSection.residenceCardNumber"),
            value: (() => {
              if (isVisaExtension) {
                return (data as ExtensionBySelfData)[
                  "WCIBS010Dto:txtZiryCardNum"
                ];
              }

              if (isVisaChange) {
                return (data as ChangeBySelfData)["WCICS020Dto:txtZiryCardNum"];
              }

              return "";
            })(),
          },
        ]}
      />
    </section>
  );
};

export default StatusOfResidenceSection;
